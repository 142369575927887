import * as React from "react";
import swal from "sweetalert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SVG } from "../../assert/svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LandingPageStyleNew from "./landingPageStyleNew";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Toolbar,
} from "@mui/material";
import PurpleButtonComponent from "../../components/button/purpleButton";
import OutLinePurpleButton from "../../components/button/OutlinePurpleButton";
import Warehouse from "../../assert/images/wareHousing.png";
import Clearance from "../../assert/images/clearance.png";
import Duty from "../../assert/images/duty.png";
import Global from "../../assert/images/global.png";
import Pack from "../../assert/images/pack.png";
import Labeling from "../../assert/images/labeling.png";
import ContainerPic from "../../assert/images/contain.png";
import nurse from "../../assert/images/nurse.png";
import Truck from "../../assert/images/truck.png";
import Boat from "../../assert/images/boat.png";
import AmazonFrit from "../../assert/svg/partnerS.svg";
import Colabri from "../../assert/svg/partnerTh.svg";

import bridgeImg from "../../assert/images/bridge.png";
import LandingPageFooter from "../../components/footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { baseURL } from "../../api/api";
import axios from "axios";
import HeaderMain from "../../components/headerMain";
import { useDispatch, useSelector } from "react-redux";
import { setActiveHeader, setCalculate } from "../../reducers/modalSlice";
import { Helmet } from "react-helmet";

function LandingPageNew(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState("home");
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();

  const [userData, setUserData] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendEmail = async () => {
    if (!userData.name || !userData.email)
      return swal(
        "Missing required fields",
        "Please fill all the fields",
        "error"
      );
    setIsProcess(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseURL}/sendMail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(userData),
    };

    try {
      const resppnse = await axios.request(config);
      let result = resppnse.data;
      console.log(result);
      if (result.success) {
        setIsProcess(false);

        swal("Quote request sent successfully", "", "success");
        setUserData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        swal("Something Went Wrong", "Quote request not sent", "error");
      }
    } catch (error) {
      console.log(error);
      setIsProcess(false);
      swal("Something Went Wrong", "Quote request not sent", "error");
    }
  };

  const location = useLocation();

  const dummyCard = [
    {
      id: "1",
      img: (
        <>
          <SVG.AmazonLogo />
        </>
      ),
      title: "Amazon Sellers",
      content:
        "Optimize your Amazon business with our all-inclusive shipping and logistics services. From inventory management to last-mile delivery, we handle the complexities, so you can focus on growing your brand and delighting your customers.",
      fade: "fade-right",
    },
    {
      id: "2",
      img: (
        <>
          <SVG.ShopifyLogo />
        </>
      ),
      title: "Shopify Merchants",
      content:
        "Unlock the full potential of your Shopify store with our customized shipping and logistics services. From inventory management to seamless order fulfillment, we provide the expertise and support you need to streamline your operations and exceed customer expectations.",
      fade: "fade-top",
    },
    {
      id: "3",
      img: (
        <>
          <SVG.StoreLogo />
        </>
      ),
      title: "In-Store Retail",
      content:
        "Empower your in-store retail business with our comprehensive shipping and logistics services tailored specifically for importers. From seamless customs clearance and freight forwarding to efficient inventory management and distribution, we provide end-to-end solutions.",
      fade: "fade-left",
    },
  ];
  const teamCard = [
    {
      id: "1",
      img: (
        <>
          <SVG.timer />
        </>
      ),
      title: "Shipment within week",
      content:
        "Dedicated account manager for any size shipment with 7 day a week availability. Call, text, or email us with any questions or concerns!",
    },
    {
      id: "2",
      img: (
        <>
          <SVG.purse />
        </>
      ),
      title: "Fast and Economic Rates",
      content:
        "We compare hundreds of freight quotes to get you the lowest prices and fastest transportation of your goods globally.",
    },
    {
      id: "3",
      img: (
        <>
          <SVG.experty />
        </>
      ),
      title: "Ecommerce Expertise",
      content:
        "Founded by former Amazon employees and 9 figure ecommerce sellers, we know time is your most valuable asset. We handle the complicated paperwork and communicate proactively so you can focus on growing your business!",
    },
  ];

  const contactArray = [
    {
      id: "1",
      img: (
        <>
          <SVG.Storage />
        </>
      ),
      content: "Packaging and Storage",
    },
    {
      id: "2",
      img: (
        <>
          <SVG.Service />
        </>
      ),
      content: "Warehousing Service",
    },
    {
      id: "3",
      img: (
        <>
          <SVG.Transport />
        </>
      ),
      content: "Ground Transport",
    },
    {
      id: "4",
      img: (
        <>
          <SVG.Logistic />
        </>
      ),
      content: "Logistics Service ",
    },
  ];
  const manageArray = [
    {
      id: "1",
      img: bridgeImg,
      title: "Sea & Air Freight",
      fade: "fade-left",
    },
    {
      id: "2",
      img: Warehouse,
      title: "Warehousing",
      fade: "fade-left",
    },
    {
      id: "3",
      img: Clearance,
      title: "Customs Clearance",
      fade: "fade-left",
    },
    {
      id: "4",
      img: Duty,
      title: "Duty Fees",
      fade: "fade-left",
    },
    {
      id: "5",
      img: Global,
      title: "Logistics US or Global",
      fade: "fade-right",
    },
    {
      id: "6",
      img: Pack,
      title: "Pick and Pack",
      fade: "fade-right",
    },
    {
      id: "7",
      img: Labeling,
      title: "Amazon Labeling",
      fade: "fade-right",
    },
  ];
  const partnerArray = [
    {
      id: "",
      img: <SVG.wallaby />,
    },
    {
      id: "",
      img: <SVG.Shopify />,
    },
    {
      id: "",
      img: <img src={Colabri} />,
    },
    {
      id: "",
      img: <SVG.Medify />,
    },
    {
      id: "",
      img: <SVG.Anything />,
    },
    {
      id: "",
      img: <img src={AmazonFrit} />,
    },
  ];
  const navItems = [
    {
      id: 1,
      title: "Home",
      url: "/",
      section: "home",
    },
    {
      title: "What We Do",
      url: "/",
      section: "fullyManage",
      id: 2,
    },
    {
      title: "FAQ's",
      url: "/",
      id: 3,
      section: "faq",
    },
  ];
  const handleMoveToSection = (id) => {
    setSelectedSection(id);
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const activeHeader = useSelector((state) => state.modalHandler.activeHeader);

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const drawerWidth = 270;
  const drawer = (
    <Box onClick={handleDrawerToggle} className="drawer_menu_div">
      <Typography variant="h6" sx={{ my: 2 }}>
        <SVG.Logo />
      </Typography>
      <Divider />
      <List sx={{ px: 2 }} className="side_menu">
        {navItems.map((item, i) => (
          <Link
            onClick={() => {
              setTimeout(() => {
                handleMoveToSection(item.section);
              }, 100);
            }}
            to={item.url}
            key={i}
            className={`header-menu ${
              item.section === activeHeader ? "active" : ""
            }`}
          >
            {item.title}
          </Link>
        ))}
        <Link
          to="https://containercruncher.com/load-calculator"
          target="_blank"
        >
          <PurpleButtonComponent
            className="drawer-btn"
            text="Container Load Calculator"
            // onClick={(e) => {
            //   dispatch(setActiveHeader(""));
            //   dispatch(setCalculate(true));
            //   navigate("https://containercruncher.com/load-calculator");
            // }}
          />
        </Link>
      </List>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Container Cruncher</title>
        <meta
          name="description"
          content="Container Cruncher is a container tracking and management system that helps you track your containers and manage your container inventory."
        />
      </Helmet>
      <LandingPageStyleNew>
        <>
          {" "}
          <>
            <AppBar component="nav">
              <Container>
                <Toolbar className="main_header_new">
                  <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    className="header_logo"
                    variant="h6"
                    component="div"
                    onClick={(e) => navigate("/")}
                    style={{ cursor: "pointer" }}
                    //   sx={{ flexGrow: 1, display: { sm: "block" } }}
                  >
                    <SVG.Logo />
                  </Typography>

                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    {navItems.map((item, i) => (
                      <Link
                        onClick={() => {
                          setTimeout(() => {
                            handleMoveToSection(item.section);
                          }, 100);
                        }}
                        to={item.url}
                        key={i}
                        className={`header-menu ${
                          item.section === activeHeader ? "active" : ""
                        }`}
                      >
                        {item.title}
                      </Link>
                    ))}
                  </Box>
                  <Box>
                    {" "}
                    <OutLinePurpleButton
                      text="Get Quote"
                      className="me-2 border_btn_head"
                      onClick={(e) => {
                        navigate("/");
                        handleMoveToSection("quote");
                      }}
                    />{" "}
                    <Link to="https://containercruncher.com/load-calculator">
                      <PurpleButtonComponent
                        className="big-btn-header"
                        text="Container Load Calculator"
                        // onClick={(e) => {
                        //   // dispatch(setActiveHeader(""));
                        //   // dispatch(setCalculate(true));
                        //   navigate(
                        //     "/https://containercruncher.com/load-calculator"
                        //   );
                        // }}
                      />
                    </Link>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
            <Box component="nav">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
          </>
          ;{/* <HeaderMain className="container_new_header" /> */}
          <Container>
            <div className="blank_space"></div>
            <div className="landing_page_content" id="home">
              <div className="first_section">
                <Grid container>
                  <Grid md={6} item>
                    <div
                      className="left_content_data mt-5"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <Typography variant="h3">
                        100% white glove import management & logistics service
                        for ecommerce sellers.
                      </Typography>
                      <Typography variant="p" color="#2B3A90">
                        Importing goods shouldn't be complicated!
                      </Typography>
                      <Typography variant="h6" color="#747b8b">
                        You have a product and we'll do everything<br></br> else
                        to get it to where it needs to go!
                      </Typography>
                      <PurpleButtonComponent
                        text="Get a Quote"
                        className="mt-3"
                        onClick={() => handleMoveToSection("quote")}
                      />
                    </div>
                  </Grid>
                  <Grid md={6}>
                    <div
                      className="position_top_div mt-2"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <div className="first_div_img">
                        <img src={ContainerPic} />
                      </div>
                      <div className="second_div_img">
                        <img src={nurse} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="logistic_partner_section">
                <Grid container className="d-block">
                  <Grid md={12} className="text-center">
                    <Typography variant="h6" color="#545C71">
                      Trusted Logistics Partners
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  {partnerArray.map((data, index) => {
                    return (
                      <Grid item md={2} xs={12}>
                        <div className="logo_pics">{data.img}</div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
              <div className="perfect_content_div">
                <Grid container spacing={3} justifyContent="center">
                  <Grid
                    md={6}
                    xs={12}
                    data-aos="fade-down"
                    data-aos-duration="1500"
                  >
                    <Typography
                      variant="h3"
                      className="text-center heading_perfect"
                    >
                      Perfect For
                    </Typography>
                    <Typography className="text-center">
                      Businesses seeking to optimize container shipping
                      logistics and improve supply chain efficiency.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                  {dummyCard.map((item, index) => {
                    return (
                      <Grid md={4} key={index}>
                        <Box
                          className="perfect_card"
                          data-aos={item.fade}
                          data-aos-duration="1500"
                        >
                          {item.img}
                          <Typography variant="h6">{item.title}</Typography>
                          <p>{item.content}</p>
                        </Box>
                      </Grid>
                    );
                  })}

                  <Grid md={12} xs={12} item>
                    <div
                      className="text-center"
                      data-aos="fade-top"
                      data-aos-duration="1500"
                    >
                      {" "}
                      <Link to="https://containercruncher.com/load-calculator">
                        <PurpleButtonComponent
                          text="Get Started"
                          // onClick={(e) => {
                          //   navigate(
                          //     "/https://containercruncher.com/load-calculator"
                          //   );
                          // }}
                        />
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Container>
          ;
          <div className="calculator_div">
            <Grid container justifyContent="center" alignItems="center">
              <Grid md={6} data-aos="fade-up" data-aos-duration="1500">
                <Typography variant="h3" className="text-center ">
                  Want to know exactly how many units you can fit in a freight
                  container?
                </Typography>
                <Typography className="text-center">
                  Try Our Free Container Loading Calculator
                </Typography>{" "}
                <Link to="https://containercruncher.com/load-calculator">
                  <OutLinePurpleButton
                    text="Try Now"
                    className="mt-4"
                    onClick={(e) => {
                      // dispatch(setActiveHeader(""));
                      // dispatch(setCalculate(true));
                      // navigate("/https://containercruncher.com/load-calculator");
                    }}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                      color: "#6D5BDE",
                      border: "1px solid #fff",
                    }}
                  />
                </Link>
              </Grid>
            </Grid>
          </div>
          ;
          <div className="team_section">
            <Container>
              {" "}
              <Grid container>
                <Grid md={6} data-aos="fade-right" data-aos-duration="2000">
                  <Typography variant="h3">We’re Part Of Your Team</Typography>
                  <Typography color="#fff">
                    Say goodbye to the complexities and frustrations of
                    importing as our friendly<br></br> and dedicated team takes
                    care of every detail, ensuring a smooth and stress-free
                    experience tailored to your unique needs.
                  </Typography>
                  {teamCard.map((item, index) => {
                    return (
                      <Box className="team_card">
                        <Grid className="icon_box">{item.img}</Grid>
                        <Grid className="ms-3">
                          <Typography variant="h6">{item.title}</Typography>
                          <p>{item.content}</p>
                        </Grid>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="service_section">
            <Grid container justifyContent="center" alignItems="center">
              <Grid md={8}>
                <Typography variant="h3" className="text-center ">
                  How Our Services Work
                </Typography>
              </Grid>
              <Grid
                className=""
                container
                justifyContent="center"
                alignItems="center"
                spacing={6}
              >
                <Grid item={4}>
                  <div
                    className="service_card first_service"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <div>
                      <div className="icon_service">
                        <SVG.call />
                      </div>
                      <div className="text-center">
                        <Typography variant="h6">Contact Us</Typography>
                        <p>Contact us with your import/export needs</p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item={4}>
                  <div className="service_card second_service">
                    <div>
                      <div className="icon_service">
                        <SVG.dollar />
                      </div>
                      <div className="text-center">
                        <Typography variant="h6">Shipping quote</Typography>
                        <p>We will send you a shipping quote for approval</p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item={4}>
                  <div
                    className="service_card third_service"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    <div>
                      <div className="icon_service">
                        <SVG.check />
                      </div>
                      <div className="text-center">
                        <Typography variant="h6">Verify</Typography>
                        <p>
                          Once approved, we will handle everything to your end
                          destination
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <div className="boat_icon">
              <img src={Boat} />
            </div>
          </div>
          <div className="fully_manage_section" id="fullyManage">
            <Container>
              <Grid container justifyContent="center">
                <Grid
                  md={6}
                  xs={12}
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  <Typography variant="h3" className="text-center">
                    We Fully Manage
                  </Typography>
                  <Typography color="#fff" className="text-center">
                    We handle every step of the import process so you can rest
                    easy<br></br> and focus on your business with complete peace
                    of mind.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" spacing={3}>
                {manageArray.map((item, index) => {
                  return (
                    <Grid md={3} xs={12} item>
                      <Box
                        data-aos={item.fade}
                        data-aos-duration="1500"
                        className="manage_card"
                        style={{ backgroundImage: `url(${item.img})` }}
                      >
                        <Grid className="bottom_title">
                          {" "}
                          <Typography varient="h6">{item.title}</Typography>
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <div className="text-center mt-5 button_section_mb">
                <PurpleButtonComponent
                  text="Get a Quote"
                  onClick={() => handleMoveToSection("quote")}
                />
                {/* <OutLinePurpleButton
                text="Try Now"
                className="ms-4"
                sx={{
                  backgroundColor: "#fff",
                  border: "1px solid transparent",
                }}
                onClick={(e) => {
                  dispatch(setActiveHeader(""));
                  dispatch(setCalculate(true));
                  navigate("/load-calculator");
                }}
              /> */}
              </div>
            </Container>
          </div>
          <div className="form_section" id="quote">
            <Container>
              <Grid container justifyContent="space-between">
                <Grid md={5} data-aos="fade-down" data-aos-duration="2000">
                  <div className="left_section_form">
                    <Typography variant="h3">Get A Quote Today</Typography>
                    <Typography color="#fff" className=" mb-4 mt-2  ">
                      Contact us today for a free quote and explore the<br></br>{" "}
                      benefits of our 100% White Glove logistics services for
                      <br></br> your business.
                    </Typography>
                    <Grid container>
                      {contactArray.map((item, index) => {
                        return (
                          <Grid item md={6} xs={12} className="form_icon_div">
                            <div className="contact_icon mt-4 mb-1">
                              {item.img}
                            </div>
                            <Typography
                              variant="p"
                              color="#fff"
                              className="service_name"
                            >
                              {item.content}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  md={6}
                  xs={12}
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div className="form_card p-5">
                    <form>
                      <div className="form-group mb-3">
                        <label>
                          Name <small className="text-danger">*</small>
                        </label>
                        <input
                          className="form-control new_input w-100 mt-1"
                          type="text"
                          placeholder="Enter your name"
                          value={userData.name}
                          onChange={(e) =>
                            setUserData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="form-group new_input mb-3">
                        <label>
                          Email <small className="text-danger">*</small>
                        </label>
                        <input
                          placeholder="Enter your email address"
                          className="form-control new_input w-100 mt-1"
                          type="email"
                          value={userData.email}
                          onChange={(e) =>
                            setUserData((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label>Phone</label>
                        <input
                          placeholder="Enter your phone number"
                          className="form-control w-100 mt-1"
                          type="text"
                          value={userData.phone}
                          onChange={(e) =>
                            setUserData((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          placeholder="Message"
                          className="form-control new_input w-100 mt-1"
                          value={userData.message}
                          onChange={(e) =>
                            setUserData((prev) => ({
                              ...prev,
                              message: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <PurpleButtonComponent
                        text="Submit"
                        className="mt-4 "
                        sx={{ width: "100%" }}
                        onClick={sendEmail}
                        isProcess={isProcess}
                      />
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Container>
            <div className="truck_pic">
              <img src={Truck} />
            </div>
          </div>
          <div className="accordian_section" id="faq">
            <Grid container justifyContent="center" alignItems="center">
              <Grid md={5}>
                <div data-aos="fade-bottom" data-aos-duration="1000">
                  <Typography variant="h3" className="text-center ">
                    Got Questions? <br></br>We’ve Got You Covered?
                  </Typography>
                  <Typography className="text-center mb-5 mt-3" color="#545C71">
                    If you have any further questions, Get in touch<br></br>{" "}
                    with our friendly team
                  </Typography>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000">
                  <Accordion className="accordian_div" defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        color="#141D2E"
                        variant="h5"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          fontFamily: "'Inter', sans-serif",
                        }}
                      >
                        What is Container Cruncher?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordian_details">
                      <Typography>
                        Container Cruncher is a specialized web app offering a
                        comprehensive white glove import management and
                        logistics service exclusively designed for ecommerce
                        sellers. Our goal is to simplify the import process and
                        ensure a seamless shipping experience for our users.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="accordian_div">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        color="#141D2E"
                        variant="h5"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          fontFamily: "'Inter', sans-serif",
                        }}
                      >
                        How can Container Cruncher benefit ecommerce sellers,
                        particularly those using Amazon FBA and Shopify?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordian_details">
                      <Typography>
                        Container Cruncher is the perfect solution for ecommerce
                        sellers, particularly those using Amazon FBA and
                        Shopify, who seek peace of mind and a seamless shipping
                        experience. With our comprehensive suite of services, we
                        handle every aspect of the shipping process, from
                        warehousing to customs clearance and labeling. You can
                        trust us to manage these intricacies with meticulous
                        attention to detail, ensuring a white glove service that
                        simplifies the complexities of ecommerce shipments. By
                        entrusting Container Cruncher with your logistics
                        operations, you can focus on growing your business with
                        confidence, knowing that your shipments are in capable
                        hands.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="accordian_div">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        color="#141D2E"
                        variant="h5"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          fontFamily: "'Inter', sans-serif",
                        }}
                      >
                        Can Container Cruncher assist with Amazon FBA labeling,
                        packaging, and inventory management?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordian_details">
                      <Typography>
                        Certainly! Container Cruncher provides comprehensive
                        support for Amazon FBA sellers, including labeling,
                        packaging, and inventory management services. Our
                        experienced team ensures that your products are properly
                        labeled according to Amazon's guidelines, ensuring
                        smooth compliance and efficient fulfillment.
                        Additionally, we can assist with professional packaging
                        to protect your products during transit. With our
                        advanced inventory management system, you can track and
                        manage your inventory seamlessly, ensuring accurate
                        stock levels and timely replenishment. Container
                        Cruncher is your trusted partner for streamlining Amazon
                        FBA operations and optimizing your selling experience.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="accordian_div">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        color="#141D2E"
                        variant="h5"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          fontFamily: "'Inter', sans-serif",
                        }}
                      >
                        How does Container Cruncher ensure a seamless shipping
                        experience for Amazon FBA and Shopify sellers?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordian_details">
                      <Typography>
                        Container Cruncher ensures a seamless shipping
                        experience for Amazon FBA and Shopify sellers through a
                        combination of advanced technology and meticulous
                        processes. Our robust systems are designed to optimize
                        container space utilization, ensuring efficient packing
                        and minimizing shipping costs. With our precise
                        inventory management and tracking, you can trust that
                        your products are accurately labeled, packaged, and
                        ready for fulfillment. We take pride in our commitment
                        to excellence, consistently delivering exceptional
                        shipping services to Amazon FBA and Shopify sellers.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="accordian_div">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        color="#141D2E"
                        variant="h5"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          fontFamily: "'Inter', sans-serif",
                        }}
                      >
                        How can I get started with Container Cruncher?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordian_details">
                      <Typography>
                        Getting started with Container Cruncher is simple. Just
                        click on the "Get a Quote" button on our website or
                        shoot us an email with your contact details and specific
                        requirements. Our team will promptly respond, providing
                        you with all the necessary information, guiding you
                        through the onboarding process, and helping you
                        streamline your shipping and logistics operations.
                        Experience a seamless shipping experience with Container
                        Cruncher by taking the first step today!
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            </Grid>
          </div>
          <LandingPageFooter handleMoveToSection={handleMoveToSection} />
        </>
      </LandingPageStyleNew>
    </>
  );
}

export default LandingPageNew;
