// import ManageAdminSetting from "../pages/adminSetting";
// import AddContactUs from "../pages/crms/contact-us/addContactUs";
// import ManageContactUs from "../pages/crms/contact-us/manageContactus";
import { DataUsage } from "@mui/icons-material";
import ManageAdminSetting from "../pages/adminSetting";
import ProfileSetting from "../pages/mySetting";
import UsageStats from "../pages/usageStats";

const adminRoutes = [
  {
    path: "/usage-stats",
    exact: true,
    name: "usage-stats",
    component: <UsageStats />,
  },
  {
    path: "/manage-smtp-settings",
    exact: true,
    name: "manage-smtp-settings",
    component: <ManageAdminSetting />,
  },
  {
    path: "/profile-setting",
    exact: true,
    name: "profile-setting",
    component: <ProfileSetting />,
  },
];
export default adminRoutes;
