import React, { useEffect, useState } from "react";
import { InputBase, MenuItem, Paper, Select, Typography } from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import { setUpdateCargoPackingByField } from "../../reducers/modalSlice";
import { SPECIAL_CHAR_REGEX } from "../../utils/defaultValues";
import { formatValue } from "../../utils/calculation";

const SelectOption = ({
  defaultValue,
  height,
  inputValue,
  id,
  field,
  readOnly,
  inputTypeValue,
  maximum,
  quantity,
  handlerChange,
}) => {
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState("");
  const [readOnlyValue, setReadOnlyValue] = useState(false);
  const [inputType, setInputType] = useState("number");
  const [error, setError] = useState(null);

  useEffect(() => {
    setInitialValue(inputValue);
    setReadOnlyValue(readOnly);
    setInputType(inputTypeValue);
    dispatch(
      setUpdateCargoPackingByField({
        id,
        field,
        value: inputValue,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, dispatch, id, field]);

  const updateValue = (e) => {
    setInitialValue(e.target.valueAsNumber);
    handlerChange && handlerChange(e);
  };

  useEffect(() => {
    if (field === "weight") {
      if (initialValue >= maximum) {
        setError("Cargo overweighed than container");
      } else {
        if (initialValue < 0 || !SPECIAL_CHAR_REGEX.test(initialValue)) {
          setError("Invalid value");
        } else {
          setError(null);
        }
      }
    } else {
      if (initialValue >= maximum) {
        return setError("Cargo oversized than container");
      }
      if (initialValue < 0 || !SPECIAL_CHAR_REGEX.test(initialValue)) {
        setError("Invalid value");
      } else {
        setError(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onFocusOut = () => {
    dispatch(
      setUpdateCargoPackingByField({
        id,
        field,
        value: initialValue,
      })
    );
  };

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          height: height,
          border: error ? "1px solid red !important" : "1px solid #8FADC8",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, width: "50px" }}
          inputProps={{ "aria-label": "search google maps" }}
          value={formatValue(initialValue) || ""}
          onChange={updateValue}
          onKeyUp={onFocusOut}
          readOnly={readOnlyValue}
          type={inputType}
        />
        <span
          style={{
            color: "#8FADC8",
            fontSize: "12px",
            fontWeight: 500,
            paddingRight: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {defaultValue}
        </span>

        {/* <Select
          defaultValue={defaultValue}
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          sx={{ width: "60px" }}
          IconComponent={KeyboardArrowUpIcon}
        >
          {selectItems.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select> */}
      </Paper>
      {error ? (
        <span
          style={{
            color: "red",
            fontSize: "8px",
            position: "absolute",
          }}
        >
          {error}
        </span>
      ) : null}
    </>
  );
};
export default SelectOption;
