import React, { useEffect, useState } from "react";
import styles from "./profileSetting.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ProfileSetting() {
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <div className="">
          <div className="px-4 py-3">
            <div className="d-flex align-items-center justify-content-between mb-3"></div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <div className={styles.card_body}>
                    <div className={styles.page_title}>
                      <h3 className="mb-2">Email Setting</h3>
                    </div>
                    <label>Name</label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      className={styles.form_input}
                    />
                    <label>Email</label>
                    <Form.Control
                      type="email"
                      placeholder="*"
                      className={styles.form_input}
                    />

                    <div className="text-center  mt-5">
                      <Button className={styles.main_button}>Update</Button>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={styles.card_body}>
                    <div className={styles.page_title}>
                      <h3 className="mb-2">Password Setting</h3>
                    </div>
                    <label> Old Password</label>
                    <Form.Control
                      type="password"
                      placeholder="*********"
                      className={styles.form_input}
                    />
                    <label>New Password</label>
                    <Form.Control
                      type="password"
                      placeholder="*********"
                      className={styles.form_input}
                    />
                    <label>Confirm Password</label>
                    <Form.Control
                      type="password"
                      placeholder="*********"
                      className={styles.form_input}
                    />
                    <div className="text-center  mt-5">
                      <Button className={styles.main_button}>Update</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
