import { Button } from "@mui/material";
import React from "react";

import { styled } from "@mui/system";
export const ButtonStyled = styled("span")(({ theme }) => ({
  "& .MuiButton-root": {
    boxShadow: "none",
    color: "#fff",
    fontWeight: 500,
    borderRadius: "8px",
    padding: "12px 30px",
    backgroundColor: "#6D5BDE",
    border: "2px solid #6D5BDE",
    textTransform: "inherit",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",

    "@media (max-width:992px)": {
      width: "100%",
    },
  },
  "& .MuiButton-root:hover": { backgroundColor: "#6D5BDE" },
}));

const PurpleButtonComponent = ({ text, ...props }) => {
  return (
    <ButtonStyled>
      <Button sx={{ backgroundColor: "#6D5BDE" }} {...props}>
        {props.isProcess ? (
          <div class="box-4">
            <div class="loader-4">
              <div class="dbl-spin-1"></div>
              <div class="dbl-spin-2"></div>
            </div>
          </div>
        ) : (
          ""
        )}

        {text}
      </Button>
    </ButtonStyled>
  );
};
export default PurpleButtonComponent;
