import { createTheme } from "@mui/material/styles";

const colors = {
  primary: {
    main: "#2B3A90",
    fountainblue: "#54BBAB",
  },
  secondary: {
    main: "#009EE3",
  },
  others: {
    green: "#00B712",
    blue: "#009EE3",
    red: "#FF0000",
    white: "#fff",
  },
};

const defaultTheme = createTheme({
  palette: colors,
  typography: {
    fontFamily: `"Roboto"`,
  },
});

export default defaultTheme;
