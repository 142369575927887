import React from "react";
import { loaderStyles } from "../load-calculator/style";
import { CircularProgress } from "@mui/material";

export default function Loader({ show }) {
  return (
    <div
      style={{
        display: show ? "grid" : "none",
        ...loaderStyles,
      }}
    >
      <CircularProgress disableShrink />
      <h4>Calculating...</h4>
    </div>
  );
}
