import React, { useEffect, useState } from "react";
import styles from "./usageStats.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import {
  blackLine,
  checkTick,
  deleteIcon,
  editIcon,
} from "../../utils/svg.file";
export default function UsageStats() {
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "deviceId", headerName: "Device Id", height: 300, width: 300 },

    {
      field: "stepOne",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span className={styles.black_icon_btn}>{blackLine}</span>
          </>
        );
      },
    },
    {
      field: "stepTwo",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span className={styles.edit_icon_btn}>{checkTick}</span>
          </>
        );
      },
    },
    {
      field: "stepThree",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span className={styles.black_icon_btn}>{blackLine}</span>
          </>
        );
      },
    },
    {
      field: "stepFour",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span className={styles.edit_icon_btn}>{checkTick}</span>
          </>
        );
      },
    },
    {
      field: "stepFive",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span className={styles.black_icon_btn}>{blackLine}</span>
          </>
        );
      },
    },

    // {
    //   field: "Action",
    //   width: 150,
    //   renderCell: (cellValues) => {
    //     return (
    //       <>
    //         <span className={styles.edit_icon_btn}>{editIcon}</span>
    //         <span className={styles.delete_icon_btn}>{deleteIcon}</span>
    //       </>
    //     );
    //   },
    // },
  ];
  const rows = [
    {
      id: "1",
      deviceId: "543545353",
    },
  ];

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Usage Stats</h3>
              </div>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
}
