import React, { useEffect } from "react";
import HeaderMain from "../../components/headerMain";
import LandingPageFooter from "../../components/footer";
import LandingPageStyle from "../landing-page/landingPageStyle";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const ServiceAgreement = () => {
  useEffect(() => {
    console.log({ window });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Service Agreement</title>
        <meta
          name="description"
          content="Container Cruncher is a container tracking and management system that helps you track your containers and manage your container inventory."
        />
      </Helmet>
      <LandingPageStyle>
        <div className="fixing-page">
          <HeaderMain />
          <Container>
            <div
              className="landing_page_content"
              style={{ marginTop: "130px", padding: " 0 0px 50px" }}
            >
              <div className="">
                <h3>Service Agreement</h3>
              </div>
              <div className="service_agreement_content mt-2">
                {/* <strong id="docs-internal-guid-b1b14cdb-7fff-935a-706d-ab22f316a87c"> */}
                <p> </p>
                <p>
                  Welcome to Container Cruncher! This Agreement is between
                  Container Cruncher and you. This Agreement governs your access
                  to, and use of Container Cruncher&rsquo; sites and services
                  (&ldquo;Container Cruncher&rsquo; Services&rdquo;). The
                  Container Cruncher&rsquo; Services, corporate websites and
                  tools are collectively called the &ldquo;Container Cruncher
                  Platform&rdquo;. (Please note that some of the services
                  provided by Container Cruncher and its affiliates are governed
                  by other terms. If you have any questions, let us know.)
                </p>
                <p>
                  This Agreement was last updated on January 8, 2023&nbsp; and
                  it can be accessed. It is effective between you and Container
                  Cruncher as of the date of your acceptance of this Agreement.
                </p>
                <h2>1. Entering Into this Agreement with Container Cruncher</h2>
                <p>
                  By using Container Cruncher, you accept this Agreement, and
                  you agree to be bound by all of its terms. If you are entering
                  into this Agreement on behalf of a company (such as your
                  employer) or other legal entity, you represent and warrant
                  that you have the authority to bind that company or other
                  legal entity to this Agreement. In that case,
                  &ldquo;you&rdquo; and &ldquo;your&rdquo; will refer to that
                  company or other legal entity, except where context implies it
                  is referring to you as an individual. If you don&rsquo;t
                  accept the terms of this Agreement, you cannot use Container
                  Cruncher and/or the Container Cruncher Services.
                </p>
                <h2>2. Relationship to other agreements</h2>
                <p>
                  To the extent that there is a conflict between this Agreement
                  and any other documents executed between you and Container
                  Cruncher, including any Purchase Order or Statement of Work
                  between you and Container Cruncher which references this
                  Agreement, the provisions of this Agreement will govern,
                  unless you agree otherwise with Container Cruncher in a
                  written agreement.&nbsp;
                </p>
                <p>
                  There are also some other documents which operate in
                  conjunction with this Agreement and which are incorporated
                  into this Agreement by reference, namely:
                </p>
                <p>
                  All the relevant ones of which you should read carefully.
                  These documents and this Agreement may be updated from time to
                  time, and to the extent that there is a conflict between this
                  Agreement and any of these documents, the provisions of this
                  Agreement will govern.
                </p>
                <h2>3. Container Cruncher Services</h2>
                <h3>3.1. Types of Service.</h3>
                <p>
                  &nbsp;As described further down in this section, we offer
                  different types of services, provided by either our employees
                  or through vendors like the Sellers on Container Cruncher.
                  These services can be provided through our own websites
                  assets, through external vendors (like Container Cruncher
                  Sellers) through affiliated vendors or through digital
                  channels like Container Cruncher APIs. Services include but
                  are not limited to digital services (like Quote comparison or
                  online sales for Sellers), and physical services (like
                  Shipment transportation services facilitated by Container
                  Cruncher Sellers). (&ldquo;Freight Services&rdquo;).
                </p>
                <h4>3.1.1. Services for Container Cruncher Shippers:</h4>
                <p>
                  A company that chooses to compare, book, and/or manage Quotes
                  or Shipments from Sellers through Container Cruncher is a
                  &ldquo;Shipper&rdquo;. If that&rsquo;s you, you can use the
                  Container Cruncher Platform to search for suitable Sellers,
                  obtain Shipment Quotes from those Sellers and facilitate
                  Bookings between you and Sellers. You can also manage your
                  Shipments through Container Cruncher, communicate with the
                  Seller, and manage payments. Container Cruncher Shippers who
                  sign up on Marketplace Site via a Container Cruncher Partner
                  agree that the Partner employees have access to all their
                  data, including but not limited to the requests for Quotes,
                  Bookings, and Shipments.
                </p>
                <h4>3.1.2. Services for Container Cruncher Partners:</h4>
                <p>
                  If you operate a software platform whose customers need
                  Freight Services, such as a Fulfillment Center, Transportation
                  Management System, E-commerce Website and have signed a
                  partnership agreement with Container Cruncher, you are a
                  &ldquo;Container Cruncher Partner Software Provider&rdquo;.
                  Container Cruncher provides white label customer portals,
                  APIs, software client libraries, widgets, plugins to
                  e-commerce platforms, or other integrations to enable you to
                  digital freight tools, like rate estimates and duty estimates,
                  use Rates for Freight Services obtained from Sellers via
                  Container Cruncher, and offer shipping to your customers
                  through your own platform (the &ldquo;E-commerce
                  Tools&rdquo;). Container Cruncher Partners have access to all
                  the data of their customers, including but not limited to the
                  requests for Quotes, Bookings, and Shipments.
                </p>
                <h4>3.1.3. Services for Sellers:</h4>
                <p>
                  If you provide, offer to sell, and sell Freight Services
                  (including as a carrier, NVOCC, freight broker or freight
                  forwarder, insurer or customs broker), and if you choose to
                  sell Container Cruncher Services or authorize Container
                  Cruncher to offer your Rates to Shippers through the Container
                  Cruncher Platform, you are a &ldquo;Seller&rdquo; and you
                  engage in &ldquo;Selling&rdquo;. Unless agreed to otherwise
                  Container Cruncher is not a Seller and not party to any
                  agreement between a Seller and a Shipper, but we help you
                  manage your Rates, calculate prices, market your services
                  online , receive Bookings on your behalf, and act as your
                  collection agent to collect payments via Container Cruncher.
                  (Please note that from time to time an affiliate of Container
                  Cruncher may be a Seller on the Platform.) Sellers may be SaaS
                  Subscribers of Container Cruncher, the relationship of which
                  will be governed by a separate agreement unrelated to Selling
                  on the Container Cruncher Platform.
                </p>
                <h3>3.2. Services Used.</h3>
                <p>
                  You may use any, all or a combination of the Container
                  Cruncher Services. For example, if you are a freight
                  forwarder, you may be a Seller, a Shipper (e.g. buying from
                  freight forwarders as agents, or from carriers), and a
                  customer of Data Services. Some Sections of this Agreement
                  specifically apply to particular types of Container Cruncher
                  Services or users. Where Sections of this Agreement are not
                  specifically directed towards particular types of Container
                  Cruncher Services or users, they apply to all users of all
                  Container Cruncher Services.
                </p>
                <h3>3.3. Sub-contractors.</h3>
                <p>
                  We will sometimes use sub-contractors or other third parties
                  as part of the Container Cruncher Services&nbsp; and/or
                  Container Cruncher. When we do so, we will always remain
                  responsible for all of our obligations under this Agreement.
                </p>
                <h2>
                  4. Changes to the Agreement or Container Cruncher Services
                </h2>
                <h3>4.1 Agreement.</h3>
                <p>
                  Given the dynamic nature of our business and industry, we may
                  modify this Agreement at any time, at our sole discretion. If
                  we do so, we&rsquo;ll let you know either by posting the
                  modified Agreement on the Site, posting a notice on the Site
                  and/or the Container Cruncher App, or through other
                  communications. Please review changes to the MSA, TOS, etc. at
                  the beginning of each calendar quarter and let us know if any
                  changes are not acceptable and you wish to terminate your
                  relationship with us. Otherwise changes will be binding
                  following the change. If you don&rsquo;t agree to be bound by
                  the modified Agreement, then you will continue to be bound by
                  any existing obligations under the agreement that was in place
                  when you entered into an agreement for those services, however
                  you will not be able to otherwise use Container
                  Cruncher.&nbsp;
                </p>
                <h3>4.2 Container Cruncher Services.</h3>
                <p>
                  As part of our efforts to continually improve the Container
                  Cruncher Services, we may change or discontinue any part of
                  the Container Cruncher Services at any time at our sole
                  discretion. For example, we may remove a feature that users
                  don&rsquo;t use very often or add new features.&nbsp;
                </p>
                <h3>
                  4.3. Customer requested changes to the Container Cruncher
                  Services.
                </h3>
                <p>
                  When customers request changes to the Container Cruncher
                  Services (e.g., new features in the SaaS functionality),
                  Container Cruncher may at its discretion offer to make these
                  changes for free, for payment, or not at all. (This will
                  typically depend on whether Container Cruncher believes the
                  changes are beneficial to other customers.) All new
                  developments in the Container Cruncher Services, will be owned
                  by Container Cruncher, and may be offered to other Container
                  Cruncher customers, even if one customer is paying money
                  towards the development cost, unless otherwise agreed in
                  writing between Container Cruncher and that customer.
                </p>
                <h2>5. Registration and Intellectual Property Rights</h2>
                <h3>5.1 Accounts and Users:&nbsp;</h3>
                <h4>5.1.1 Marketing User:</h4>
                <p>
                  If you download content or subscribe for updates on Container
                  Cruncher, we will never disclose your information to external
                  parties and will always honor any request to be removed.
                </p>
                <p>5.1.2 Container Cruncher User Registration:</p>
                <p>
                  You must create an account (&ldquo;Account&rdquo;) to use
                  certain features of the Container Cruncher Platform and
                  Container Cruncher Services. You can register for an Account
                  via the Site or the Container Cruncher App. From time to time
                  we may also enable registration through your account with
                  certain third-party services such as Linkedin (each, an
                  &ldquo;SNS Account&rdquo;). If you choose the SNS Account
                  option, we&rsquo;ll create your Account by extracting from
                  your SNS Account certain personal information such as your
                  name and email address and other personal information that
                  your privacy settings on the SNS Account permit us to access.
                  It&rsquo;s important that you provide us with accurate,
                  complete and up-to-date information for your Account and you
                  agree to update such information, as needed, to keep it
                  accurate, complete and up-to-date. You agree that you
                  won&rsquo;t disclose your Account password to anyone and you
                  will notify us immediately of any unauthorized use of your
                  Account. You&rsquo;re responsible for all activities that
                  occur under your Account, whether or not you know about
                  them.&nbsp;
                </p>
                <h4>5.1.3. Business Accounts:</h4>
                <p>
                  If you are a business, you may create a &ldquo;Business
                  Account&rdquo; for a group of Accounts for your employees
                  and/or independent contractors providing services to your
                  business (typically all having the same
                  hello@containercruncher.com email address). Each employee or
                  independent contractor must have his/her own Account and you
                  can designate particular employees as administrators of the
                  Business Account (each, a &ldquo;Business Account
                  Administrator&rdquo;). Business Account Administrators can
                  allow employees and independent contractors who hold Accounts
                  to be part of the Business Account, and each employee or
                  independent contractor who is accepted into the Business
                  Account is then an &ldquo;Authorized User&rdquo;. You may also
                  authorize any of your employees or independent contractors
                  with an hello@containercruncher.com email address to create an
                  Account as part of your Business Account. You are responsible
                  for all actions taken under an Authorized User&rsquo;s
                  Account, whether or not such action was authorized by an
                  Authorized User. You are responsible for the security of each
                  Authorized User&rsquo;s Account and will not share (and will
                  instruct each Authorized User not to share) such Account
                  details with any other person or entity or otherwise permit
                  any other person or entity to access or use the Container
                  Cruncher Platform.
                </p>
                <h3>5.2. Intellectual Property:&nbsp;</h3>
                <h4>5.2.1. Container Cruncher IP:</h4>
                <p>
                  We&rsquo;ve worked for years to develop our technology and
                  we&rsquo;re proud of it. Subject to your rights described in
                  Section 5.2.2, we exclusively own all rights, title and
                  interest in, and to any software, tools, utilities, processes,
                  inventions, devices, methodologies, specifications,
                  documentation, techniques and materials of any kind used or
                  developed by us in connection with provision of the Container
                  Cruncher Services to you (collectively &ldquo;Container
                  Cruncher Materials&rdquo;) as well as any text, data, images,
                  information or other materials that are posted, generated,
                  provided or otherwise made available by us through the
                  Container Cruncher Platform (collectively &ldquo;Container
                  Cruncher Content&rdquo;). Our rights expressly include all
                  worldwide patent rights, copyright rights, trade secret
                  rights, know-how and any other intellectual property rights
                  (&ldquo;Intellectual Property Rights&rdquo;) in the Container
                  Cruncher Materials and the Container Cruncher Content. You
                  have no rights in any Container Cruncher Materials or
                  Container Cruncher Content except as granted to you in this
                  Agreement. You also cannot record or otherwise access or make
                  use of our data&nbsp; with automated programs, software, or
                  any other method of screen scraping.
                </p>
                <h4>5.2.2. Customer IP:</h4>
                <h5>5.2.2.1. Customer Content:</h5>
                <p>
                  Subject to this Section, you exclusively own all rights, title
                  and interest in and to any text, data, images, information or
                  other materials posted, generated, provided or otherwise made
                  available by you through the Container Cruncher Platform,
                  whether on a Custom Site or otherwise (collectively,
                  &ldquo;Customer Content&rdquo;), including all Intellectual
                  Property Rights in that Customer Content. You are solely
                  responsible for the accuracy, quality, integrity, legality and
                  reliability of all the Customer Content that you (or any
                  Authorized User) submit to the Container Cruncher Platform. By
                  making Customer Content available through the Container
                  Cruncher Platform, you grant to Container Cruncher a
                  non-exclusive, transferable, sublicensable, worldwide,
                  royalty-free license to access, process, analyze, store, use,
                  copy, and modify your Customer Content in connection with
                  operating the Container Cruncher Platform and providing the
                  Container Cruncher Services.&nbsp;
                </p>
                <h5>5.2.2.2. Anonymous Data and Reference Data:</h5>
                <p>
                  You grant us the right to use Customer Content (whether
                  related to your activities as a Shipper, Seller, or other) to
                  generate anonymous, statistical data based on such Customer
                  Content (for example, an average price calculated across
                  multiple Customers without naming those Customers), which will
                  not identify you (&ldquo;Anonymous Data&rdquo;), and to use
                  such Anonymous Data in any manner in which we see fit,
                  including selling such Anonymous Data. This Anonymous Data is
                  not Confidential Information for purposes of this Agreement
                  and is owned by Container Cruncher. Similarly, in order to
                  improve the Container Cruncher Services for you and others,
                  Container Cruncher may make use of reference data, which is by
                  its nature is not confidential or proprietary, including, but
                  not limited to, formats of industry tariffs or tenders (e.g.,
                  column structures with no pricing data), carrier public
                  surcharge data, carrier public &ldquo;rack rate&rdquo; prices,
                  fee codes, commodity codes, public service schedules, and
                  names/web sites of corporations (&ldquo;Reference Data&rdquo;)
                  and this Reference Data is not Customer Content for purposes
                  of this Agreement.&nbsp;
                </p>
                <h4>5.2.3. Software License:</h4>
                <p>
                  Most Container Cruncher features are provided to you as
                  software-as-service accessible via the Site, but in certain
                  situations we provide software to install on your devices.
                  This includes E-commerce Tools, and other software from time
                  to time, each, and together, as applicable, &ldquo;Installed
                  Software&rdquo;). We grant you a worldwide, non-exclusive,
                  non-transferable and non-sublicensable license to download and
                  install a copy of any Installed Software on a mobile device or
                  computer that you own or control, and to run such copy of
                  Installed Software solely in conjunction with your use of the
                  Container Cruncher Services. You agree not to (i) copy the
                  Installed Software, except for making a reasonable number of
                  copies for backup or archival purposes; (ii) distribute,
                  transfer, sublicense, lease, lend or rent the Installed
                  Software to any third party; (iii) reverse engineer, decompile
                  or disassemble the Installed Software; or (iv) make the
                  functionality of the Installed Software available to multiple
                  users (where the Installed Software is designed for a single
                  user) through any means; and (v) use the Installed Software in
                  any way other than the way it is intended to be used. You
                  agree not to modify or create derivative works based on the
                  Installed Software however, you are allowed to modify the
                  E-commerce Tools for the purposes of integrating the
                  E-commerce Tools with your e-commerce platform. If you stop
                  using the Container Cruncher Services you agree to uninstall
                  all copies of the Installed Software on your devices
                  immediately.&nbsp;
                </p>
                <h4>5.2.4. Trademark License:</h4>
                <p>
                  We&rsquo;re proud to be doing business with you. We grant you
                  a non-exclusive, non-transferable, non-assignable,
                  royalty-free, worldwide license, without the right to grant
                  sublicenses, to use the &ldquo;Container Cruncher&rdquo; name
                  and logo on your list of vendors or list of technologies you
                  use. All goodwill associated with the use of such name and
                  logo inures to the benefit of Container Cruncher. You grant us
                  a non-exclusive, transferable, assignable, royalty-free,
                  worldwide license to list your name and logo on Container
                  Cruncher&rsquo; list of customers (whether in a general
                  customer list or a list of customers for a specific product,
                  service or sector) and to publish your reviews. All goodwill
                  associated with the use of your name and logo inures to your
                  benefit. We will use the Seller&rsquo;s logo when showing its
                  Quotes, on the Seller Profile Page, and in other places for
                  marketing, and customer support and other services related to
                  offering on Container Cruncher.com to attract Shippers, and in
                  order to generate business for Sellers.
                </p>
                <h4>5.2.5. Data License:</h4>
                <p>
                  From time to time we may make available certain industry data
                  such as indicative pricing data, whether through our Site or
                  Container Cruncher APIs (&ldquo;Container Cruncher
                  Data&rdquo;) or otherwise and in such event we may sell a
                  non-exclusive, non-transferable, non-assignable, royalty-free
                  license to you to copy and publish (but not modify) such
                  Container Cruncher Data, subject to the terms and conditions
                  of such license.&nbsp; Any Container Cruncher Data displayed
                  on www.Container Cruncher.com or associated sites may be used
                  by you provided that you clearly acknowledge Container
                  Cruncher as the source of such data together towith every copy
                  of the Container Cruncher Data that you make, and also agree
                  to fulfill any other Container Cruncher conditions
                  communicated to you governing use of this Container Cruncher
                  Data.
                </p>
                <h4>5.2.6. Reservation of Rights:</h4>
                <p>
                  We reserve all rights in and to Intellectual Property Rights,
                  Software, Container Cruncher Materials and Container Cruncher
                  Content not expressly granted to you under this Agreement.
                </p>
                <h3>5.3. Data Maintenance and Backup Procedures.</h3>
                <p>
                  We know how important your data is to you. We will use
                  commercially reasonable efforts to store, secure, and backup
                  your Customer Content, including selecting reputable
                  infrastructure or platform providers. We will follow our
                  standard storage, backup and archival procedures for all
                  Customer Content submitted into the Container Cruncher
                  Platform. In the event of any loss or corruption of Customer
                  Content, we will use our commercially reasonable efforts to
                  restore the lost or corrupted Customer Content from the latest
                  backup of such Customer Content maintained by us. WE WILL NOT
                  BE RESPONSIBLE FOR ANY LOSS, DESTRUCTION, ALTERATION,
                  UNAUTHORIZED DISCLOSURE OR CORRUPTION OF CUSTOMER CONTENT
                  CAUSED BY ANY THIRD PARTY. OUR EFFORTS TO RESTORE LOST OR
                  CORRUPTED CUSTOMER CONTENT PURSUANT TO THIS SECTION 5.3 WILL
                  CONSTITUTE OUR SOLE LIABILITY AND YOUR SOLE AND EXCLUSIVE
                  REMEDY IN THE EVENT OF ANY LOSS OR CORRUPTION OF CUSTOMER
                  CONTENT IN CONNECTION WITH THE CONTAINER CRUNCHER SERVICES AND
                  CONTAINER CRUNCHER PLATFORM.
                </p>
                <h2>6. Buying (including E-commerce Services)</h2>
                <h3>6.1. Buying Freight Services:</h3>
                <h4>6.1.1. Quotes:</h4>
                <p>
                  You can use the Container Cruncher Platform to look for
                  Sellers and to request price Quotes and related terms for a
                  Shipment and delivery of goods (&ldquo;Quotes&rdquo;). In
                  addition to discovering new Sellers, and obtaining Quotes, we
                  add features from time to time to help you evaluate Sellers
                  and manage your Quotes, Shipments and shipping documents. If
                  you are only using the Container Cruncher Services as a
                  Shipper, you don&rsquo;t need to pay to use the Container
                  Cruncher Services, but of course you do need to pay for any
                  Freight Services that you buy from a Seller.
                </p>
                <h4>6.1.2. Where you can Buy:</h4>
                <p>
                  You can access Buying services through a Marketplace
                  Site.&nbsp;
                </p>
                <h4>6.1.3. Representations:</h4>
                <p>
                  If you are a Shipper, by using the Container Cruncher Services
                  you represent to Container Cruncher and to Sellers that you
                  understand the way in which Freight Services work and that you
                  hold all the necessary permits to legally procure any Freight
                  Services that you buy.
                </p>
                <h3>6.2. Accepting Quotes:&nbsp;</h3>
                <h4>6.2.1. Bookings:</h4>
                <p>
                  When you accept a Quote, we create an &ldquo;Booking&rdquo; on
                  your behalf which is a legally binding agreement between you
                  and the Seller who provided that Quote. Sellers, not Container
                  Cruncher, are responsible for honoring any Bookings and
                  performing the applicable Freight Services. The identity of
                  the Seller will be clearly marked on the Quote. As a Shipper,
                  you agree to accept any terms, conditions, rules and
                  restrictions associated with such Freight Services that are
                  imposed by the Seller. You acknowledge and agree that you, and
                  not Container Cruncher, will be responsible for performing the
                  Shipper&rsquo;s obligations of the Booking and any other
                  contracts with Sellers.
                </p>
                <h3>6.3. Container Cruncher is Not the Seller.</h3>
                <p>
                  We help you find Quotes from Sellers, but unless otherwise
                  explicitly agreed to in writing, we are not a Seller and we
                  don&rsquo;t provide Freight Services. Sellers are not agents
                  or contractors of Container Cruncher. We don&rsquo;t endorse
                  any unaffiliated Sellers, and we don&rsquo;t in any way
                  supervise, direct or control an unaffiliated Seller&rsquo;s
                  performance of Freight Services, nor do we warrant that any
                  Seller will meet your expectations in performing Freight
                  Services nor do we guarantee specific service levels. We are
                  not obliged to conduct background checks on any Seller, but we
                  might do so on a discretionary basis (and have removed Sellers
                  from Container Cruncher when deemed appropriate). In order to
                  assist you, we publish reviews of Sellers or vet particular
                  Sellers from time to time, but we don&rsquo;t guarantee the
                  accuracy of any reviews and warn you that reviews can be
                  misleading. Your access to and use of the Container Cruncher
                  Services is at your own risk and we encourage you to do your
                  own research and due diligence on any Seller with which you
                  may engage through a Shipment, just like you would if you
                  found a Seller outside of the Container Cruncher Platform. We
                  do act as collection agent for Sellers so if you have
                  purchased freight from a Seller, Container Cruncher will have
                  been authorized by the Seller to collect payment and you agree
                  that if you fail to make payment due, Seller and/or Container
                  Cruncher as their agent will have the right to detain release
                  of a cargo shipped under the Container Cruncher Platform until
                  the freight is paid in full.
                </p>
                <h3>6.4. Disputes with Sellers.</h3>
                <p>
                  We encourage you to communicate directly with Sellers
                  regarding any Bookings that you make (whether using
                  communication tools on the Container Cruncher Platform or
                  offline). If you have any concerns about any Shipment, you
                  should try to resolve your concerns directly with the Seller
                  in question. If you are not able to resolve your concerns with
                  the relevant Seller, you can make a formal complaint to us and
                  we will address such complaints as we consider appropriate
                  under the circumstances. By using Container Cruncher, you
                  agree that any legal remedy or liability that you seek to
                  obtain for actions or omissions of Sellers or other third
                  parties will be limited to a claim against the particular
                  Sellers or other third parties who caused you harm, and you
                  agree not to attempt to impose liability on us, or seek any
                  legal remedy from us with respect to such actions or
                  omissions.
                </p>
                <h3>6.5. Shipper Payment Terms:</h3>
                <h4>6.5.1. Container Cruncher as Payment Agent:</h4>
                <p>
                  You agree to pay the Seller for the Freight Services
                  associated with a Shipment. We act as the payment agent of the
                  Seller (also known as agent of the payee). This means that we
                  collect money from you on behalf of the Seller, which we then
                  pass on to the Seller. We have our own policies and practices
                  as to how and where we collect money from you and how we pass
                  that money on to the Seller. Due to the complexities of our
                  industry, policies and practices may vary depending on factors
                  such as geography, volume and type of Freight Services,
                  Shipper&rsquo;s track record, and Seller&rsquo;s track record.
                  For example, payment may or may not be required at the time of
                  making a Booking, or we may accept part payment at the time of
                  making a Booking with the balance being required at a later
                  time, such as prior to releasing the cargo from the
                  destination country&rsquo;s customs. The terms of the payment
                  policy applying to your Booking will be presented to you at
                  the time of making the Booking and there may be further
                  guidelines published from time to time in the Seller&rsquo;s
                  TOS and Container Cruncher.com Terms and Conditions for
                  Shippers.&nbsp;
                </p>
                <h4>6.5.2. Quotes:</h4>
                <p>
                  You agree to pay the amounts presented to you in a Quote for a
                  Booking you make with a Seller, and any other amounts that you
                  owe to the Seller in connection with a Shipment. You also
                  agree to provide any documentation reasonably requested by a
                  Seller for the purposes of your Shipment and you are
                  responsible for the accuracy and timely submission of such
                  documentation to the Seller, whether that occurs through the
                  Container Cruncher Platform or otherwise. We will use
                  reasonable efforts to ensure that Quotes displayed on the
                  Container Cruncher Platform are all-inclusive. However, as we
                  have already explained, each Seller has its own terms and
                  conditions of sale which will apply to any Booking you make
                  with that Seller, and you may be charged an amount that is
                  additional to the amount of the Quote in certain
                  circumstances. For example, if the Seller&rsquo;s truck waits
                  hours for your cargo, or your cargo weighs more than you
                  declared, you will incur extra charges in addition to what
                  appeared in the relevant Quote, as determined by the
                  Seller&rsquo;s TOS and Seller&rsquo;s terms and conditions.
                  Similarly most Sellers will disclaim liability for delays
                  caused by force majeure events including weather and strikes.
                </p>
                <h4>6.5.3. Insurance, Customs Brokerage and Import Duties:</h4>
                <p>
                  Unless specifically stated, freight insurance and customs
                  brokerage charges are not included in a Quote for Freight
                  Services. Import duties you may have to pay to the destination
                  government are never included unless the Quote explicitly
                  states Delivery Duty Paid (DDP). Value added tax and/or sales
                  tax (if applicable) are only included if they are specifically
                  listed in the fee breakdown of a Booking.
                </p>
                <h4>6.5.4. Changes to Payment Policies:</h4>
                <p>
                  In our continued effort to improve the Container Cruncher
                  Services, we reserve the right to make changes to our payment
                  policies and practices at any time, for any reason and without
                  advance notice to you. We will always make the current payment
                  policy and practice clear at the time of making a Booking. We
                  may also offer certain Shippers credit to make a payment at a
                  later date. We reserve the right to charge extra fees for
                  these arrangements (&ldquo;Processing Fees&rdquo;) and if you
                  select these services, your payment obligation with respect to
                  such Processing Fees is directly to Container Cruncher and you
                  agree to pay these Processing Fees to Container
                  Cruncher.&nbsp; We may sometimes at our discretion offer
                  different payment options such as the ability to pay in
                  different currencies or through different payment mechanisms
                  and charge you&nbsp; through different Container Cruncher
                  entities, including Container Cruncher Inc., the US subsidiary
                  of Container Cruncher .
                </p>
                <h3>6.6. E-commerce Services:</h3>
                <p>
                  If you are an E-commerce Provider and you make Quotes
                  available to your customers through your e-commerce platform
                  (for example, in the &ldquo;shipping&rdquo; section of your
                  shopping cart functionality), you function as a Shipper of the
                  Freight Services for the purposes of this Agreement and all of
                  the terms of this Agreement that apply specifically to
                  Shippers, including all the terms of this Section 7, apply to
                  you. Alternatively, Container Cruncher may also allow you to
                  obtain shipping price estimates through use of our Container
                  Cruncher API (&ldquo;Container Cruncher Estimates&rdquo;) and
                  to present the Container Cruncher Shipping Estimates to your
                  own customers together with a hyperlink to the Container
                  Cruncher Platform, in which case your customers will be free
                  to become Shippers in their own rights.
                </p>
                <h2>7. Selling</h2>
                <h3>7.1. Selling Freight Services:</h3>
                <h4>7.1.1. Where You Can Sell:</h4>
                <p>
                  You can use the Container Cruncher Platform to sell your
                  Freight Services to Shippers introduced by Container Cruncher.
                  As a Seller, you will choose which (if any) of your Freight
                  Services are actively offered to Shippers through the
                  Container Cruncher Platform. You may also have the opportunity
                  to filter which categories of Shippers see Quotes generated
                  for your Freight Services.&nbsp;
                </p>
                <h4>7.1.2. Representations:</h4>
                <p>
                  If you are a Seller, by using the Container Cruncher Services
                  you represent to Container Cruncher and to Shippers that you
                  understand the way in which Freight Services work and that you
                  hold all necessary permits, licenses, knowledge and experience
                  to offer the Freight Services that you provide. You also
                  understand that given the nature of the business and in order
                  to be in compliance with applicable laws and regulations we
                  may be required from time to time to request that you provide
                  us with data about your company or Shipments which we
                  reasonably require for compliance and financial purposes and
                  you agree that continued sales on the site may be subject to
                  receipt of the requested data.
                </p>
                <h4>7.1.3. Reselling:</h4>
                <p>
                  Businesses which are both Shippers and Sellers (e.g freight
                  forwarders) may configure the Container Cruncher Platform to
                  automatically search for Quotes which are available for them
                  to Buy, markup the prices in those Quotes, and use that in
                  creating a Quote which they offer to Sell
                  (&ldquo;Reselling&rdquo;). Often the resold Freight Services
                  will be combined with broader Freight Services. For example a
                  Seller freight forwarder who has its own sell Rates for
                  port-to-port shipping, may wish to Resell some other
                  Seller&rsquo;s trucking Quotes for door delivery, and will
                  configure Container Cruncher to combine these two Rates into a
                  single port-to-door Quote which they offer to the Shipper.
                  <br />
                  <br />
                </p>
                <p>
                  As a Seller you agree that any Shipper who you permit to see
                  your Quotes through the Container Cruncher Platform may engage
                  in Reselling, that is may use the Container Cruncher Platform
                  to automatically create Quotes which are based, or partly
                  based, on the prices in your Quote, plus an optional (positive
                  or negative) markup. Although they use your price in Quoting,
                  should they secure the Booking they are not obliged to book
                  the relevant part of the Freight Service with you. Of course,
                  you may also Resell Freight Services offered to you by other
                  Sellers, and use that to increase the range of Freight
                  Services you offer for Selling (for example you can use
                  Reselling to offer Freight Services on new lanes and modes, or
                  extending a port-to-port offering to door-to-door). Please
                  remember that when you Resell you are entering into two
                  bilateral agreements with the Seller who is supplying you and
                  with the Shipper you SellContainer Cruncher is a party to
                  neither agreement.
                </p>
                <h3>
                  7.2. Container Crunche acts as a facilitator of trade between
                  Shippers and Sellers, and in this capacity Container Cruncher
                  provides you with the following services and also acts as your
                  collection Agent:
                </h3>
                <h4>7.2.1. Container Cruncher will:</h4>
                <ol>
                  <li aria-level="3">
                    <p>
                      Automate generation of your price Quotes based on the
                      information (for example, tariffs) you provide through the
                      SaaS Services and via your use of the Container Cruncher
                      Platform;
                    </p>
                  </li>
                  <li aria-level="3">
                    <p>
                      Advertise and promote your Freight Services on the
                      Container Cruncher Platform, subject to the configuration
                      options you choose;
                    </p>
                  </li>
                  <li aria-level="3">
                    <p>
                      Facilitate some communication or document exchange between
                      you and the Shipper; and
                    </p>
                  </li>
                  <li aria-level="3">
                    <p>
                      Act as your Collection Agent to collect payment from
                      Shippers on your behalf (also known as agent for payee).
                      By accepting this Agreement you appoint us as your limited
                      agent just for the purposes of collection. Once we collect
                      payment due, you agree that you have no claims against
                      Shipper for such payment.&nbsp; Contract with Shipper: If
                      a Shipper engages you for provision of Freight Services by
                      making a Booking, the Booking agreement and any subsequent
                      agreement that you enter into with the Shipper will be
                      between you and the Shipper only. We don&rsquo;t act as
                      your insurer, broker, contracting agent or other
                      representative. You acknowledge and agree that you, and
                      not Container Cruncher, are and will be responsible for
                      providing the Freight Services and performing your other
                      obligations under any agreements with Shippers, and we are
                      not a party to such agreements and disclaim all liability
                      arising from or related to such agreements. That said, in
                      order to help us collect payment on your behalf, you agree
                      upon our request to&nbsp; hold off releasing cargo to a
                      Shipper if the Shipper has not fulfilled its payment
                      obligations.
                    </p>
                  </li>
                  <li aria-level="3">
                    <p>
                      Occasionally we may need your help collecting from a
                      Shipper. We may request and you agree that a Shipment
                      purchased through Container Cruncher not be released so
                      long as the Shipper of that Shipment owes money to any
                      Seller.
                    </p>
                  </li>
                  <li aria-level="3">
                    <p>
                      Seller&nbsp; is fully and solely responsible for
                      determining the correct VAT/tax consequences of the
                      invoice for Freight Services.
                    </p>
                  </li>
                </ol>
                <h4>7.2.2. No Endorsement of Shippers:</h4>
                <p>
                  We don&rsquo;t endorse any Shippers. We are not obliged to
                  conduct background checks on any Shipper, but we might do so
                  on a discretionary basis and we may vet particular Shippers
                  from time to time. Your access to and use of Container
                  Cruncher and the Container Cruncher Services is at your own
                  risk and we encourage you to do your own research and due
                  diligence on any Shipper with which you may engage through a
                  Shipment, just like you would if you found a Shipper outside
                  of Container Cruncher.
                </p>
                <h4>7.2.3. No Endorsement of Sellers:</h4>
                <p>
                  We don&rsquo;t endorse any Sellers or Freight Services, and
                  using the Container Cruncher Services does not mean that you
                  or your Freight Services are endorsed in any way by us. We
                  don&rsquo;t necessarily conduct background checks on any
                  Seller, but we reserve the right to do so on a discretionary
                  basis and you agree to cooperate with us and to provide us
                  with all information we request from you in the event that we
                  decide to conduct a background check on you as well as
                  accurate information for us to place in your profile in the
                  Container Cruncher Platform. By virtue of selling on the
                  Container Cruncher, you are not an agent, contractor, employee
                  or affiliated entity of Container Cruncher and we do not in
                  any way supervise, direct or control your performance of
                  Freight Services.
                </p>
                <h4>7.2.4. Subagent:</h4>
                <p>
                  In order for Container Cruncher to find more customers for
                  you, you authorize Container Cruncher to appoint sub-agents
                  who may also market the Quotes that are generated for your
                  Freight Services via Container Cruncher.
                </p>
                <h3>7.3. Disputes with Shippers.</h3>
                <p>
                  Disputes with Shippers shall be governed by the relevant
                  provisions in the Seller&rsquo;s SOP. By using Container
                  Cruncher, you agree that any legal remedy or liability that
                  you seek to obtain for actions or omissions of Shippers or
                  other third parties will be limited to a claim against the
                  particular Shippers or other third parties who caused you harm
                  and you agree not to attempt to impose liability on us, or
                  seek any legal remedy from us with respect to such actions or
                  omissions.
                </p>
                <h3>7.4. Seller Fees:</h3>
                <h4>7.4.1. Quote and Applicable Terms:</h4>
                <p>
                  You agree that the price that a Shipper will pay for your
                  Freight Services is the amount specified in the Quote selected
                  by the Shipper. Although the Quotes are generated through
                  Container Cruncher, they are still your Quotes and you are
                  bound by the Quote which is selected by the Shipper. It is
                  your obligation to ensure the accuracy and currency of
                  tariffs, data and other Customer Content provided to us
                  through Container Cruncher, which is used to generate Quotes.
                  We encourage you to regularly check and test this aspect of
                  your use of Freight Services to ensure that details such as
                  prices, transit time and terms and conditions are displayed
                  correctly and that the Quotes generated by Container Cruncher
                  on your behalf for your Freight Services are accurate and meet
                  your requirements.
                </p>
                <h4>7.4.2. Remittance of Funds:</h4>
                <p>
                  We will charge you a marketing success fee for each Booking
                  made with you by a Shipper (a &ldquo;Seller Fee&rdquo;).
                  Seller Fees are described in the Container Cruncher Price
                  List. We reserve the right to change the Seller Fees from time
                  to time but we will notify you of any changes in advance of
                  their implementation. We will invoice you for the Seller Fees
                  and either (i) deduct the Seller Fees from the payments made
                  by Shippers collected by us on your behalf before remitting
                  funds to you, in which case you authorize us to make such
                  deduction, or (ii) in case we don&rsquo;t make such deduction
                  you agree to pay the Seller Fees within thirty days of the
                  date of an invoice we send you.&nbsp;
                </p>
                <h4>7.4.3. Payment Policies:</h4>
                <p>
                  We aim to have our Sellers paid in a timely and convenient
                  manner. We have our own policies and practices as to how and
                  when we collect money from Shipper and how we pass that money
                  on to you, and under what circumstances (if any) we guarantee
                  payment to you if a Shipper doesn&rsquo;t pay us. These
                  policies are detailed in the TOS. We reserve the right to make
                  changes to our payment policies and practices at any time and
                  for any reason, but we will notify you of any changes by email
                  and/or posting to the Container Cruncher Platform.
                </p>
                <h3>7.5. Non-Circumvention.</h3>
                <p>
                  We&rsquo;ve worked hard to develop&nbsp; Container Cruncher
                  and we have invested and continue to invest a lot of time and
                  money in connecting you with Shippers. When a specific Shipper
                  books a Shipment with you through Container Cruncher
                  (&ldquo;Initial Booking&rdquo;) for Freight Services, Seller
                  and Shipper both agree that for at least twelve (12) months
                  Seller will not not target or directly sell Freight Services
                  to Shipper introduced through Container Cruncher for 12 months
                  from the introduction of such Shipper and Shippers shall not
                  deal directly with Seller introduced through Container
                  Cruncher (&ldquo;Non-circumvention&rdquo;). We reserve the
                  right to charge you Sellers and Platform Fees, to terminate or
                  suspend your Account, to terminate or suspend your access to
                  the SaaS Services or to do any combination of the foregoing in
                  respect of any breach of this Section by you.
                  Non-circumvention will not apply if the same Shipper had
                  booked services from Seller&rsquo;s organization in the six
                  months prior to the Initial Booking. Non-circumvention will
                  also not apply if a different part of Seller&rsquo;s
                  organization sells to the Shipper coincidentally, without
                  being aware of any Booking from that Shipper that came through
                  Container Cruncher, or if the sale is the result of a general
                  solicitation which would have occurred irrespective of whether
                  Seller and Shipper were previously introduced by Container
                  Cruncher. Non-circumvention will not apply with respect to
                  selling services which are not offered through Container
                  Cruncher.
                </p>
                <h2>8. Data Services</h2>
                <p>
                  Container Cruncher may offer Data Services from time to time,
                  such as services to convert carrier contracts and other
                  freight tariffs and their updates/amendments into a format
                  that can be imported into Container Cruncher, or services to
                  convert freight tenders (also known as RFQs or bids) between
                  different Excel formats.
                </p>
                <h3>8.1. Statements of Work:&nbsp;</h3>
                <h4>8.1.1. Engagement:</h4>
                <p>
                  If you want to engage Container Cruncher to perform Data
                  Services, we&rsquo;ll prepare a statement of work or purchase
                  order that describes specific Data Services to be performed
                  for you by us, that we both sign (any such document is called
                  a &ldquo;Statement of Work&rdquo;). Each Statement of Work
                  will expressly refer to this Agreement, will form a part of
                  this Agreement, and will be subject to the terms and
                  conditions contained in this Agreement.&nbsp;
                </p>
                <h4>8.1.2. Designated Contacts:</h4>
                <p>
                  We will each designate in the Statement of Work one or more
                  individuals who will serve as the point(s) of contact between
                  us for all matters relating to the Data Services to be
                  performed under that Statement of Work and we may change the
                  designated contacts from time to time.&nbsp;
                </p>
                <h3>8.2. Data Services Payment Terms:&nbsp;</h3>
                <h4>8.2.1. Data Services Fees:</h4>
                <p>
                  You will pay us fees for our performance of Data Services,
                  either according to the Container Cruncher&nbsp; Price List,
                  if you have chosen a standard Data Services package listed
                  there, or calculated in accordance with the terms set out in
                  the applicable Statement of Work.&nbsp;
                </p>
                <h4>8.2.2. Payment Terms:</h4>
                <p>
                  Unless otherwise agreed, we will invoice you on or after the
                  last day of a calendar quarter (or on another schedule
                  otherwise agreed with you) for all applicable fees for the
                  Data Services performed in that calendar quarter. You agree to
                  pay all invoices within 21 days of the date of the invoice.
                </p>
                <h3>8.3. Responsibility:&nbsp;</h3>
                <h4>8.3.1. Disclaimer:</h4>
                <p>
                  We will use commercially reasonable efforts to provide the
                  Data Services in a timely manner and free of errors. However,
                  you are responsible for the data that you provide to us both
                  before and after it has been processed through Data Services.
                  You must check the accuracy of your data after engaging Data
                  Services. For example, if we convert and import a freight
                  tariff for you, you should check that the resulting Quotes or
                  Rates are accurate. We expressly disclaim any and all
                  liability arising from or related to any errors in data that
                  occur as a result of our provision of Data Services.&nbsp;
                </p>
                <h4>8.3.2. Protection of Data:</h4>
                <p>
                  All data you provide in the context of Data Services is
                  Customer Content for the purposes of this Agreement. However,
                  Container Cruncher will retain ownership of any reusable
                  templates we create which do not contain any pricing
                  information, including templates for reading the structure of
                  carrier-specific tariffs, or templates for reading and writing
                  shipper-specific tenders, and carrier surcharges which are not
                  customer specific (&ldquo;Template Data&rdquo;). Template Data
                  is not Customer Content.
                </p>
                <h2>9. Mutual Non-Disclosure Agreement</h2>
                <h3>9.1. Confidential Information.</h3>
                <p>
                  &ldquo;Confidential Information&rdquo; means:(i) Container
                  Cruncher Materials andContainer Cruncher Content; (ii)
                  Customer Content; (iii) any business or technical information
                  that a party discloses to the other party and designates as
                  &ldquo;confidential&rdquo; or &ldquo;proprietary&rdquo; at the
                  time of disclosure; and (iii) any information that, due to its
                  nature or the circumstances of its disclosure, the receiving
                  party knows or has reason to know should be treated as
                  confidential or proprietary.
                </p>
                <h3>9.2 Exclusions.</h3>
                <p>
                  Confidential Information does not include information that:
                  (i) is or becomes generally known to the public through no
                  fault or breach of this Agreement by the receiving party; (ii)
                  is rightfully known by the receiving party at the time of
                  disclosure without restrictions on use or disclosure; (iii) is
                  independently developed by the receiving party without use of
                  the disclosing party&rsquo;s Confidential Information; or (iv)
                  is rightfully received by the receiving party from a third
                  party, who has the right to provide such information and who
                  provides it without restrictions on use or disclosure. In
                  addition, if a company sets up a Business Account and
                  associates Authorized Users&rsquo; Accounts with the Business
                  Account as contemplated in Section 5.1, Customer Content will
                  be accessible by all associated Account holders via the
                  Container Cruncher Platform and Container Cruncher Services,
                  subject to the Container Cruncher Platform settings created by
                  the Business Account holder. Similarly data may be shared
                  between linked Business Accounts of affiliated businesses.
                </p>
                <h3>9.3. Use and Disclosure Restrictions.</h3>
                <p>
                  Each party will not use any Confidential Information disclosed
                  by the other party except as necessary for the performance or
                  enforcement of this Agreement and will not disclose such
                  Confidential Information to any third party except to those of
                  its employees and subcontractors who have a bona fide need to
                  know such Confidential Information for the performance or
                  enforcement of this Agreement; provided that each such
                  employee and subcontractor is bound by a written agreement
                  that contains use and nondisclosure restrictions consistent
                  with the terms set forth in this Section. Each party will
                  employ all reasonable steps to protect all Confidential
                  Information disclosed by the other party from unauthorized use
                  or disclosure, including, but not limited to, all steps that
                  it takes to protect its own information of like importance.
                  The foregoing obligations will not restrict either party from
                  disclosing such Confidential Information: (i) pursuant to the
                  order or requirement of a court, administrative agency, or
                  other governmental body; provided that the party required to
                  make such a disclosure gives reasonable notice to the other
                  party to contest such order or requirement; (ii) to its legal
                  or financial advisors; (iii) as required under applicable
                  securities regulations; and (iv) subject to customary
                  restrictions, to present or future providers of venture
                  capital and/or potential private investors in or acquirers of
                  such party.
                </p>
                <h3>9.4. Order Details.</h3>
                <p>
                  Please remember that inter-business documents which are used
                  for business purposes such as Quotes, Booking, Orders,
                  Invoices and Receipts and relevant communications will be
                  shared between the applicable Shippers and Sellers as part of
                  the functionality of the Container Cruncher Services.
                </p>
                <h2>10. General Use</h2>
                <h3>10.1. Acceptable Use.</h3>
                <p>
                  You agree not to do any of the following in connection with
                  your use of the Container Crunche Services:
                </p>
                <ol>
                  <li aria-level="2">
                    <p>
                      Post, upload, publish, submit or transmit any Customer
                      Content that: (i) infringes, misappropriates or violates a
                      third party&rsquo;s Intellectual Property Rights, or
                      rights of publicity or privacy; (ii) violates, or
                      encourages any conduct that would violate, any applicable
                      law or regulation or would give rise to civil liability;
                      (iii) is fraudulent, false, misleading or deceptive; (iv)
                      is defamatory, obscene, pornographic, vulgar or offensive;
                      (v) promotes discrimination, bigotry, racism, hatred,
                      harassment or harm against any individual or group; (vi)
                      is violent or threatening or promotes violence or actions
                      that are threatening to any person or entity; or (vii)
                      promotes illegal or harmful activities or substances.
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Use, display, mirror or frame the Container Cruncher
                      Platform or any individual element within&nbsp; Container
                      Cruncher, Container Cruncher name, any Container Cruncher
                      trademark, logo or other proprietary information, or the
                      layout and design of any page or form contained on a page,
                      unless expressly allowed under the terms of this
                      Agreement;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Access, tamper with, or use non-public areas of the
                      Container Cruncher Platform or Container Cruncher computer
                      systems;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Attempt to probe, scan or test the vulnerability of any
                      Container Cruncher system or network or breach any
                      security or authentication measures;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Avoid, bypass, remove, deactivate, impair, descramble or
                      otherwise circumvent any technological measure implemented
                      by Container Cruncher or any of Container Cruncher
                      providers or any other third party (including another
                      user) to protect Container Cruncher or Container Cruncher
                      Content;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Attempt to access or search Container Cruncher, Container
                      Cruncher Content or Customer Content, or download
                      Container Cruncher Content or Customer Content from
                      Container Cruncher through the use of any engine,
                      software, tool, agent, device or mechanism (including
                      spiders, robots, crawlers, data mining tools or the like)
                      other than the software and/or search agents provided by
                      Container Cruncher or other generally available
                      third-party web browsers;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Send any unsolicited or unauthorized advertising,
                      promotional materials, email, junk mail, spam, chain
                      letters or other form of solicitation;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Interfere with, or attempt to interfere with, the access
                      of any user, host or network, including, without
                      limitation, sending a virus, overloading, flooding,
                      spamming, or mail-bombing&nbsp; Container Cruncher;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Collect or store any personally identifiable information
                      from Container Cruncher or regarding other users of the
                      Container Cruncher Services without their express
                      permission;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Impersonate or misrepresent your affiliation with any
                      person or entity;&nbsp;
                    </p>
                  </li>
                  <li aria-level="2">
                    <p>Violate any applicable law or regulation; or</p>
                  </li>
                  <li aria-level="2">
                    <p>
                      Encourage or enable any other individual to do any of the
                      foregoing.
                    </p>
                  </li>
                </ol>
                <h3>10.2 Our Enforcement Rights.</h3>
                <p>
                  Although we&rsquo;re not obligated to monitor access to or use
                  of FreContainer Cruncher ightos or to review or edit any
                  Customer Content, we have the right to do so for the purpose
                  of operating the&nbsp;
                </p>
                <p>
                  Container Cruncher Platform, to ensure compliance with this
                  Agreement, to comply with applicable law or other legal
                  requirements, or to improve the user experience. We reserve
                  the right, but are not obligated, to remove or disable access
                  to any Customer Content, or any Account, at any time and
                  without notice, including, but not limited to, if we, at our
                  sole discretion, consider any Customer Content to be
                  objectionable or in violation of this Agreement. We have the
                  right to investigate violations of this Agreement or conduct
                  that affects Container Cruncher. We may also consult and
                  cooperate with law enforcement authorities to prosecute users
                  who violate the law. We may also use third parties, including
                  collection agents, to help us enforce this Agreement.
                </p>
                <h3>10.3 Compliance.&nbsp;</h3>
                <p>
                  We comply with all applicable laws and expect Container
                  Cruncher Shippers and Sellers to do the same. By entering into
                  this Agreement you undertake to comply with all applicable
                  laws, rules and regulations, including without limitation
                  sanctions, anti-corruption, anti-money laundering and tax laws
                  in your performance of&nbsp; this Agreement.
                </p>
                <h2>11. Digital Millennium Copyright Act (DMCA)</h2>
                <p>
                  Container Cruncher respects copyright law and expects its
                  users to do the same. It is our policy to terminate in
                  appropriate circumstances Account holders and users who
                  repeatedly infringe or are believed to be repeatedly
                  infringing the rights of copyright holders.&nbsp;
                </p>
                <p>12. Audit</p>
                <p>
                  If you&rsquo;re a Seller, during the term of this Agreement
                  and for one year following termination, you will maintain
                  complete and accurate books and records regarding your use of
                  Container Cruncher Services. During this time, we have the
                  right to inspect and audit such books and records for the
                  purpose of confirming your compliance with the terms of this
                  Agreement. Any audit will be conducted by a reputable firm of
                  certified public accountants during regular business hours and
                  in a manner that minimizes interference with your normal
                  business activities. If we discover any payment error in the
                  audit, the party in whose favor the error was made will pay
                  the other the amount of the error. We will pay for the audit
                  unless the audit uncovers payment errors in our favor of five
                  percent (5%) or more of the total amount of Seller Fees paid
                  by you to us under this Agreement. In this case, you must pay
                  for the cost of the audit and all our related expenses.
                </p>
                <h2>13. Termination</h2>
                <ol>
                  <li aria-level="1">
                    <p>
                      Term. This Agreement will commence when you accept its
                      terms, and unless terminated earlier in accordance with
                      the terms of this Agreement, will remain in force and
                      effect for as long as we are providing Container Cruncher
                      Services to you.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Termination of Accounts. We may terminate your access to
                      and use of the Container Cruncher Platform, including your
                      Business Account or Account, at our sole discretion, upon
                      90 days notice. However, given the importance of
                      maintaining the integrity of our Platform for all users,
                      if we have reason to suspect abuse or a substantial breach
                      of this agreement, we may terminate your Account at any
                      time at our sole discretion and will only provide notice
                      if we feel it is reasonable and feasible to do so. You may
                      cancel your Account at any time by sending an email to us
                      at hello@containercruncher.com or by using the
                      cancellation feature on the Site or in the Container
                      Cruncher App.&nbsp;
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Effect of Termination. Upon the expiration or termination
                      of this Agreement, you will no longer be able to access
                      and use Container Cruncher or Container Cruncher Services
                      and each party will promptly return to the other party or
                      destroy all Confidential Information of the other party in
                      its possession or control.&nbsp;
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Shipments that are Still in Process. If you are a Seller,
                      in the event that upon the expiration or termination of
                      this Agreement, a Shipper has a copy of a valid unexpired
                      Quote from you, and wishes to book a Shipment, or the
                      performance of Freight Services under a Booking made
                      through the Container Cruncher Platform is ongoing, the
                      expiration or termination of this Agreement will not
                      affect the terms of such Quote or Booking. Sellers are
                      obligated and agree to carry out the Freight Services
                      specified in such Shipments to completion in accordance
                      with the terms of the agreement between the Seller and
                      Shipper for the Shipment in question and Shippers are
                      obligated and agree to fulfill their obligations under
                      such Shipments, including payment obligations to us
                      pursuant to Section 7.5(a).
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Survival. The rights and obligations of the parties
                      contained in the following Sections will survive
                      termination of this Agreement or any Statement of Work:
                      5.2 (Intellectual Property); 5.3 (Data Maintenance and
                      Backup Procedures); 6.3(a) (SaaS Subscriber Customer
                      Portal); 7.4 (Disputes with Sellers); 8.3 (Disputes with
                      Shippers); 8.5 (Non-Circumvention); 9.3(a) (Data Services
                      Disclaimer); 10 (Mutual Non-Disclosure Agreement); 11.2
                      (Our Enforcement Rights); 13 (Audit); 14(c) (Effect of
                      Termination); 14(d) (Shipments that are Still in Process);
                      14(e) (Survival); 15 (Warranties); 16 (Indemnity) and 17
                      (Limitation of Liability).
                    </p>
                  </li>
                </ol>
                <h2>14. Disclaimer of Warranties</h2>
                <p>
                  THE CONTAINER CRUNCHER PLATFORM AND CONTAINER CRUNCHER
                  SERVICES ARE PROVIDED &ldquo;AS IS,&rdquo; WITHOUT WARRANTY OF
                  ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY
                  DISCLAIM ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE,
                  QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES
                  ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO
                  WARRANTY THAT USING CONTAINER CRUNCHER OR CONTAINER
                  CRUNCHER&nbsp; SERVICES WILL RESULT IN HIGHER NUMBERS OF
                  CUSTOMERS OR INCREASED REVENUE FOR YOU OR YOUR BUSINESS. WE
                  MAKE NO WARRANTY THAT THE CONTAINER CRUNCHER&nbsp; PLATFORM OR
                  CONTAINER CRUNCHER&nbsp; SERVICES WILL MEET YOUR REQUIREMENTS
                  OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE
                  BASIS.
                </p>
                <h2>15. Indemnity</h2>
                <p>
                  You will indemnify, defend and hold Container Cruncher and its
                  officers, directors, employee and agents harmless, from and
                  against any claims, disputes, demands, liabilities, damages,
                  losses, and costs and expenses, including, without limitation,
                  reasonable legal and accounting fees arising out of or in any
                  way connected with (i) your access to or use of Container
                  Cruncher or Container Cruncher Services, (ii) the use of any
                  of your Authorized Users or SaaS Subscriber Customers of
                  Container Cruncher or Container Cruncher Services (if
                  applicable), (iii) your Customer Content, or (iv) your
                  violation of this Agreement.
                </p>
                <h2>16. Limitation of Liability</h2>
                <p>
                  NEITHER CONTAINER CRUNCHER NOR ANY OTHER PARTY INVOLVED IN
                  CREATING, PRODUCING, OR DELIVERING THE CONTAINER CRUNCHER
                  SERVICES OR CONTAINER CRUNCHER PLATFORM WILL BE LIABLE FOR ANY
                  INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
                  INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE
                  INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF
                  SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THIS
                  AGREEMENT OR FROM THE USE OF OR INABILITY TO USE THE CONTAINER
                  CRUNCHER SERVICES OR CONTAINER CRUNCHER, CONTAINER CRUNCHER
                  BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
                  PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR
                  NOT CONTAINER CRUNCHER HAS BEEN INFORMED OF THE POSSIBILITY OF
                  SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS
                  FOUND TO HAVE FAILED IN ITS ESSENTIAL PURPOSE. SOME
                  JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE
                  ABOVE LIMITATION WILL APPLY TO YOU TO THE DEGREE ALLOWED BY
                  LAW.
                </p>
                <p>
                  IN NO EVENT WILL CONTAINER CRUNCHER TOTAL LIABILITY ARISING
                  OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR FROM THE USE OF
                  OR INABILITY TO USE THE CONTAINER CRUNCHER SERVICES OR
                  CONTAINER CRUNCHER PLATFORM EXCEED THE AMOUNTS YOU HAVE PAID
                  TO CONTAINER CRUNCHER FOR USE OF THE CONTAINER CRUNCHER
                  SERVICES IN THE 12 MONTH PERIOD PRIOR TO THE EVENT GIVING RISE
                  TO LIABILITY, OR $10,000 IF YOU HAVE NOT HAD ANY PAYMENT
                  OBLIGATIONS TO CONTAINER CRUNCHER
                </p>
                <p>, AS APPLICABLE.</p>
                <p>
                  THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
                  FUNDAMENTAL ELEMENTS OF THE BASIS OF THE COMMERCIAL AGREEMENT
                  BETWEEN Container Cruncher AND YOU.
                </p>
                <h2>17. General</h2>
                <ol>
                  <li aria-level="1">
                    <p>
                      Assignment. You may not assign or transfer this Agreement,
                      by operation of law or otherwise, without our prior
                      written consent. Any attempt by you to assign or transfer
                      this Agreement, without such consent, will be null. We may
                      assign or transfer this Agreement without restriction or
                      your consent to an affiliated company or to a successor or
                      acquirer, as the case may be, in connection with a merger
                      or acquisition, or the sale of all or substantially all of
                      our assets. Subject to the foregoing, this Agreement will
                      bind and benefit the parties and their respective
                      successors and assigns.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      No Election of Remedies. Except as expressly set forth in
                      this Agreement, the exercise by either party of any of its
                      remedies under this Agreement will not be deemed an
                      election of remedies and will be without prejudice to its
                      other remedies under this Agreement or available at law or
                      in equity or otherwise.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Governing Law. This Agreement will be governed by and
                      construed in accordance with the laws of Delaware,
                      excluding its body of law controlling conflict of laws.
                      Any disputes will be resolved exclusively by binding
                      arbitration in Delaware.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Severability. If any provision of this Agreement is held
                      invalid or unenforceable by a court of competent
                      jurisdiction, the remaining provisions of the Agreement
                      will remain in full force and effect, and the provision
                      affected will be construed so as to be enforceable to the
                      maximum extent permissible by law.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Notices. Any notices or other communications provided by
                      us under this Agreement, including those regarding
                      modifications to this Agreement, will be given: (i) via
                      email; or (ii) by posting to the Container Cruncher
                      Platform. For notices made by e-mail, the date of receipt
                      will be deemed the date on which such notice is
                      transmitted. Notices to Container Cruncher should be sent
                      to hello@containercruncher.com.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Waiver. Our failure to enforce any right or provision of
                      this Agreement will not be considered a waiver of such
                      right or provision. The waiver of any such right or
                      provision will be effective only if in writing and signed
                      by our duly authorized representative.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Entire Agreement. This Agreement, together with all
                      documents which incorporate this Agreement (including any
                      customer specific purchase orders or Statements of Work)
                      and which are incorporated by reference into this
                      Agreement constitutes the complete and exclusive agreement
                      of the parties with respect to its subject matter and
                      supersedes all prior understandings and agreements,
                      whether written or oral, with respect to its subject
                      matter.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Language. This Agreement may be translated from time to
                      time for your convenience, but the English language
                      version of the Agreement is the binding version.
                    </p>
                  </li>
                  <li aria-level="1">
                    <p>
                      Mandatory Law. Container Cruncher policies shall only take
                      effect to the extent that they are not contrary to the
                      mandatory provisions of international conventions or
                      national law applicable to the Freight Forwarding
                      Services.
                    </p>
                  </li>
                </ol>
                {/* </strong> */}
              </div>
            </div>
          </Container>
          <LandingPageFooter />
        </div>
      </LandingPageStyle>
    </>
  );
};
export default ServiceAgreement;