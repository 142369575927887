import { Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
export const FountainButtons = styled("span")(({ theme }) => ({
  "& .MuiButton-root": {
    backgroundColor: theme.palette.primary.fountainblue,
    boxShadow: "none",
    color: "#fff",
    fontWeight: 600,
    borderRadius: "35px",
    padding: "11.76px 54px",
    "@media (max-width:992px)": {
      width: "100%",
    },
  },
  "& .MuiButton-root:hover": {
    backgroundColor: theme.palette.primary.fountainblue,
  },
}));

const FountainBlueButton = ({ text, ...rest }) => {
  return (
    <FountainButtons>
      <Button {...rest}>{text}</Button>
    </FountainButtons>
  );
};
export default FountainBlueButton;
