import React, { useEffect, useState } from "react";

import { InputAdornment, OutlinedInput } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { setUpdateContainerByField } from "../../reducers/modalSlice";
import { SPECIAL_CHAR_REGEX } from "../../utils/defaultValues";
import { formatValue } from "../../utils/calculation";
export const InputStyled = styled("div")(() => ({
  "& .MuiInputBase-root": {
    border: "1px solid #C3CAD5",
    width: "100%",
    borderRadius: "45px",
    color: "#666666",
    fontSize: "12px",
    fontWeight: 500,
  },
  "& fieldset": { display: "none" },
  input: {
    "&::placeholder": {
      color: "#666666",
      opacity: 1,
    },
  },
  "& .MuiInputBase-root .MuiTypography-root": {
    color: "#8FADC8",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return debouncedValue;
};

const InputTextField = ({
  kg,
  placeholder,
  height,
  value,
  inputType,
  id,
  field,
  handlerChange,
}) => {
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState(value);
  const [error, setError] = useState(false);

  const debouncedValue = useDebounce(initialValue, 1000);

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  useEffect(() => {
    !error &&
      dispatch(
        setUpdateContainerByField({
          id,
          field,
          value: debouncedValue,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    if (
      // initialValue > 2000 ||
      initialValue < 0 ||
      !SPECIAL_CHAR_REGEX.test(initialValue)
    ) {
      setError(true);
    } else {
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const updateValue = (e) => {
    handlerChange && handlerChange(e);
    setInitialValue(e.target.value);
  };
  const onFocusOut = () => {
    !error &&
      dispatch(
        setUpdateContainerByField({
          id,
          field,
          value: debouncedValue,
        })
      );
  };
  return (
    <>
      <InputStyled>
        <OutlinedInput
          sx={{
            height: height,
            border: error
              ? "1px solid red !important"
              : "1px solid #8FADC8 !important",
          }}
          size="small"
          placeholder={placeholder}
          id="outlined-adornment-weight"
          endAdornment={<InputAdornment position="end">{kg}</InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          value={formatValue(initialValue) || ""}
          inputProps={{
            "aria-label": "weight",
          }}
          onChange={(event) => updateValue(event)}
          onBlur={onFocusOut}
          type={inputType || "text"}
          readOnly={false}
        />
      </InputStyled>
      {error ? (
        <span
          style={{
            color: "red",
            fontSize: "8px",
            position: "absolute",
          }}
        >
          Invalid value
        </span>
      ) : null}
    </>
  );
};
export default InputTextField;
