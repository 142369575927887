import { Button, IconButton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { SVG } from "../../../assert/svg";
import { BlueButtons } from "../../button";
import { read, utils, writeFile } from "xlsx";
import { v4 as uuidv4 } from "uuid";
import ExportStyled from "./style";
import {
  setCalculate,
  setImportedExcelData,
  setRemainingCargo,
} from "../../../reducers/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { getRandomColor } from "../../../utils/calculation";
import { toast } from "react-toastify";

const ExportModalContent = ({ handleClose }) => {
  const dispatch = useDispatch();

  const { measurementUnit } = useSelector((state) => state.modalHandler);

  const [myFiles, setMyFiles] = useState([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles(acceptedFiles);
    },
    [myFiles]
  );

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    setImportedData([]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: 1,
    accept: {
      "file/xls": [".xls"],
      "file/xlsx": [".xlsx"],
    },
  });
  const acceptedFileItems = myFiles.map((file, i) => (
    <li key={i}>
      <div className="text-tracate">{file.path}</div>
      <IconButton
        onClick={removeFile(file)}
        disableFocusRipple
        sx={{ color: "#274593" }}
      >
        <SVG.CloseIcon />
      </IconButton>
    </li>
  ));
  const [importedData, setImportedData] = useState(null);
  const handleExcelImport = () => {
    if (myFiles.length) {
      const file = myFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("rows", rows);
          const objectCount = Object.keys(rows[0]).length;
          if (objectCount === 7) {
            const newRow = rows.map((item) => ({
              // ...item,
              cargoType: item["Cargo type"],
              height: item["Height (mm)"],
              length: item["Length (mm)"],
              name: item["Name"],
              quantity: item["Q-ty"],
              weight: item["Weight (kg)"],
              width: item["Width / Diameter for barrels (mm)"],
              id: uuidv4(),
              color: getRandomColor(),
              measurementUnit: measurementUnit,
              weightUnit: measurementUnit === "mm" ? "kg" : "lbs",
            }));
            setImportedData(newRow);
            dispatch(setImportedExcelData(newRow));
            dispatch(setRemainingCargo(newRow));
            // dispatch(setCalculate(true))
            toast.success("Excel imported successfully!");
          } else {
            toast.error("Invalid Excel");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <ExportStyled>
        <div className="export-content">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            className="border-bottom"
          >
            <h2>Import</h2>
            <IconButton disableRipple={true} onClick={handleClose}>
              <SVG.CloseIcon />
            </IconButton>
          </Stack>

          <Box className="drop-content">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className="upload-button-box">
                <SVG.UploadIcon />
                <span>Upload files</span>
                <Button variant="contained" component="label">
                  Upload
                </Button>
              </div>
            </div>
            <ul className="listitem">{acceptedFileItems}</ul>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2.5 }}
              sx={{ mt: 1.75 }}
              justifyContent="end"
            >
              <Button component="span" className="export-btn">
                <a href={"/excel/import.xlsx"} download>
                  {" "}
                  <SVG.DwonloadIcon /> Get Excel Import Template XLS
                </a>
              </Button>
              <BlueButtons
                text="Import"
                onClick={() => {
                  handleExcelImport();
                  handleClose();
                }}
              />
            </Stack>
          </Box>
        </div>
      </ExportStyled>
    </>
  );
};
export default ExportModalContent;
