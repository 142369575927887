import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { SVG } from "../../assert/svg";
import CardStyled from "../container-type/style";

import ExportStyled from "../container-type/export-content/style";
import { useDispatch, useSelector } from "react-redux";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { setPackingParameters } from "../../reducers/modalSlice";

const PackingParameters = () => {
  const [isPrameters, setIsPrameters] = useState([]);
  const dispatch = useDispatch();
  const packingParameters = useSelector(
    (state) => state.modalHandler.packingParameters
  );
  const handleChecked = (id, cargoType, parameterType) => {
    let isExist = isPrameters.some(
      (item) => item.id === id && item.parameterType === parameterType
    );

    if (isExist) {
      let filteredData = isPrameters.filter(
        (el) => el.id !== id || el.parameterType !== parameterType
      );
      setIsPrameters(filteredData);

      let packingParametersData = [...packingParameters];
      let packingParametersFilter = packingParametersData.filter(
        (el) => el.id !== id || el.parameterType !== parameterType
      );
      dispatch(setPackingParameters(packingParametersFilter));
    } else {
      // setIsPrameters([...isPrameters, id]);
      setIsPrameters([
        ...isPrameters,
        {
          id: id,
          parameterType: parameterType,
          cargoType,
        },
      ]);

      dispatch(
        setPackingParameters([
          ...isPrameters,
          {
            id: id,
            parameterType: parameterType,
            cargoType,
          },
        ])
      );
    }
  };

  const checkedList = [
    {
      id: "1",
      icon: <SVG.HeightIcon />,
      title: "Height",
      value: "height",
    },
    {
      id: "2",
      icon: <SVG.LengthIcon />,
      title: "Length",
      value: "lenght",
    },
    {
      id: "3",
      icon: <SVG.WidthIcon />,
      title: "Width",
      value: "width",
    },
  ];

  const iconList = {
    box: [
      {
        id: "1",
        icon: <SVG.HeightIcon />,
        title: "Height",
        value: "height",
      },
      {
        id: "2",
        icon: <SVG.LengthIcon />,
        title: "Length",
        value: "lenght",
      },
      {
        id: "3",
        icon: <SVG.WidthIcon />,
        title: "Width",
        value: "width",
      },
    ],
    barrel: [
      {
        id: "1",
        icon: <SVG.CylinderIcon />,
        title: "Height",
        value: "height",
      },
    ],
    sack: [
      {
        id: "1",
        icon: <SVG.SackHeightIcon />,
        title: "Height",
        value: "height",
      },
      {
        id: "2",
        icon: <SVG.SackLengthIcon />,
        title: "Length",
        value: "lenght",
      },
      {
        id: "3",
        icon: <SVG.SackWidthIcon />,
        title: "Width",
        value: "width",
      },
    ],
    "big-bag": [
      {
        id: "1",
        icon: <SVG.HeightIcon />,
        title: "Height",
        value: "height",
      },
      {
        id: "2",
        icon: <SVG.LengthIcon />,
        title: "Length",
        value: "lenght",
      },
      {
        id: "3",
        icon: <SVG.WidthIcon />,
        title: "Width",
        value: "width",
      },
    ],
  };
  // const parametersList = [
  //   {
  //     label: "Cargo",
  //     descripition: "Cargo 1",
  //   },
  //   {
  //     label: "Qty",
  //     descripition: "5 Units",
  //   },
  //   { label: "Weight", descripition: "50 kg" },
  //   { label: "Length", descripition: "300 mm" },
  //   { label: "Width", descripition: "400 mm" },
  //   { label: "Height", descripition: "200 mm" },
  // ];

  const cargoData = useSelector(
    (state) => state.modalHandler.ImportedExcelData
  );

  useEffect(() => {
    let tempArr = [];
    cargoData.forEach((item) => {
      checkedList.forEach((el) => {
        tempArr.push({
          id: item.id,
          parameterType: el.title,
          cargoType: item.cargoType,
        });
      });
    });
    setIsPrameters(tempArr);
    dispatch(setPackingParameters(tempArr));
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoData]);

  return (
    <CardStyled>
      <Card>
        <CardContent>
          <Stack
            spacing={1.875}
            direction="row"
            alignItems="center"
            sx={{ mb: 3.75 }}
          >
            <IconButton disableRipple>
              <SVG.BackArrowIcon />
            </IconButton>

            <Typography variant="h1">Step 5: Packing Parameters</Typography>
          </Stack>
          {cargoData.map((cargo, index) => (
            <Grid key={index} container spacing={2}>
              <Grid item lg={4} xs={12}>
                <Stack
                  direction="row"
                  spacing={1}
                  className="paramenter-list"
                  alignItems="center"
                >
                  <label>Cargo </label>
                  <span>:</span>
                  <big>{cargo.name}</big>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className="paramenter-list"
                  alignItems="center"
                >
                  <label>Qty </label>
                  <span>:</span>
                  <big>{cargo.quantity} units</big>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className="paramenter-list"
                  alignItems="center"
                >
                  <label>Weight </label>
                  <span>:</span>
                  <big>
                    {Math.round(cargo.weight)} {cargo.weightUnit}
                  </big>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className="paramenter-list"
                  alignItems="center"
                >
                  <label>Length </label>
                  <span>:</span>
                  <big>
                    {Math.round(cargo.length)} {cargo.measurementUnit}
                  </big>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className="paramenter-list"
                  alignItems="center"
                >
                  <label>Width </label>
                  <span>:</span>
                  <big>
                    {Math.round(cargo.width)} {cargo.measurementUnit}
                  </big>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className="paramenter-list"
                  alignItems="center"
                >
                  <label>Height </label>
                  <span>:</span>
                  <big>
                    {Math.round(cargo.height)} {cargo.measurementUnit}
                  </big>
                </Stack>
              </Grid>
              <Grid item lg={8} xs={12}>
                <ExportStyled>
                  <Grid container spacing={4.5}>
                    {iconList[cargo.cargoType].map((items, index) => (
                      <Grid item lg={4} xs={12} key={index}>
                        <Box
                          component="label"
                          className={`card parameters-check ${
                            isPrameters.some(
                              (item) =>
                                item.id === cargo.id &&
                                item.parameterType === items.title
                            )
                              ? "is-checked"
                              : ""
                          }`}
                        >
                          {cargo.cargoType !== "barrel" && (
                            <div className="checkbox">
                              <Checkbox
                                checked={isPrameters.some(
                                  (item) =>
                                    item.id === cargo.id &&
                                    item.parameterType === items.title
                                )}
                                // onClick={() =>
                                //   handleChecked(
                                //     cargo.id,
                                //     cargo.cargoType,
                                //     items.title
                                //   )
                                // }
                                checkedIcon={<SVG.CheckedIcon />}
                                icon={<SVG.UncheckedIcon />}
                                disableRipple={true}
                              />
                            </div>
                          )}
                          <span style={{ color: cargo.color || "#9FB1C1" }}>
                            {items.icon}
                          </span>
                          <h6>{items.title}</h6>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </ExportStyled>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Divider sx={{ mb: 3 }} />
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
    </CardStyled>
  );
};
export default PackingParameters;
