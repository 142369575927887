import { Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
export const OutLineStyled = styled("span")(({ theme }) => ({
  "& .MuiButton-root": {
    boxShadow: "none",
    color: "#707D88",
    fontWeight: 400,
    borderRadius: "62px",
    padding: "5px 12px 5px 7px",
    borderColor: "#009EE3",
    fontSize: "14px",
  },
  "& .MuiButton-root:hover": {
    background: "#fff",
    borderColor: "#009EE3",
  },
  "& .MuiButton-root svg": { color: "#009EE3", marginRight: "7px" },
  "& .topspace": { position: "relative", top: "2px" },
}));
const OutLineButton = ({ text, icon, ...rest }) => {
  return (
    <OutLineStyled>
      <Button {...rest} variant="outlined">
        {icon} <span className="topspace">{text}</span>
      </Button>
    </OutLineStyled>
  );
};
export default OutLineButton;
