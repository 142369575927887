import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { SVG } from "../../assert/svg";
import { Link, useNavigate } from "react-router-dom";
import FooterStyle from "./footerStyle";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setActiveHeader, setCalculate } from "../../reducers/modalSlice";
import { Container } from "react-bootstrap";

function LandingPageFooter(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const quickLink = [
    {
      id: "",
      menu: "Home",
      url: "/",
      section: "home",
    },
    {
      id: "",
      url: "/",
      menu: "What we Do",
      section: "fullyManage",
    },
    {
      id: "",
      url: "/",
      menu: "FAQ's",
      section: "faq",
    },
    {
      id: "",
      menu: "Privacy Policy",
      url: "/privacy-policy",
    },
    {
      id: "",
      menu: "Service Agreement",
      url: "/service-agreement",
    },
  ];

  const contactUs = [
    {
      id: "",
      menu: "Get Quote",
    },
    {
      id: "",
      menu: "Get Started",
    },
    {
      id: "",
      menu: "hello@containercruncher.com",
    },
  ];

  const socialLink = [
    {
      id: "",
      icon: SVG.facebookIcon,
    },
    {
      id: "",
      icon: SVG.twitterIcon,
    },
    {
      id: "",
      icon: SVG.linkedinIcon,
    },
  ];
  const handleMoveToSection = (id) => {
    dispatch(setActiveHeader(id));
    // setSelectedSection(id);
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
      // setTimeout(() => {
      //   setSelectedSection(id);
      // }, 100);
    }
  };
  return (
    <FooterStyle>
      <>
        <div className="footer_list">
          <Container>
            <Grid container spacing={2}>
              <Grid md={6} lg={4} sm={12} xs={12} item>
                <div className="left_content_footer">
                  <SVG.Logo />
                  <Typography varient="p" className="mt-3">
                    Seamless Imports, Simplified Logistics: Unlocking <br></br>{" "}
                    Smooth Shipping for Ecommerce Sellers!
                  </Typography>
                </div>
              </Grid>
              {/* <Grid md={1}></Grid> */}
              <Grid md={3} sm={3} xs={12} item>
                <Typography>
                  <h5>QUICK LINKS</h5>
                </Typography>
                <List
                  className="footer_link"
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                >
                  {quickLink.map((item, index) => {
                    return (
                      <ListItem>
                        <Link
                          to={item.url}
                          onClick={() => {
                            dispatch(setActiveHeader(item.section));
                            setTimeout(() => {
                              handleMoveToSection(item.section);
                            }, 100);
                          }}
                        >
                          {item.menu}
                        </Link>{" "}
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>

              <Grid md={6} sm={5} xs={12} lg={3} item>
                <h5>CONTACT US</h5>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                  className="contact_footer footer_link"
                >
                  {/* {contactUs.map((item, index) => {
                    return ( */}
                  <ListItem>
                    <Link
                      to="/"
                      onClick={(e) => {
                        // navigate("/rov");
                        // dispatch(setActiveHeader("quote"));

                        setTimeout(() => {
                          handleMoveToSection("quote");
                        }, 100);
                      }}
                    >
                      Get Quote
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      to="/load-calculator"
                      onClick={(e) => {
                        dispatch(setCalculate(true));
                        navigate("/load-calculator");
                      }}
                    >
                      Get Started
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      to="#"
                      onClick={() => props.handleMoveToSection("quote")}
                    >
                      <SVG.Envelop className="me-1 envelop" />
                      hello@containercruncher.com
                    </Link>
                  </ListItem>
                  {/* );
                  })} */}
                </List>
              </Grid>

              <Grid md={6} lg={2} sm={4} xs={12} item className="social_footer">
                <div className="text-end">
                  <h5 className="text-start ms-5">GET SOCIAL</h5>
                  <List
                    className="social_login"
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                    }}
                  >
                    {socialLink.map((item, index) => {
                      return (
                        <ListItem>
                          <Link to="#">{<item.icon />}</Link>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </Grid>
            </Grid>
            <Grid md={12}>
              <div className="bottom_footer text-center">
                <Typography varient="p">
                  © Container Cruncher, Copyright 2023
                </Typography>
              </div>
            </Grid>
          </Container>
        </div>
      </>
    </FooterStyle>
  );
}

export default LandingPageFooter;
