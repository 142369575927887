import { Button, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

import CargoPackaging from "../../components/cargo-packaging";
import ContainerSelection from "../../components/container-selection";
import ContainerType from "../../components/container-type";

import LoadCalculator from "../../components/load-calculator";
import LodingType from "../../components/loading-type";
import PackingParameters from "../../components/packing-parameters";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";

export const containerTypeArr = (value) => {
  switch (value) {
    case "1":
      return "20 dv";
    case "2":
      return "40 dv";
    // case "3":
    //   return "40 hq";
    // case "4":
    //   return "45 hq";
    default:
      return "Truck";
  }
};
const StackStyled = styled("div")(() => ({
  "& .MuiButton-root": {
    borderRadius: "35px",
    background: "#0158FF",
    fontSize: "17px",
    color: "#fff",
    fontWeight: "500",
    boxShadow: "none",
    padding: "9.13px",
  },
}));

const Home = () => {
  const [selectedContainer, setSelectedContainer] = useState("container");

  const ImportedExcelData = useSelector(
    (state) => state.modalHandler.ImportedExcelData
  );

  const downloadExcel = (data) => {
    const removeId = data.map((item) => {
      let copy = { ...item };
      delete copy.id;
      // delete copy.measurementUnit;
      // delete copy.weightUnit;
      return copy;
    });
    const worksheet = XLSX.utils.json_to_sheet(removeId);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Load Calculator</title>
        <meta
          name="description"
          content="Container Cruncher is a container tracking and management system that helps you track your containers and manage your container inventory."
        />
      </Helmet>
      <Container className="load_page">
        <Grid container spacing={2}>
          <Grid item lg={9} xs={12}>
            <Stack direction="column" spacing={3.75}>
              <div>
                <ContainerType
                  selectedContainer={selectedContainer}
                  handleSelectedContainer={(vl) => setSelectedContainer(vl)}
                />
              </div>
              <div>
                <CargoPackaging
                  selectedContainer={selectedContainer}
                  handleSelectedContainer={(vl) => setSelectedContainer(vl)}
                />
              </div>
              <div>
                <LodingType
                  selectedContainer={selectedContainer}
                  handleSelectedContainer={(vl) => setSelectedContainer(vl)}
                />
              </div>
              <div>
                <ContainerSelection
                  selectedContainer={selectedContainer}
                  handleSelectedContainer={(vl) => setSelectedContainer(vl)}
                />
              </div>
              <div>
                <PackingParameters />
              </div>
            </Stack>
          </Grid>
          <Grid item lg={3} xs={12}>
            <Stack direction="column" spacing={3}>
              <LoadCalculator />

              {Object.keys(ImportedExcelData).length > 0 && (
                <StackStyled>
                  <Stack direction="row" spacing={1.75}>
                    <Button variant="contained" fullWidth onClick={() => {}}>
                      Export .dat
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => downloadExcel(ImportedExcelData)}
                    >
                      Export .xls
                    </Button>
                  </Stack>
                </StackStyled>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Home;
