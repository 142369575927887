import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasOnlyDuplicates } from "../../utils/calculation";
import {
  setCalculate,
  setContainerSelectionType,
  setTruckSelectionType,
} from "../../reducers/modalSlice";
import { v4 as uuidv4 } from "uuid";
import { containerTypeArr } from "../../pages/home";
import { defaultContainerDataAsUnit } from "../../utils/defaultValues";
import { toast } from "react-toastify";
import { baseURL } from "../../api/api";
import axios from "axios";
import { Suspense } from "react";
import Loader from "../loader";

const LoadCalculator = React.lazy(() => import("./LoadCalculator.jsx"));

const MainLoadCalculator = () => {
  const dispatch = useDispatch();

  const {
    ContainerTypeData,
    containerSelectionType,
    truckSelectionType,
    loadingType,
    ImportedExcelData,
    measurementUnit,
    calculate,
  } = useSelector((state) => state.modalHandler);

  const isTruck = ContainerTypeData === "Truck";
  const isPallets = loadingType?.loadingType === "pallet";
  const palletInfo = loadingType?.pallet_info;

  const [assignedContainers, setAssignedContainers] = useState([]);

  const manualData = isTruck
    ? truckSelectionType.container
    : containerSelectionType.container;

  const type = isTruck
    ? truckSelectionType["type"]
    : containerSelectionType["type"];

  const shiftCargoByVolume = async () => {
    console.log("\x1b[33;1m%s\x1b[1m", "re render with calculate");

    let givenContainers = isPallets
      ? containerSelectionType.container.map((container) => ({
          ...container,
          height: container.height - palletInfo.height,
        }))
      : containerSelectionType.container;

    let givenTrucks = isPallets
      ? truckSelectionType.container.map((truck) => ({
          ...truck,
          height: truck.height - palletInfo.height,
        }))
      : truckSelectionType.container;

    console.log({
      cargoData: [...ImportedExcelData].map((item) => ({ ...item })),
      containerData: accumulatedContainers(
        isTruck ? givenTrucks : givenContainers
      ),
      automatic: type === "automatic",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseURL}/calculate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        cargoData: [...ImportedExcelData].map((item) => ({ ...item })),
        containerData: accumulatedContainers(
          isTruck ? givenTrucks : givenContainers
        ),
        automatic: type === "automatic",
      }),
    };

    const containersResult = await axios.request(config);

    let containers = containersResult.data;

    if (type !== "automatic" && manualData?.length > containers.length) {
      const mData = [...manualData].map((c) => ({ ...c }));
      for (let i = 0; i < containers.length; i++) {
        const container = containers[i];
        const arr = mData.findIndex((c) => c.type === container.type);
        if (arr !== -1) {
          mData.splice(arr, 1);
        }
      }
      containers = [
        ...containers,
        ...mData.map((c) => ({
          ...c,
          items: [],
        })),
      ];
    }
    console.log({ containers });
    setAssignedContainers((prev) => {
      if (prev.length === 0) {
        return prev;
      } else {
        if (prev.length < containers.length) {
          toast.info("New containers added!");
          return prev;
        }
      }
    });
    setAssignedContainers(containers);
    if (assignedContainers.length > 0) {
      if (!isTruck) {
        dispatch(
          setContainerSelectionType({
            type: containerSelectionType.type,
            container: containers.map((container) => {
              return {
                type: container.type,
                id: uuidv4(),
                ...defaultContainerDataAsUnit("Container", measurementUnit)
                  .container[container.type - 1],
              };
            }),
          })
        );
      } else {
        dispatch(
          setTruckSelectionType({
            type: "automatic",
            container: containers.map((container) => {
              return {
                type: container.type,
                id: uuidv4(),
                ...defaultContainerDataAsUnit("Truck", measurementUnit)
                  .container[0],
              };
            }),
          })
        );
      }
    }
    dispatch(setCalculate(false));
  };

  const accumulatedContainers = (containers) => {
    const result = containers.reduce((acc, cargo) => {
      const existingCargo = acc.find((c) => c.type === cargo.type);
      if (existingCargo) {
        acc[acc.indexOf(existingCargo)] = {
          ...existingCargo,
          qty: existingCargo.qty + 1,
        };
      } else {
        acc.push({ ...cargo, qty: 1 });
      }
      return acc;
    }, []);
    if (containers[0].type === "Truck") {
      return [...result, { ...result[0], qty: 0 }];
    } else {
      if (hasOnlyDuplicates(containers)) {
        const containerType = containers[0].type === "1" ? "2" : "1";
        return [
          ...result,
          {
            type: containerType,
            id: uuidv4(),
            ...defaultContainerDataAsUnit("Container", measurementUnit)
              .container[containerType - 1],
            qty: 0,
          },
        ];
      } else return result;
    }
  };

  useEffect(() => {
    if (calculate) {
      shiftCargoByVolume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculate]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Loader show={calculate} />
      {assignedContainers.map((container, i) => {
        return (
          <LoadCalculator
            key={i}
            containerDetails={container}
            measurementUnit={measurementUnit}
            loadingType={loadingType}
            title={containerTypeArr(container.type)}
            distributedData={container?.items || []}
          />
          // <div />
        );
      })}
    </Suspense>
  );
};

export default MainLoadCalculator;
