import { styled } from "@mui/system";
import CalculatorBg from "../../assert/images/calculator-bg.png";
import ShipBg from "../../assert/images/ship-bg.png";
import ManageBg from "../../assert/images/manage-group.png";
import upRight from "../../assert/images/upLineArray.png";
const HeaderMainStyle = styled("div")(({ theme }) => ({
  "& .MuiAppBar-root": {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 20px 3px rgba(0, 0, 0, 0.05)",
    padding: "14px 0",
    fontFamily: "'Inter', sans-serif",
  },
  "& .main_header_new": {
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "none",
    padding: "0",
  },
  "& .border_btn_head:hover": {
    border: "2px solid #6D5BDE",
  },
  "& .header-menu": {
    color: "#9098AF",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "none",
    padding: "0px 5px",
    marginRight: "27px",
    fontFamily: "'Inter', sans-serif",
    position: "relative",
  },
  "& .active": {
    color: "#6D5BDE",
  },
  "& .first_section": {
    padding: "0 20px",
    fontFamily: "'Inter', sans-serif",
  },
  "& .MuiList-root": {
    padding: "0px 16px",
  },
  "& .landing_page_content": {
    marginTop: "150px",
    background:
      "linear-gradient(180deg, #F4F5FF -24.83%, rgba(255, 255, 255, 0) 100%)",
  },
  "& .left_content_data h3": {
    fontSize: "36px",
    fontWeight: "600",
    marginBottom: "10px",
    fontFamily: "'Inter', sans-serif",
  },
  "& .left_content_data span": {
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "'Inter', sans-serif",
  },
  "& .left_content_data h6": {
    margin: "10px 0",
    fontSize: "20px",
    fontWeight: "400",
  },
  "& .logistic_partner_section": { padding: "50px 15px" },
  "& .logistic_partner_section h6": {
    padding: "20px 0 30px",
    fontWeight: "700",
  },
  "& .perfect_content_div": {
    padding: "40px 15px 50px",
  },
  "& .perfect_content_div .heading_perfect": {
    fontSize: "30px",
    fontWeight: "600",
    marginBottom: "15px",
    fontFamily: "'Inter', sans-serif",
  },
  "& .perfect_content_div p": {
    marginBottom: "30px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    color: "#545C71",
  },
  "& .perfect_card": {
    backgroundColor: "#fff",
    border: "1px solid #D1D2E3",
    margin: "30px 20px 10px",
    textAlign: "center",
    padding: "20px 20px 0",
    minHeight: "353px",
    borderRadius: "8px",
    fontFamily: "'Inter', sans-serif",
  },
  "& .perfect_card h6": {
    fontSize: "20px",
    margin: "15px 0 5px",
    fontWeight: "700",
    fontFamily: "'Inter', sans-serif",
  },
  "& .perfect_card p": {
    color: "#545C71",
    fontSize: "16px",
    fontWeight: "500",
  },
  "& .calculator_div": {
    // minHeight: "500px",
    backgroundImage: `url(${CalculatorBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "60px 0",
    textAlign: "center",
  },
  "& .calculator_div h3": {
    color: "#fff",
    fontSize: "30px",
  },
  "& .calculator_div  p": { color: "#fff", margin: "10px 0" },
  "& .team_section": {
    minHeight: "735px",
    backgroundImage: `url(${ShipBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "40px 0px",
  },
  "& .team_section h3": {
    color: "#fff",
    fontSize: "36px",
    fontWeight: "600",
    fontFamily: "'Inter', sans-serif",
  },
  "& .team_card": {
    display: "flex",
    backgroundColor: "#fff",
    border: "1px solid #D1D2E3",
    margin: "25px 0px 0",
    padding: "18px 18px 0",
    // alignItems: "center",
    borderRadius: "8px",
  },
  "& .team_card h6": {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "'Inter', sans-serif",
  },
  "& .fully_manage_section": {
    minHeight: "500px",
    backgroundImage: `url(${ManageBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "120px 100px",
  },
  "& .fully_manage_section h3": {
    fontSize: "36px",
    color: "#fff",
  },
  "& .fully_manage_section p": {
    margin: "10px 0",
  },
  "& .fully_manage_section .bottom_title": {
    position: "absolute",
    width: "100%",
    color: "#fff",
    bottom: "0",
    padding: "0 10px ",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    background:
      " linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.69) 82.81%)",
  },
  "& .manage_card": {
    position: "relative",
    backgroundSize: "100% 100%",
    minHeight: "260px",
    margin: "30px 0 0",
    borderRadius: "10px 10px",
    backgroundRepeat: "no-repeat",
  },
  "& .form_section": {
    padding: "80px 0",
    backgroundColor: "#21368D",
    position: "relative",
  },
  "& .service_name": {
    fontSize: "20px",
    fontFamily: "'Inter', sans-serif",
    marginTop: "10px",
  },

  "& .form_section label": { fontWeight: "500" },
  "& .truck_pic": {
    position: "absolute",
    left: "0",
    width: "200px",
    heighg: "70px",
    bottom: "0",
  },
  "& .boat_icon": {
    position: "absolute",
    right: "0",
    width: "244px",
    height: "55px",
    bottom: "0",
  },
  "& .form_section h3": {
    color: "#fff",
    fontSize: "36px",
  },
  "& .contact_icon": {
    width: "50px",
    height: "50px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .contact_icon svg": {
    fontSize: "12px",
    height: "30px",
    width: "30px",
  },
  "& .form_card": {
    backgroundColor: "#fff",
    borderRadius: "10px",
  },
  "& .accordian_section": { padding: "90px 0" },
  "& .accordian_section h3": {
    fontSize: "36px",
    fontWeight: "600",
  },
  "& .accordian_div": {
    marginBottom: "18px",
    boxShadow: "none",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  "& .accordian_details": {
    borderTop: "1px solid #ccc",
    margin: "0 20px",
    padding: "10px 0",
  },
  "& .accordian_details  p": {
    color: "#545C71",
    fontSize: "16px",
  },
  "& .service_section": {
    padding: "120px 0 80px",
    position: "relative",
    background:
      "linear-gradient(180deg, #F4F5FF -24.83%, rgba(255, 255, 255, 0) 100%)",
  },
  "& .service_card": {
    width: "300px",
    height: "265px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D1D2E3",
    borderRadius: "8px",
    padding: "30px 18px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    fontFamily: "'Inter', sans-serif",
  },
  "& .service_section h3": {
    fontSize: "36px",
    color: "#141D2E",
    fontWeight: "600",
    fontFamily: "'Inter', sans-serif",
  },
  "& .service_card p": {
    color: "#545C71",
    fontFamily: "'Inter', sans-serif",
  },
  "& .service_card h6": {
    color: "#545C71",
    fontSize: "24px",
    fontFamily: "'Inter', sans-serif",
  },

  "& .icon_service": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    height: "60px",
    background: "#6D5BDE",
    borderRadius: "10.9091px",
    margin: "0 auto 10px",
  },
  "& .first_service": { position: "relative" },

  "& .second_service": {
    marginTop: "220px",
    position: "relative",
  },
  "& .position_top_div": {
    display: "flex",
    width: "100%",
    justifyContent: "end",
  },
  "& .position_top_div img": {
    width: "100%",
  },
  "& .position_top_div .first_div_img": {
    marginTop: "70px",
    marginRight: "-70px",
    zIndex: "9",
    width: "50%",
  },
  "& .second_div_img": {
    width: "50%",
  },
  "& .icon_box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    height: "60px",
    background: "#6D5BDE",
    borderRadius: "10.9091px",
  },
  "& .icon_box svg": { width: "60px" },
  "& .border_btn_head": {
    border: "2px solid #6D5BDE",
  },
  "& input:placeholder": {
    color: "#9098AF",
  },
}));

export default HeaderMainStyle;
