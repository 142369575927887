import { Dialog } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const ModalStyled = styled(Dialog)(() => ({
  "& .MuiPaper-rounded": {
    borderRadius: "10px",
    "@media (max-width:992px)": {
      borderRadius: "0px",
    },
  },
}));

const ModalView = ({ fullScreen, open, handleClose, content, maxWidth }) => {
  return (
    <>
      <ModalStyled
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        fullWidth
      >
        {content}
      </ModalStyled>
    </>
  );
};
export default ModalView;
