import React from "react";
import { DataGrid } from "@mui/x-data-grid";
export default function Table(props) {
  console.log(props, "check prprprprp  ");
  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        {" "}
        <DataGrid
          rows={props.rows}
          columns={props.columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          //   checkboxSelection
        />
      </div>
    </>
  );
}
