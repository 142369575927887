import { styled } from "@mui/system";

const ExportStyled = styled("div")(({ theme }) => ({
  "& .export-content h2": {
    margin: "0px",
    color: "#333333",

    fontSize: "24px",
    fontWeight: "400",
    "@media (max-width:992px)": {
      fontSize: "17px",
    },
  },
  "& .mui-box": {
    "@media (max-width:992px)": {
      padding: "16px",
    },
  },
  "& .MuiIconButton-root": { color: "#000", padding: "0px" },
  "& .border-bottom": {
    borderBottom: "1px solid #DAE3EB",
    padding: "19.44px 43px 21.56px 43px",
    "@media (max-width:992px)": {
      padding: "16px",
    },
  },
  "& .drop-content": {
    padding: "19px 43px",
    "@media (max-width:992px)": {
      padding: "16px",
    },
  },
  "& .dropzone": {
    background: "rgba(216, 222, 233, 0.2)",
    border: "2px dashed #C4C4C4",
    marginBottom: "26px",

    borderRadius: "24px",
    padding: "20px",
  },
  "& .upload-button-box": {
    textAlign: "center",
  },
  "& .upload-button-box svg": {
    marginBottom: "20px",
    color: "#D8DEE9",
  },
  "& .upload-button-box span": {
    display: "block",
    color: "#465BBA",
    fontFamily: "Inter",
    Textdecoration: "underline",
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "20px",
  },
  "& .MuiButton-contained": {
    borderRadius: "5px",
    background: "#427AAD",
    padding: "7px 35px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .listitem": {
    margin: "16px 0px 0px 0px",
    padding: "0px",
  },
  "& .listitem li": {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .export-btn": {
    color: theme.palette.secondary.main,
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "35px",
    padding: "6px 10px",
    "@media (max-width:992px)": {
      width: "100%",
      fontSize: "14px",
    },
  },
  "& .export-btn svg": {
    marginRight: "10px",
  },
  "& .card": {
    display: "block",
    background: "#F7F9FA",
    borderRadius: "20px",
    padding: "25px",
    color: "#9FB1C1",
    position: "relative",
    textAlign: "center",
    height: "100%",
    boxSizing: "border-box",
    border: "2px solid transparent",
    cursor: "pointer",
  },
  "& .card h6": {
    margin: "22px 0px 0px",
    color: "#707D88",
    fontSize: "18px",
    fontWeight: "700",
  },
  "& .checkbox": {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  "& .selected": {
    border: "2px solid #2B3A90",
    boxShadow: "0px 8px 20px rgba(3, 131, 255, 0.2)",
    background: "#FFFFFF",
  },
  "& .MuiFormControlLabel-labelPlacementStart": {
    background: "#F7F9FA",
    borderRadius: "15px",
    padding: "10px",
    marginRight: "0",
    border: "1px solid transparent",
  },
  "& .MuiFormControlLabel-labelPlacementStart .MuiRadio-root": {
    padding: "0px",
    color: "#427AAD",
  },
  "& .MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label": {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    color: "#1F4970",
    marginRight: "50px",
    "@media (max-width:992px)": {
      marginRight: "15px",
    },
  },
  "& .MuiFormControlLabel-labelPlacementStart .Mui-checked": {
    color: "#427AAD",
  },
  "& .pallet-selected": {
    border: "1px solid #2B3A90",
  },
  "& img": { maxWidth: "100%" },
  "& .form-group label": {
    color: "#9FB1C1",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "8px",
    display: "block",
    paddingLeft: "10px",
  },
  "& .card.parameters-check h6": {
    fontSize: "16px",
    color: "#1F4970",
    fontWeight: 400,
  },
  "& .is-checked": {
    background: "#E6E8FF",
  },
  "& .is-checked h6": {
    color: "#2B3A90 !important",
  },
}));

export default ExportStyled;
