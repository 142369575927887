import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingType,
  setSelectedPalletType,
} from "../../reducers/modalSlice";

const SelectStyled = styled("div")(() => ({
  "& fieldset": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    border: "1px solid #C3CAD5",
    width: "100%",
    borderRadius: "45px",
    color: "#666666",
    fontSize: "12px",
    fontWeight: 500,
  },
}));


const SelectBox = ({
  selectItems,
  height,
  padding,
  bgColor,
  borderColor,
  selectedItem,
  valuePalletType,
  handleChange
}) => {
  // const [age, setAge] = React.useState(selectedItem | "");
 
  // const [palletSettingPalletType,setPalletSettingPalletType]= useState('');
  // const isPalletSettingModelOpen = useSelector((state) => state.modalHandler.cargoModal);
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  //   console.log("test 1",isPalletSettingModelOpen);
  //   if(!isPalletSettingModelOpen){
  //     dispatch(
  //       setLoadingType({
  //         loadingType: "pallet",
  //         pallet_info: {
  //           palletType: event.target.value,
  //           ...pallet_info(event.target.value),
  //         },
  //       })
  //     );
  //   }else{
  //     setPalletSettingPalletType(event.target.value);
  //   }
    
  // };
 
  return (
    <SelectStyled>
      <Select
        size="small"
        sx={{
          width: "100%",
          height: height,
          padding: padding,
          background: bgColor,
          borderColor,
        }}
        IconComponent={KeyboardArrowUpIcon}
        value={selectedItem}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {selectItems.map((option,i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </SelectStyled>
  );
};
export default SelectBox;
