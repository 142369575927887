import {
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { SVG } from "../../../assert/svg";
import ExportStyled from "../../container-type/export-content/style";
import palletsContainer from "../../../assert/images/pallet-container.png";
import InputTextField from "../../textfield";
import SelectBox from "../../select/SelectBox";
import { BlueButtons } from "../../button";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingType } from "../../../reducers/modalSlice";
import { changeAllValueUnits, formatValue } from "../../../utils/calculation";
import { pallet_info } from "../../../utils/defaultValues";
const PalletsDimensions = ({ handleClose }) => {
  // const [isLoadingChecked, setISLoadingChecked] = useState("container");
  const content = [
    {
      value: "1",
      label: "Europe Pallets",
    },
    {
      value: "2",
      label: "Europe 1/2 Pallets",
    },
    {
      value: "3",
      label: "Europe Plastic Pallets",
    },
    {
      value: "4",
      label: "US Pallets",
    },
    {
      value: "5",
      label: "Custom Pallets",
    },
  ];
  const loadingType = useSelector((state) => state.modalHandler.loadingType);
  const [palletType, setPalletType] = useState(
    loadingType.pallet_info.palletType || "1"
  );
  const [max_loading_height_level, set_max_loading_height_level] = useState(
    loadingType.pallet_info.max_loading_height_level || 0
  );
  const [thickness_of_separating_plate, set_thickness_of_separating_plate] =
    useState(loadingType.pallet_info.thickness_of_separating_plate || 0);
  const [palletLength, setPalletLength] = useState(
    loadingType.pallet_info.length || 0
  );
  const [palletWidth, setPalletWidth] = useState(
    loadingType.pallet_info.width || 0
  );
  const [palletHeight, setPalletHeight] = useState(
    loadingType.pallet_info.height || 0
  );
  const [palletPayload, setPalletPayload] = useState(
    loadingType.pallet_info.payload || 0
  );
  const [measurementUnit, setMeasurementUnit] = useState(
    loadingType.pallet_info.measurementUnit || "mm"
  );
  const [weightUnit, setWeightUnit] = useState(
    loadingType.pallet_info.weightUnit || "kg"
  );
  const dispatch = useDispatch();
  const sumbitData = () => {
    const palletData = {
      loadingType: "pallet",
      pallet_info: {
        palletType: palletType,
        length: palletLength,
        width: palletWidth,
        height: palletHeight,
        payload: palletPayload,
        max_loading_height_level,
        thickness_of_separating_plate,
        measurementUnit,
        weightUnit,
      },
    };
    dispatch(setLoadingType(palletData));
    // changeAllValueUnits(measurementUnit, dispatch);
    handleClose();
  };

  const handleUnitChange = (mUnit, wUnit) => {
    if (mUnit === measurementUnit && wUnit === weightUnit) return;
    setMeasurementUnit(mUnit);
    setWeightUnit(wUnit);
    // setPalletLength(measurementConversion(palletLength, mUnit));
    // setPalletWidth(measurementConversion(palletWidth, mUnit));
    // setPalletHeight(measurementConversion(palletHeight, mUnit));
    // setPalletPayload(weightConversion(palletPayload, wUnit));
    // set_max_loading_height_level(
    //   measurementConversion(max_loading_height_level, mUnit)
    // );
    // set_thickness_of_separating_plate(
    //   measurementConversion(thickness_of_separating_plate, mUnit)
    // );
  };

  useEffect(() => {
    const palletData = pallet_info(palletType, loadingType.pallet_info);
    dispatch(
      setLoadingType({
        loadingType: "pallet",
        pallet_info: {
          palletType: palletType,
          ...palletData,
        },
      })
    );

    setPalletLength(palletData.length);
    setPalletWidth(palletData.width);
    setPalletHeight(palletData.height);
    setPalletPayload(palletData.payload);
    set_max_loading_height_level(palletData.max_loading_height_level);
    set_thickness_of_separating_plate(palletData.thickness_of_separating_plate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palletType]);

  return (
    <ExportStyled>
      <div className="export-content">
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems={{ xs: "start", sm: "center" }}
          className="border-bottom"
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 3.5 }}
            alignItems={{ xs: "start", sm: "center" }}
          >
            <h2>SELECT PALLETS DIMENSIONS</h2>
            {/* <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                className={
                  measurementUnit === "mm" && weightUnit === "kg"
                    ? "pallet-selected"
                    : ""
                }
                value="female"
                control={
                  <Radio
                    disableRipple={true}
                    checked={measurementUnit === "mm" && weightUnit === "kg"}
                    onClick={() => handleUnitChange("mm", "kg")}
                  />
                }
                label="mm/kg"
                labelPlacement="start"
              />
              <FormControlLabel
                className={
                  measurementUnit === "inch" && weightUnit === "lbs"
                    ? "pallet-selected"
                    : ""
                }
                value="male"
                control={
                  <Radio
                    disableRipple={true}
                    checked={measurementUnit === "inch" && weightUnit === "lbs"}
                    onClick={() => handleUnitChange("inch", "lbs")}
                  />
                }
                label="inch/lbs"
                labelPlacement="start"
              />
            </RadioGroup> */}
          </Stack>
          <IconButton disableRipple={true} onClick={handleClose}>
            <SVG.CloseIcon />
          </IconButton>
        </Stack>
        <Box sx={{ py: 5, px: 5.375 }} className="mui-box">
          <Grid container spacing={3.75}>
            <Grid item lg={3} xs={12}>
              <div className="img-container">
                <img src={palletsContainer} alt="" />
              </div>
            </Grid>
            <Grid item lg={9}>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                  <Box className="form-group">
                    <FormLabel>Choose Pallet</FormLabel>
                    <SelectBox
                      selectItems={content}
                      selectedItem={palletType}
                      handleChange={(event) =>
                        setPalletType(event.target.value)
                      }
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Box className="form-group">
                    <FormLabel> Max loading height level</FormLabel>
                    <InputTextField
                      kg={measurementUnit}
                      value={formatValue(max_loading_height_level)}
                      handlerChange={(event) =>
                        set_max_loading_height_level(event.target.value)
                      }
                      inputType="number"
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Box className="form-group">
                    <FormLabel>Thickness of separating plate</FormLabel>
                    <InputTextField
                      kg={measurementUnit}
                      value={formatValue(thickness_of_separating_plate)}
                      handlerChange={(event) =>
                        set_thickness_of_separating_plate(event.target.value)
                      }
                      inputType="number"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Box className="form-group">
                    <FormLabel>Length</FormLabel>
                    <InputTextField
                      kg={measurementUnit}
                      value={formatValue(palletLength)}
                      handlerChange={(event) =>
                        setPalletLength(event.target.value)
                      }
                      inputType="number"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Box className="form-group">
                    <FormLabel>Width / Diameter</FormLabel>
                    <InputTextField
                      kg={measurementUnit}
                      value={formatValue(palletWidth)}
                      handlerChange={(event) =>
                        setPalletWidth(event.target.value)
                      }
                      inputType="number"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Box className="form-group">
                    <FormLabel>Height</FormLabel>
                    <InputTextField
                      kg={measurementUnit}
                      value={formatValue(palletHeight)}
                      handlerChange={(event) =>
                        setPalletHeight(event.target.value)
                      }
                      inputType="number"
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Box className="form-group">
                    <FormLabel>Payload</FormLabel>
                    <InputTextField
                      kg={weightUnit}
                      value={formatValue(palletPayload)}
                      handlerChange={(event) =>
                        setPalletPayload(event.target.value)
                      }
                      inputType="number"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2.5 }}
            justifyContent="end"
            sx={{ mt: 3.75 }}
          >
            <BlueButtons
              text="Add"
              onClick={() => {
                sumbitData();
              }}
            />
          </Stack>
        </Box>
      </div>
    </ExportStyled>
  );
};
export default PalletsDimensions;
