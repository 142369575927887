import React, { useEffect, useState } from "react";
import styles from "./adminSetting.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ManageAdminSetting() {
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <div className="p-4">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage SMTP Setting</h3>
              </div>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label>Mail Mailer</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Mailer"
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label>Host Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Host Name"
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label>Port</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Port"
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label>Username</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Username"
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label>Password</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Password"
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label>Mail Encryption</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Encryption"
                    className={styles.form_input}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button className={styles.main_button}>Update</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
