import { changeAllUnits } from "../reducers/modalSlice";

export const mmToM = (measurementType, value) => {
  switch (measurementType) {
    case "mm":
      return value / 1000;
    case "inch":
      return value / 12;
    default:
      break;
  }
};
export const numberFormat = (value, digitLength) => {
  return (value || 0).toFixed(digitLength);
};

export const CheckCargoWeightVolume = (cargo, remainingContainerVolume) => {
  // check for volume
  if (
    parseFloat(remainingContainerVolume) >
      mmToM("mm", parseFloat(cargo.length)) *
        mmToM("mm", parseFloat(cargo.width)) *
        mmToM("mm", parseFloat(cargo.height)) *
        cargo.quantity &&
    parseFloat(remainingContainerVolume) >
      mmToM("mm", parseFloat(cargo.length)) *
        mmToM("mm", parseFloat(cargo.width)) *
        mmToM("mm", parseFloat(cargo.height)) *
        cargo.quantity
  ) {
    return true;
  } else {
    return false;
  }
};

export const getVolumePercent = (totalContainerVolume, cargoVolume) => {
  return ((cargoVolume / totalContainerVolume) * 100).toFixed(2);
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const measurementConversion = (value, to) => {
  return to === "inch" ? value / 25.4 : value * 25.4;
};

export const weightConversion = (value, to) => {
  return to === "lbs" ? value * 2.20462 : value / 2.20462;
};

export const convertedMUnit = (value, currUnit, to) => {
  if (currUnit === to) return value;
  return to === "inch" ? value / 25.4 : value * 25.4;
};

export const convertedWUnit = (value, currUnit, to) => {
  if (currUnit === to) return value;
  return to === "lbs" ? value * 2.20462 : value / 2.20462;
};

export const calculateCargoVolume = (cargo) => {
  return (
    mmToM(cargo.measurementUnit, cargo.length) *
    mmToM(cargo.measurementUnit, cargo.width) *
    mmToM(cargo.measurementUnit, cargo.height) *
    cargo.quantity
  );
};

export const calculatePalletVolume = (pallet) => {
  return pallet
    ? mmToM(pallet.measurementUnit, pallet.length) *
        mmToM(pallet.measurementUnit, pallet.width) *
        mmToM(pallet.measurementUnit, pallet.height)
    : 0;
};

export const calculateCargoRealVolume = (cargo) => {
  if (
    cargo.cargoType === "box" ||
    cargo.cargoType === "big-bag" ||
    cargo.cargoType === "sack"
  ) {
    return (
      mmToM(cargo.measurementUnit, cargo.length) *
      mmToM(cargo.measurementUnit, cargo.width) *
      mmToM(cargo.measurementUnit, cargo.height) *
      cargo.quantity
    );
  } else {
    return (
      Math.PI *
      Math.pow(mmToM(cargo.measurementUnit, cargo.width) / 2, 2) *
      mmToM(cargo.measurementUnit, cargo.height) *
      cargo.quantity
    );
  }
};

export const calculateCargoWeight = (cargo) => {
  return cargo.weight * cargo.quantity;
};

export const calculateContainerVolume = (container) => {
  return (
    mmToM(container.measurementUnit, container.length) *
    mmToM(container.measurementUnit, container.width) *
    mmToM(container.measurementUnit, container.height) *
    container.qty
  );
};

export const calculateContainerWeight = (container) => {
  return container.weight * container.qty;
};

export const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const changeAllValueUnits = (unit, dispatch) => {
  dispatch(
    changeAllUnits({
      measurementUnit: unit,
      weightUnit: unit === "mm" ? "kg" : "lbs",
    })
  );
};

export const boxDimensions = (box) => {
  if (!box)
    return {
      boxLength: 0,
      boxHeight: 0,
      boxWidth: 0,
    };
  return box?.measurementUnit === "mm"
    ? {
        boxLength: box.length,
        boxHeight: box.height,
        boxWidth: box.width,
      }
    : {
        boxLength: box.length * 25.4,
        boxHeight: box.height * 25.4,
        boxWidth: box.width * 25.4,
      };
};

export const cylinderDimensions = (box) => {
  if (!box)
    return {
      cylinderDiameter: 0,
      cylinderHeight: 0,
    };
  return box?.measurementUnit === "mm"
    ? {
        cylinderDiameter: box.width,
        cylinderHeight: box.height,
      }
    : {
        cylinderDiameter: box.width * 25.4,
        cylinderHeight: box.height * 25.4,
      };
};

export const formatValue = (value) => {
  if (!value) return value;
  let convertedValue = value.toString();
  if (convertedValue.indexOf(".") === -1) return value;
  convertedValue = convertedValue.slice(0, convertedValue.indexOf(".") + 3);
  return value && Number(convertedValue);
};

export const hasOnlyDuplicates = (arr) =>
  arr.every((obj) => obj.type === arr[0].type);
