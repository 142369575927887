import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../admin.module.css";
export default function Header() {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.header_section}>
        <ul className="header_menu">
          <li>
            <Dropdown className={styles.header_dropdown}>
              <Dropdown.Toggle id="dropdown-basic">Admin</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => navigate("/admin/profile-setting")}
                >
                  Setting
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/admin" className={styles.logout_btn}>
                    {" "}
                    Logout
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </>
  );
}
