import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SVG } from "../../assert/svg";
import {
  setCargoModal,
  setLoadingType,
  setLoadingTypePalletValues,
} from "../../reducers/modalSlice";
import { pallet_info } from "../../utils/defaultValues";
import SelectOption from "../select";
import SelectBox from "../select/SelectBox";
import InputTextField from "../textfield";
import { formatValue } from "../../utils/calculation";
const content = [
  {
    value: "1",
    label: "Europe Pallets",
  },
  {
    value: "2",
    label: "Europe 1/2 Pallets",
  },
  {
    value: "3",
    label: "Europe Plastic Pallets",
  },
  {
    value: "4",
    label: "US Pallets",
  },
  {
    value: "5",
    label: "Custom Pallets",
  },
];
const maxLoading = [
  {
    value: "MM",
    label: "mm",
  },
  {
    value: "KG",
    label: "kg",
  },
];

export const PALLET_COLUMN_DATA = [
  {
    id: 1,
    name: "Pallets type",
    key: "palletstype",
    width: 86,
  },

  {
    id: 2,
    name: "Width / Diameter",
    key: "diameter",
    width: 86,
  },
  {
    id: 3,
    name: "Height",
    key: "height",
    width: 100,
  },
  {
    id: 8,
    name: "Length",
    key: "length",
    width: 100,
  },
  {
    id: 4,
    name: "Payload",
    key: "payload",
    width: 86,
  },
  {
    id: 5,
    name: "Max loading height level",
    key: "maxloading",
  },
  {
    id: 6,
    name: "Thickness of separating plate",
    key: "thickness",
  },
  {
    id: 7,
    name: "Action",
    key: "action",
  },
];

export const PALLET_ROW_DATA = (loadingType) => {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(
      setLoadingType({
        loadingType: "pallet",
        pallet_info: {
          palletType: event.target.value,
          ...pallet_info(event.target.value, loadingType.pallet_info),
        },
      })
    );
  };

  const onValueChange = (field, value) => {
    dispatch(
      setLoadingTypePalletValues({
        loadingType: "pallet",
        field,
        value,
      })
    );
  };

  // console.log("loadingType ---- data ---",loadingType);
  return [
    {
      palletstype: (
        <SelectBox
          selectItems={content}
          selectedItem={loadingType.pallet_info.palletType || "1"}
          height="30px"
          padding="0px 10px"
          handleChange={handleChange}
        />
      ),
      diameter: (
        <InputTextField
          kg={loadingType.pallet_info.measurementUnit || "mm"}
          value={formatValue(loadingType.pallet_info.width)}
          height="30px"
          handlerChange={(e) => onValueChange("width", e.target.value)}
        />
      ),
      height: (
        <InputTextField
          kg={loadingType.pallet_info.measurementUnit || "mm"}
          value={formatValue(loadingType.pallet_info.height)}
          height="30px"
          handlerChange={(e) => onValueChange("height", e.target.value)}
        />
      ),
      length: (
        <InputTextField
          value={formatValue(loadingType.pallet_info.length)}
          kg={loadingType.pallet_info.measurementUnit || "mm"}
          height="30px"
          handlerChange={(e) => onValueChange("length", e.target.value)}
        />
      ),
      payload: (
        <InputTextField
          value={formatValue(loadingType.pallet_info.payload)}
          kg={loadingType.pallet_info.weightUnit || "kg"}
          height="30px"
          handlerChange={(e) => onValueChange("payload", e.target.value)}
        />
      ),
      maxloading: (
        <InputTextField
          value={formatValue(loadingType.pallet_info.max_loading_height_level)}
          kg={loadingType.pallet_info.measurementUnit || "mm"}
          height="30px"
          handlerChange={(e) =>
            onValueChange("max_loading_height_level", e.target.value)
          }
        />
      ),
      thickness: (
        <InputTextField
          kg={loadingType.pallet_info.measurementUnit || "mm"}
          value={formatValue(
            loadingType.pallet_info.thickness_of_separating_plate
          )}
          height="30px"
          handlerChange={(e) =>
            onValueChange("thickness_of_separating_plate", e.target.value)
          }
        />
      ),
      action: (
        <Button
          onClick={() => {
            dispatch(setCargoModal(true));
          }}
          className="setting-button"
        >
          <SVG.SettingIcon />
        </Button>
      ),
    },
  ];
};
