import {
  Box,
  Card,
  CardContent,
  IconButton,
  Switch,
  TableContainer,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { SVG } from "../../assert/svg";
import CardStyled from "../container-type/style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { USER_COLUMN_DATA, USER_ROW_DATA } from "./data";
import TableStyled from "./styles";
import { BlueButtons, OutLineButtons } from "../button";
import ModalView from "../modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CargoSpecifications from "./CargoSpecifications";
import {
  changeAllUnits,
  setCalculate,
  setCargoParkingModal,
  setUpdateCargoPacking,
} from "../../reducers/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { SPECIAL_CHAR_REGEX } from "../../utils/defaultValues";
import { toast } from "react-toastify";

const CargoPackaging = ({ handleSelectedContainer, selectedContainer }) => {
  const dispatch = useDispatch();
  const CargoParkingModal =
    useSelector((state) => state.modalHandler.CargoParkingModal) || false;

  const {
    measurementUnit,
    ImportedExcelData,
    ContainerTypeData,
    containerSelectionType,
    truckSelectionType,
  } = useSelector((state) => state.modalHandler);

  const isTruck = ContainerTypeData === "Truck";

  const containerDetails = isTruck
    ? truckSelectionType?.container
    : containerSelectionType?.container;

  const MAX_LENGTH = [...containerDetails].sort(
    (a, b) => b.length - a.length
  )[0].length;
  const MAX_WIDTH = [...containerDetails].sort((a, b) => b.width - a.width)[0]
    .width;
  const MAX_HEIGHT = [...containerDetails].sort(
    (a, b) => b.height - a.height
  )[0].height;
  const MAX_WEIGHT = [...containerDetails].sort(
    (a, b) => b.weight - a.weight
  )[0].weight;

  const [disableCalculate, setDisableCalculate] = React.useState(true);
  const [changed, setChanged] = React.useState(false);

  useEffect(() => {
    if (!changed) {
      setChanged(true);
    } else {
      const errors = [...ImportedExcelData].every((item) => {
        if (
          item.length < 0 ||
          item.width < 0 ||
          item.height < 0 ||
          item.weight < 0 ||
          !SPECIAL_CHAR_REGEX.test(item.length) ||
          !SPECIAL_CHAR_REGEX.test(item.width) ||
          !SPECIAL_CHAR_REGEX.test(item.height) ||
          !SPECIAL_CHAR_REGEX.test(item.weight) ||
          item.quantity < 0
          // ||
          // item.quantity >= MAX_WEIGHT / item.weight
        )
          return false;
        if (
          item.length >= MAX_LENGTH ||
          item.width >= MAX_WIDTH ||
          item.height >= MAX_HEIGHT
          // ||
          // item.weight * item.quantity >= MAX_WEIGHT
        ) {
          return false;
        } else {
          return true;
        }
      });
      setDisableCalculate(!errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ImportedExcelData]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClose = () => {
    dispatch(setCargoParkingModal(false));
    dispatch(setUpdateCargoPacking(false));
  };
  const handleClickOpen = () => {
    handleSelectedContainer("cargo");
    if (ContainerTypeData !== "") {
      // if(ImportedExcelData.length<03)
      dispatch(setCargoParkingModal(true));
      // else
      // toast.warn("You add upto 30 Cargo");
    } else {
      toast.warn("Please Select Container Type!");
    }
  };

  const handleChange = (event, newUnit) => {
    if (!newUnit) return;
    handleSelectedContainer("cargo");
    // changeAllValueUnits(newUnit, dispatch);
    dispatch(
      changeAllUnits({
        measurementUnit: newUnit,
        weightUnit: newUnit === "mm" ? "kg" : "lbs",
      })
    );
  };

  return (
    <>
      <CardStyled>
        <Card className={selectedContainer === "cargo" ? "selectedBox" : ""}>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 3.75 }}
            >
              <Stack spacing={1.875} direction="row" alignItems="center">
                <IconButton disableRipple>
                  <SVG.BackArrowIcon />
                </IconButton>
                <Typography variant="h1">Step 2: Cargo Packaging</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={measurementUnit}
                  onChange={handleChange}
                  exclusive={true}
                  style={{
                    marginInline: 10,
                  }}
                >
                  <ToggleButton
                    sx={{
                      fontSize: 10,
                      textTransform: "lowercase",
                    }}
                    value="mm"
                    key="mm"
                  >
                    <h6 style={{ margin: 0 }}>mm/kg</h6>
                  </ToggleButton>
                  ,
                  <ToggleButton
                    sx={{
                      fontSize: 10,
                      textTransform: "lowercase",
                    }}
                    value="inch"
                    key="inch"
                  >
                    <h6 style={{ margin: 0 }}>inch/lbs</h6>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Stack>
            <TableStyled>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      {(USER_COLUMN_DATA || []).map((data, i) => (
                        <TableCell key={i} sx={{ width: data.width }}>
                          {data.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(USER_ROW_DATA() || []).map((row, i) => (
                      <TableRow key={i}>
                        {Object.keys(row).map((key, i) => {
                          return <TableCell key={i}>{row[key]}</TableCell>;
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <OutLineButtons
                  text="Add cargo"
                  icon={<SVG.PlushIcon />}
                  onClick={() => handleClickOpen()}
                />
                <BlueButtons
                  disabled={disableCalculate}
                  sx={{
                    backgroundColor: disableCalculate ? "#E5E5E5" : "#2B3A90",
                  }}
                  text="Calculate"
                  onClick={() => {
                    handleSelectedContainer("cargo");
                    dispatch(setCalculate(true));
                    setDisableCalculate(true);
                    // setTimeout(() => {
                    //   dispatch(setCalculate(false));
                    // }, 300);
                  }}
                />
              </Box>
            </TableStyled>
          </CardContent>
        </Card>
        <ModalView
          open={CargoParkingModal}
          fullScreen={fullScreen}
          handleClose={handleClose}
          maxWidth="lg"
          content={<CargoSpecifications handleClose={handleClose} />}
        />
      </CardStyled>
    </>
  );
};
export default CargoPackaging;
