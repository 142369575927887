import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { SVG } from "../../assert/svg";
import { FountainButtons } from "../../components/button";
import CardStyled from "./style";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalView from "../modal";
import ExportModalContent from "./export-content/ExportModalContent";
import {
  setCalculate,
  setContainerSelectionType,
  setContainerTypeData,
  setLoadingType,
  setTruckSelectionType,
} from "../../reducers/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { defaultContainerDataAsUnit } from "../../utils/defaultValues";

const ContainerType = ({ handleSelectedContainer, selectedContainer }) => {
  const [selectedOption, setSelectedOption] = useState("container");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const isContainerAlreadySelected =
    Object.keys(
      useSelector((state) => state.modalHandler.containerSelectionType)
    ).length > 0;

  const isLoadingTypeSelected =
    Object.keys(useSelector((state) => state.modalHandler.loadingType)).length >
    0;

  const handleClickOpen = () => {
    if (selectedOption) setOpen(true);
    else toast.warn("Please Select Container Type!");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const handleContainer = () => {
    handleSelectedContainer("container");
    setSelectedOption("container");
    dispatch(setContainerTypeData("Container"));
    dispatch(setCalculate(true));
    !isLoadingTypeSelected &&
      dispatch(setLoadingType({ loadingType: "direct" }));
    if (isContainerAlreadySelected) return;
    const newData = {
      type: "1",
      id: uuidv4(),
      ...defaultContainerDataAsUnit("Container", "mm").container[0],
    };
    dispatch(
      setContainerSelectionType({
        type: "manual",
        container: [newData],
      })
    );
  };

  const handleTruck = () => {
    handleSelectedContainer("container");
    setSelectedOption("truck");
    dispatch(setContainerTypeData("Truck"));
    dispatch(setCalculate(true));
    !isLoadingTypeSelected &&
      dispatch(setLoadingType({ loadingType: "direct" }));
    // if (isContainerAlreadySelected) return;
    // const newData = {
    //   type: "Truck",
    //   id: uuidv4(),
    //  ...defaultContainerDataAsUnit("Truck", measurementUnit).container[1],
    // };
    // dispatch(
    //   setTruckSelectionType({
    //     type: "manual",
    //     container: [newData],
    //   })
    // );
  };

  return (
    <CardStyled>
      <Card className={selectedContainer === "container" ? "selectedBox" : ""}>
        <CardContent>
          <Stack
            spacing={1.875}
            direction="row"
            alignItems="center"
            sx={{ mb: 1.875 }}
          >
            <IconButton disableRipple>
              <SVG.BackArrowIcon />
            </IconButton>

            <Typography variant="h1">Step 1: Container Type</Typography>
          </Stack>

          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <Card
                className={`containerBox ${
                  selectedOption === "container" ? "selectedcontainer" : ""
                }`}
              >
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    component="label"
                    className="container-color"
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <SVG.ContainerIcon /> <span>Container</span>
                    </Stack>
                    <Radio
                      disableRipple={true}
                      checked={selectedOption === "container"}
                      onClick={() => handleContainer()}
                      checkedIcon={<SVG.CheckedIcon />}
                      icon={<SVG.UncheckedIcon />}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={6} xs={12}>
              <Card
                className={`containerBox ${
                  selectedOption === "truck" ? "selectedcontainer" : ""
                }`}
              >
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    component="label"
                    className="container-color"
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <SVG.TrackIcon /> <span>Truck</span>
                    </Stack>
                    <Radio
                      checked={selectedOption === "truck"}
                      onClick={() => handleTruck()}
                      checkedIcon={<SVG.CheckedIcon />}
                      icon={<SVG.UncheckedIcon />}
                      disableRipple={true}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2.5 }}
            sx={{ mt: 1.75 }}
          >
            <FountainButtons text="Import" onClick={handleClickOpen} />
            <Button component="span" className="export-btn">
              <a href={"/excel/import.xlsx"} download>
                {" "}
                <SVG.DwonloadIcon /> Get Excel Import Template XLS{" "}
              </a>
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <ModalView
        open={open}
        fullScreen={fullScreen}
        handleClose={handleClose}
        maxWidth="lg"
        content={<ExportModalContent handleClose={handleClose} />}
      />
    </CardStyled>
  );
};
export default ContainerType;
