import React from "react";
import Header from "../header";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Home from "../../pages/home";
import AboutUs from "../../pages/about-us";
import ContactUs from "../../pages/contact-us";
import HeaderMain from "../headerMain";
import LandingPageFooter from "../footer";
let routeArr = [
  {
    id: 1,
    path: "/load-calculator",
    componant: <Home />,
  },
  {
    id: 1,
    path: "/about-us",
    componant: <AboutUs />,
  },
  {
    id: 1,
    path: "/contact-us",
    componant: <ContactUs />,
  },
];
const Layout = ({ children }) => {
  return (
    <>
      <div className="fixing-page">
        {/* <Header /> */}
        <HeaderMain className="fixing-page-header" />

        <Box component="main" sx={{ p: 3, mt: { xs: "85px", sm: "92px" } }}>
          <Routes>
            {routeArr.map((item, i) => (
              <Route key={i} path={item.path} element={item.componant} />
            ))}
          </Routes>
        </Box>
        {children}
        <LandingPageFooter />
      </div>
    </>
  );
};
export default Layout;
