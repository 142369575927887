import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// import logo from "../../assert/images/logo.png";
import { SVG } from "../../assert/svg";
import { Link, useLocation } from "react-router-dom";
import HeaderStyle from "./style";
import { BlueButtons } from "../button";

const drawerWidth = 270;
const navItems = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    title: "About US",
    url: "/about-us",
    id: 2,
  },
  {
    title: "Contact Us",
    url: "/contact-us",
    id: 3,
  },
];

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const location = useLocation();

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {/* <img alt="logo" src={Logo} /> */}
        <SVG.Logo />
      </Typography>
      <Divider />
      <List sx={{ px: 2 }}>
        {navItems.map((item,i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton
              LinkComponent={Link}
              to={item.url}
              sx={{ textAlign: "center" }}
              className={location.pathname.includes(item.url) ? "active" : ""}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        <BlueButtons text="Load calculator" />
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <HeaderStyle>
      <>
        <AppBar component="nav">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { sm: "block" } }}
            >
              <SVG.Logo />
            </Typography>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item,i) => (
                <Link
                  to={item.url}
                  key={i}
                  className={`header-menu ${
                    location.pathname.includes(item.url) ? "active" : ""
                  }`}
                >
                  {item.title}
                </Link>
              ))}

              <BlueButtons text="Load calculator" />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </>
    </HeaderStyle>
  );
}

export default Header;
