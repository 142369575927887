import { SwitchLeftSharp } from "@mui/icons-material";
import { Button, Stack, textFieldClasses } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { QuantityPicker } from "react-qty-picker";
import { useDispatch, useSelector } from "react-redux";
import { SVG } from "../../assert/svg";
import {
  setCargoParkingModal,
  setImportedExcelData,
  setPackingParameters,
  setUpdateCargoPacking,
  setUpdateCargoPackingByField,
} from "../../reducers/modalSlice";
import SelectOption from "../select";

const lenght = [
  {
    value: "MM",
    label: "mm",
  },
  {
    value: "INCH",
    label: "inch",
  },
];
const weightList = [
  {
    value: "kg",
    label: "Kg",
  },
  {
    value: "lbs",
    label: "lbs",
  },
];

export const USER_COLUMN_DATA = [
  {
    id: 1,
    name: "Type",
    key: "type",
    width: 60,
  },
  {
    id: 2,
    name: "Product Name",
    key: "productname",
    width: 300,
  },
  {
    id: 3,
    name: "Length",
    key: "length",
    width: 300,
  },
  {
    id: 4,
    name: "Width / Diameter",
    key: "diameter",
    width: 300,
  },
  {
    id: 5,
    name: "Height",
    key: "height",
    width: 300,
  },
  {
    id: 6,
    name: "Weight",
    key: "weight",
    width: 300,
  },
  {
    id: 7,
    name: "Quantity",
    key: "quantity",
    width: 300,
  },
  {
    id: 8,
    name: "Color",
    key: "color",
  },
  {
    id: 9,
    name: "Action",
    key: "action",
  },
];

export const getIcon = (value) => {
  switch (value) {
    case "box":
      return <SVG.CubeIcon className="basket-icon-step2" />;
    case "barrel":
      return <SVG.BarrelIcon className="basket-icon-step2" />;
    case "sack":
      return <SVG.SacksIcon className="basket-icon-step2" />;
    case "big-bag":
      return <SVG.BasketIcon className="basket-icon-step2" />;
    default:
      break;
  }
};

const TempInput = ({ item, updateCargoName }) => {
  const [value, setValue] = useState(item.name || "cargo");
  useEffect(() => {
    setValue(item.name);
  }, [item.name]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (value) => {
    console.log(item.id, "name", value);
    updateCargoName(item.id, "name", value);
  };

  const optimizedFn = useCallback(debounce(handleChange), []);
  return (
    <input
      type="text"
      className="form-control"
      placeholder="Bigbags"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        optimizedFn(e.target.value);
      }}
    />
  );
};
export const USER_ROW_DATA = () => {
  const dispatch = useDispatch();
  const ImportedExcelData = useSelector(
    (state) => state.modalHandler.ImportedExcelData
  );
  const packingParameters = useSelector(
    (state) => state.modalHandler.packingParameters
  );

  const isTruck =
    useSelector((state) => state.modalHandler.ContainerTypeData) === "Truck";

  const containerSelectionType = useSelector(
    (state) => state.modalHandler.containerSelectionType
  );

  const truckSelectionType = useSelector(
    (state) => state.modalHandler.truckSelectionType
  );
  const containerDetails = isTruck
    ? truckSelectionType?.container
    : containerSelectionType?.container;

  const MAX_LENGTH = [...containerDetails].sort(
    (a, b) => b.length - a.length
  )[0].length;
  const MAX_WIDTH = [...containerDetails].sort((a, b) => b.width - a.width)[0]
    .width;
  const MAX_HEIGHT = [...containerDetails].sort(
    (a, b) => b.height - a.height
  )[0].height;
  const MAX_WEIGHT = [...containerDetails].sort(
    (a, b) => b.weight - a.weight
  )[0].weight;

  const deleteCargoHandler = (id) => {
    let data = [...ImportedExcelData];
    let filterData = data.filter((i) => i.id !== id);
    dispatch(setImportedExcelData(filterData));

    // delete step 5 packing parameters
    let packingParametersData = [...packingParameters];
    let packingParametersFilter = packingParametersData.filter(
      (i) => i.id !== id
    );
    dispatch(setPackingParameters(packingParametersFilter));
  };

  const cargoSettingHandler = (item) => {
    dispatch(
      setUpdateCargoPacking({
        itemId: item.id,
        itemType: item.cargoType,
        measurementUnit: item.measurementUnit,
        weightUnit: item.weightUnit,
      })
    );
  };
  const [cargoColor, setCargoColor] = useState({});
  const [initialValue, setInitialValue] = useState("");
  const [quantity, setQuantity] = useState({});

  const updateCargoColor = (id, field, value) => {
    dispatch(
      setUpdateCargoPackingByField({
        id: id,
        field: field,
        value: value,
      })
    );
  };
  const updateCargoName = (id, field, value) => {
    dispatch(
      setUpdateCargoPackingByField({
        id,
        field,
        value,
      })
    );
  };

  return ImportedExcelData.map((item, index) => {
    return {
      type: getIcon(item.cargoType),
      productname: (
        <TempInput
          item={item}
          setInitialValue={setInitialValue}
          updateCargoName={updateCargoName}
        />
      ),
      length: (
        <SelectOption
          defaultValue={item.measurementUnit}
          inputValue={item.length}
          field="length"
          maximum={MAX_LENGTH}
          quantity={item.quantity}
          id={item.id}
          inputTypeValue="number"
        />
      ),
      diameter: (
        <SelectOption
          defaultValue={item.measurementUnit}
          inputValue={item.width}
          field="width"
          maximum={MAX_WIDTH}
          quantity={item.quantity}
          id={item.id}
          inputTypeValue="number"
        />
      ),
      height: (
        <SelectOption
          defaultValue={item.measurementUnit}
          inputValue={item.height}
          field="height"
          maximum={MAX_HEIGHT}
          quantity={item.quantity}
          id={item.id}
          inputTypeValue="number"
        />
      ),
      weight: (
        <SelectOption
          defaultValue={item.weightUnit}
          inputValue={item.weight}
          field="weight"
          maximum={MAX_WEIGHT}
          quantity={item.quantity}
          id={item.id}
          inputTypeValue="number"
        />
      ),
      quantity: (
        <>
          <span className="quantity-picker">
            <button
              className="quantity-modifier modifier-left"
              onClick={(e) => {
                e.preventDefault();
                if (item.quantity > 0) {
                setQuantity({ ...quantity, [item.id]: item.quantity - 1 });
                  dispatch(
                    setUpdateCargoPackingByField({
                      id: item.id,
                      field: "quantity",
                      value: item.quantity - 1,
                    })
                  );
                }
              }}
            >
              –
            </button>
            <input
              className="quantity-display"
              type="number"
              style={{ width: "100%" }}
              value={quantity[item.id] || item.quantity}
              onChange={(e) => {
                e.preventDefault();
                if (e.target.valueAsNumber) {
                setQuantity({ ...quantity, [item.id]: e.target.valueAsNumber });
                  dispatch(
                    setUpdateCargoPackingByField({
                      id: item.id,
                      field: "quantity",
                      value: e.target.valueAsNumber,
                    })
                  );
                }
              }}
            />
            <button
              className="quantity-modifier modifier-right"
              onClick={(e) => {
                e.preventDefault();
                setQuantity({ ...quantity, [item.id]: item.quantity + 1 });
                // if (item.quantity <= MAX_WEIGHT / item.weight) {
                dispatch(
                  setUpdateCargoPackingByField({
                    id: item.id,
                    field: "quantity",
                    value: item.quantity + 1,
                  })
                );
                // }
              }}
            >
              ＋
            </button>
          </span>
          {/* {item.quantity > MAX_WEIGHT / item.weight ? (
            <span
              style={{
                color: "red",
                fontSize: "8px",
                position: "absolute",
              }}
            >
              Too many cargo
            </span>
          ) : null} */}
        </>
      ),
      color: (
        <input
          type="color"
          className="colortype"
          id="color"
          name="color"
          value={cargoColor[item.id] || item.color || "#00B712"}
          onChange={(event) => {
            setCargoColor({ ...cargoColor, [item.id]: event.target.value });
            updateCargoColor(item.id, "color", event.target.value);
          }}
        />
      ),
      action: (
        <Stack direction="row" spacing={1.75} className="mt-6">
          <Button
            className="setting-button"
            onClick={() => {
              dispatch(setCargoParkingModal(true));
              cargoSettingHandler(item);
            }}
          >
            <SVG.SettingIcon />
          </Button>
          <Button
            className="delete-button"
            onClick={() => deleteCargoHandler(item.id)}
          >
            <SVG.DeleteIcon />
          </Button>
        </Stack>
      ),
    };
  });
};
