import { styled } from "@mui/system";

const CardStyled = styled("div")(({ theme }) => ({
  "& .MuiCard-root": {
    boxShadow: "0px 2px 40px rgba(0, 0, 0, 0.05)",
    border: "1px solid transparent",

    borderRadius: "20px",
  },
  "& .MuiCardContent-root": {
    padding: "16px 20px",
  },
  "& .MuiIconButton-root": {
    background: "#C3CAD5",
    color: "#fff",
    width: "28px",
    height: "28px",
  },

  "& .MuiTypography-root": {
    fontSize: "24px",
    fontWeight: "600",
    color: "#333333",
    lineHeight: "31px",
    "@media (max-width:992px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  "& .containerBox": {
    boxShadow: "none",
    background: "#F7F9FA",
    borderRadius: "15px",
  },
  "& .containerBox .MuiCardContent-root": {
    padding: "17px 25px",
  },
  "& .container-color": {
    color: "#1F4970",
    fontSize: "16px",
    fontWeight: "500",

    fontFamily: `Inter`,
    "@media (max-width:992px)": {
      textAlign: "center",
    },
  },
  "& .selectedBox": {
    boxShadow: " 5px 5px 15px rgba(1, 88, 255, 0.2)",
    border: "1px solid #009EE3",
  },
  "& .selectedBox .MuiIconButton-root": {
    background: "#1F4970",
  },
  "& .selectedcontainer": {
    background: "#E6E8FF",
  },
  "& .selectedBox .container-color": {
    color: theme.palette.primary.main,
  },
  "& .export-btn": {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    fontWeight: 400,
    borderRadius: "35px",
    padding: "6px 15px",
    "@media (max-width:992px)": {
      fontSize: "12px",
    },
  },
  "& .export-btn svg": {
    marginRight: "15px",
  },
  "& .add-container-btn": {
    background: " #29DBBA",
    borderRadius: "15px",
    color: "#FFFFFF",
    fontSize: "17px",
    fontWeight: "500",
    height: "48px",
  },
  "& .add-container-btn:hover": {
    background: " #29DBBA",
  },
  "& .quantity-picker": {
    display: "flex",
    alignItems: "center",
    borderRadius: "15px",
    border: "1px solid #DAE3EB",
    overflow: "hidden",
    height: "30px",
  },
  "& .quantity-display": {
    padding: "0px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#666666",
  },
  "& .quantity-modifier": {
    background: "rgba(49, 118, 250, 0.2)",
    color: "#2B3A90",
    border: "0px",
    fontSize: "12px",
  },
  "& .paramenter-list label": {
    display: "inline-block",
    width: "20%",
    fontSize: "18px",
    fontWeight: 400,
    color: "#707D88",
    lineHeight: "31px",
    "@media (max-width:992px)": {
      width: "33.333%",
    },
  },
  "& .paramenter-list big": {
    display: "inline-block",

    fontSize: "18px",
    fontWeight: 600,
    color: "#333333",
    lineHeight: "31px",
  },
}));

export default CardStyled;
