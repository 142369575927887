import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { SVG } from "../../assert/svg";
import { Link, useNavigate } from "react-router-dom";
import PurpleButtonComponent from "../../components/button/purpleButton";
import OutLinePurpleButton from "../../components/button/OutlinePurpleButton";
import HeaderMainStyle from "./headerMainStyle";
import { useDispatch, useSelector } from "react-redux";
import { setActiveHeader, setCalculate } from "../../reducers/modalSlice";
import { Divider, Drawer, List } from "@mui/material";
import { Container } from "react-bootstrap";
const navItems = [
  {
    id: 1,
    title: "Home",
    url: "/",
    section: "home",
  },
  {
    title: "What We Do",
    url: "/",
    section: "fullyManage",
    id: 2,
  },
  {
    title: "FAQ's",
    url: "/",
    id: 3,
    section: "faq",
  },
];

function HeaderMain(props) {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();
  const activeHeader = useSelector((state) => state.modalHandler.activeHeader);

  const handleMoveToSection = (id) => {
    dispatch(setActiveHeader(id));
    // setSelectedSection(id);
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
      // setTimeout(() => {
      //   setSelectedSection(id);
      // }, 100);
    }
  };

  console.log("sel --- ", activeHeader);
  const drawerWidth = 270;
  const drawer = (
    <Box onClick={handleDrawerToggle} className="drawer_menu_div">
      <Typography variant="h6" sx={{ my: 2 }}>
        <SVG.Logo />
      </Typography>
      <Divider />
      <List sx={{ px: 2 }} className="side_menu">
        {navItems.map((item, i) => (
          <Link
            onClick={() => {
              setTimeout(() => {
                handleMoveToSection(item.section);
              }, 100);
            }}
            to={item.url}
            key={i}
            className={`header-menu ${
              item.section === activeHeader ? "active" : ""
            }`}
          >
            {item.title}
          </Link>
        ))}
        <PurpleButtonComponent
          className="drawer-btn"
          text="Container  Calculator"
          onClick={(e) => {
            dispatch(setActiveHeader(""));
            dispatch(setCalculate(true));
            navigate("/load-calculator");
          }}
        />
      </List>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <HeaderMainStyle>
      <>
        <AppBar
          component="nav"
          style={{
            zIndex: "9",
            boxShadow: "none",
            // background: "rgb(248,249,255)",
          }}
        >
          <Container>
            <Toolbar className="main_header_new">
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                className="header_logo"
                variant="h6"
                component="div"
                onClick={(e) => navigate("/")}
                style={{ cursor: "pointer" }}
                //   sx={{ flexGrow: 1, display: { sm: "block" } }}
              >
                <SVG.Logo />
              </Typography>

              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {navItems.map((item, i) => (
                  <Link
                    onClick={() => {
                      setTimeout(() => {
                        handleMoveToSection(item.section);
                      }, 100);
                    }}
                    to={item.url}
                    key={i}
                    className={`header-menu ${
                      item.section === activeHeader ? "active" : ""
                    }`}
                  >
                    {item.title}
                  </Link>
                ))}
              </Box>
              <Box className="header_btn_section">
                {" "}
                <OutLinePurpleButton
                  text="Get Quote"
                  className="me-2 border_btn_head"
                  onClick={(e) => {
                    navigate("/");
                    handleMoveToSection("quote");
                  }}
                />
                <PurpleButtonComponent
                  className="big-btn-header"
                  text="Container Calculator"
                  onClick={(e) => {
                    dispatch(setActiveHeader(""));
                    dispatch(setCalculate(true));
                    navigate("/load-calculator");
                  }}
                />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </>
    </HeaderMainStyle>
  );
}

export default HeaderMain;
