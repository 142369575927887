import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SVG } from "../../assert/svg";
import { v4 as uuidv4 } from "uuid";
import {
  setUpdateCargoPacking,
  setImportedExcelData,
} from "../../reducers/modalSlice";
import { BlueButtons } from "../button";
import ExportStyled from "../container-type/export-content/style";
import { getDefaultProperties } from "../../utils/defaultValues";
import { changeAllValueUnits } from "../../utils/calculation";
import { toast } from "react-toastify";

const CargoSpecifications = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { updateCargoPacking, ImportedExcelData, measurementUnit } =
    useSelector((state) => state.modalHandler);

  const [selectedValue, setSelectedValue] = React.useState(
    updateCargoPacking.itemType
  );
  // const [measurementUnit, setMeasurementUnit] = React.useState(
  //   updateCargoPacking.measurementUnit
  // );
  // const [weightUnit, setWeightUnit] = React.useState(
  //  updateCargoPacking.weightUnit
  // );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    dispatch(
      setUpdateCargoPacking({
        ...updateCargoPacking,
        itemType: event.target.value,
      })
    );
  };

  // console.log("result", updateCargoPacking);
  // it is used for create or update cargo
  const updateItemHandler = () => {
    // if (!measurementUnit || !selectedValue)
    //   return toast.warn("Please Select Units and Type!");
    if (updateCargoPacking.itemId) {
      let filterData = ImportedExcelData.map((data) => {
        if (data.id === updateCargoPacking.itemId) {
          return {
            ...data,
            cargoType: updateCargoPacking.itemType,
            // ...getDefaultProperties(
            //   updateCargoPacking.itemType,
            //   measurementUnit
            // ),
          };
        }
        return data;
      });
      dispatch(setImportedExcelData(filterData));
    } else {
      dispatch(
        setImportedExcelData([
          ...ImportedExcelData,
          {
            cargoType: updateCargoPacking.itemType,
            id: uuidv4(),
            ...getDefaultProperties(
              updateCargoPacking.itemType,
              measurementUnit
            ),
          },
        ])
      );
    }
    // changeAllValueUnits(measurementUnit, dispatch);
    handleClose();
    // console.log(ImportedExcelData);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const checkedList = [
    {
      item: "box",
      icon: <SVG.CubeIcon />,
      title: "Boxes",
    },
    {
      item: "big-bag",
      icon: <SVG.BigBasketIcon />,
      title: "BigBags",
    },
    {
      item: "barrel",
      icon: <SVG.BarrelIcon />,
      title: "Barrels",
    },
    {
      item: "sack",
      icon: <SVG.SacksIcon />,
      title: "Sacks",
    },
  ];

  // const handleUnitChange = (mUnit, wUnit) => {
  //   setMeasurementUnit(mUnit);
  //   setWeightUnit(wUnit);
  // };

  return (
    <ExportStyled>
      <div className="export-content">
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          className="border-bottom"
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 3.5 }}
            alignItems={{ xs: "start", sm: "center" }}
          >
            <h2>Cargo Specifications</h2>
            {/* <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                className={
                  measurementUnit === "mm" && weightUnit === "kg"
                    ? "pallet-selected"
                    : ""
                }
                value="female"
                control={
                  <Radio
                    disableRipple={true}
                    checked={measurementUnit === "mm" && weightUnit === "kg"}
                    onClick={() => handleUnitChange("mm", "kg")}
                  />
                }
                label="mm/kg"
                labelPlacement="start"
              />
              <FormControlLabel
                className={
                  measurementUnit === "inch" && weightUnit === "lbs"
                    ? "pallet-selected"
                    : ""
                }
                value="male"
                control={
                  <Radio
                    disableRipple={true}
                    checked={measurementUnit === "inch" && weightUnit === "lbs"}
                    onClick={() => handleUnitChange("inch", "lbs")}
                  />
                }
                label="inch/lbs"
                labelPlacement="start"
              />
            </RadioGroup> */}
          </Stack>
          <IconButton disableRipple={true} onClick={handleClose}>
            <SVG.CloseIcon />
          </IconButton>
        </Stack>

        <Box sx={{ py: 5, px: 5.375 }}>
          <Grid container spacing={4.5}>
            {checkedList.map((items, index) => (
              <Grid item lg={3} xs={12} key={index}>
                <Box
                  component="label"
                  className={`card ${
                    selectedValue !== items.item ? "" : "selected"
                  }`}
                >
                  <div className="checkbox">
                    <Radio
                      {...controlProps(items.item)}
                      checkedIcon={<SVG.CheckedIcon />}
                      icon={<SVG.UncheckedIcon />}
                      disableRipple={true}
                    />
                  </div>
                  {items.icon}
                  <h6>{items.title}</h6>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2.5 }}
            sx={{ mt: 5 }}
            justifyContent="end"
          >
            <BlueButtons
              text="Add"
              onClick={() => {
                updateItemHandler();
              }}
            />
          </Stack>
        </Box>
      </div>
    </ExportStyled>
  );
};
export default CargoSpecifications;
