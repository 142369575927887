import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SVG } from "../../assert/svg";
import CardStyled from "../container-type/style";
import containerTrack from "../../assert/images/container_packing.png";
import containerCargo from "../../assert/images/container_pallet.png";
import ModalView from "../modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PalletsDimensions from "./select-pallets-dimensions";
import { Box } from "@mui/system";
import TableStyled from "../cargo-packaging/styles";

import { PALLET_COLUMN_DATA, PALLET_ROW_DATA } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { setCargoModal, setLoadingType } from "../../reducers/modalSlice";
import { toast } from "react-toastify";

const LodingType = ({ handleSelectedContainer, selectedContainer }) => {
  // redux dispatcher and selector
  const dispatch = useDispatch();

  const cargoModal = useSelector((state) => state.modalHandler.cargoModal);
  const loadingType = useSelector((state) => state.modalHandler.loadingType);

  const [selectedValue, setSelectedValue] = useState("");
  const [isPalletSettingUpdate, setIsPalletSettingUpdate] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const ImportedExcelData = useSelector(
    (state) => state.modalHandler.ImportedExcelData
  );

  const measurementUnit = useSelector(
    (state) => state.modalHandler.measurementUnit
  );

  const defaultPalletData =
    measurementUnit === "mm"
      ? {
          loadingType: "pallet",
          pallet_info: {
            palletType: "1",
            length: 1200,
            width: 800,
            height: 100,
            payload: 10,
            max_loading_height_level: 2000,
            thickness_of_separating_plate: 50,
            measurementUnit: "mm",
            weightUnit: "kg",
          },
        }
      : {
          loadingType: "pallet",
          pallet_info: {
            palletType: "1",
            length: 47,
            width: 31,
            height: 4,
            payload: 22,
            max_loading_height_level: 79,
            thickness_of_separating_plate: 2,
            measurementUnit: "inch",
            weightUnit: "lbs",
          },
        };

  useEffect(() => {
    if (ImportedExcelData.length > 0 && selectedValue === "")
      setSelectedValue("direct");
  }, [ImportedExcelData, selectedValue]);

  const handleClickOpen = () => {
    if (ImportedExcelData.length > 0) {
      handleSelectedContainer("loddingtype");
      setSelectedValue("pallet");
      dispatch(setLoadingType(defaultPalletData));
    } else {
      toast.warn("Please Add Cargo!")
    }
  };

  const handleClose = () => {
    dispatch(setCargoModal(false));
  };

  return (
    <CardStyled>
      <Card
        className={selectedContainer === "loddingtype" ? "selectedBox" : ""}
      >
        <CardContent>
          <Stack
            spacing={1.875}
            direction="row"
            alignItems="center"
            sx={{ mb: 3.75 }}
          >
            <IconButton disableRipple>
              <SVG.BackArrowIcon />
            </IconButton>

            <Typography variant="h1">Step 3: Loading Type</Typography>
          </Stack>
          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <Card
                className={`containerBox ${
                  selectedValue === "direct" ? "selectedcontainer" : ""
                }`}
              >
                <CardContent>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    component="label"
                    className="container-color"
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      alignItems="center"
                    >
                      <img alt="" src={containerTrack} />{" "}
                      <span>Cargo stuffing directly into container.</span>
                    </Stack>
                    <Radio
                      disableRipple={true}
                      checked={selectedValue === "direct"}
                      onClick={() => {
                        if (ImportedExcelData.length > 0) {
                          handleSelectedContainer("loddingtype");
                          setSelectedValue("direct");
                          dispatch(setLoadingType({ loadingType: "direct" }));
                        } else {
                          toast.warn("Please Add Cargo!")
                        }
                      }}
                      checkedIcon={<SVG.CheckedIcon />}
                      icon={<SVG.UncheckedIcon />}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={6} xs={12}>
              <Card
                className={`containerBox ${
                  selectedValue === "pallet" ? "selectedcontainer" : ""
                }`}
              >
                <CardContent>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    component="label"
                    className="container-color"
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      alignItems="center"
                    >
                      <img alt="" src={containerCargo} />{" "}
                      <span>Pre-shipment cargo palletizing.</span>
                    </Stack>
                    <Radio
                      disableRipple={true}
                      checkedIcon={<SVG.CheckedIcon />}
                      icon={<SVG.UncheckedIcon />}
                      checked={selectedValue === "pallet"}
                      onClick={() => {
                        handleClickOpen();
                      }}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {selectedValue === "pallet" ? (
            <Box sx={{ mt: 2.5 }}>
              <Typography variant="h2" sx={{ fontWeight: "400 !important" }}>
                Pallet Info
              </Typography>
              <TableStyled>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        {PALLET_COLUMN_DATA.map((data, i) => (
                          <TableCell key={i} sx={{ minWidth: data.width }}>
                            {data.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(PALLET_ROW_DATA(loadingType) || []).map((row, i) => (
                        <TableRow key={i}>
                          {Object.keys(row).map((key, i) => {
                            return <TableCell key={i}>{row[key]}</TableCell>;
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableStyled>
            </Box>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
      <ModalView
        open={cargoModal}
        fullScreen={fullScreen}
        handleClose={handleClose}
        maxWidth="lg"
        content={<PalletsDimensions handleClose={handleClose} />}
        setIsPalletSettingUpdate={setIsPalletSettingUpdate}
        isPalletSettingUpdate={isPalletSettingUpdate}
      />
    </CardStyled>
  );
};
export default LodingType;
