import React, { useState } from "react";
import styles from "../../admin.module.css";
import { Link } from "react-router-dom";
import { SVG } from "../../../assert/svg";

export default function Sidebar() {
  const [activeMenu, setActiveMenu] = useState(1);

  const defaultMenu = [
    {
      name: "Manage Usage Stats",
      link: "/admin/usage-stats",
      id: 1,
    },
    {
      name: "Manage SMTP Settings",
      link: "/admin/manage-smtp-settings",
      id: 2,
    },
  ];

  return (
    <>
      <div className={styles.sidebar_div}>
        <div className={styles.sidebar_logo}>
          <SVG.Logo />
        </div>
        <div className={styles.side_menu_list}>
          <ul className={styles.main_list_sidebar}>
            {defaultMenu.map((item) => {
              return (
                <li
                  className={
                    activeMenu === item.id ? styles.active : styles.inactive
                  }
                  
                >
                  <Link onClick={() => setActiveMenu(item.id)} to={item.link}>{item.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
