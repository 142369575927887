import { styled } from "@mui/system";

export const CubeCardStyled = styled("div")(() => ({
  "& .MuiCard-root": {
    boxShadow: "0px 2px 40px rgba(0, 0, 0, 0.05)",
    border: "1px solid transparent",

    borderRadius: "20px",
  },
  "& .MuiCardContent-root": {
    padding: "16px 0px",
  },
  "& .MuiTypography-root": {
    fontSize: "24px",
    fontWeight: 600,
    textAlign: "center",
    TextTransform: "uppercase",
    color: "#333333",
    padding: "0px 16px",
    "@media (max-width:992px)": {
      fontSize: "16px",
    },
  },
  "& .ms-auto": {
    float: "right",
    cursor: "pointer",
  },
  "& .cube-container": {
    textAlign: "center",
    padding: "25px",
  },
  "& .MuiListItem-root": {
    justifyContent: "center",
    color: "#707D88",
    fontSize: "16px",
    lineHeight: "25px",
    fontWeight: "400",
  },
  "& .unit": {
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "center",
    color: "#2B3A90",
    margin: "0px",
    "@media (max-width:992px)": {
      fontSize: "16px",
    },
  },
  "& .MuiDivider-root": {
    borderColor: "#EAECEF",
    opacity: "0.5",
  },
  "& .load-total": {
    margin: "0px 0px 21px",
    padding: "0px 10px 0px 18px",
    listStyle: "none",
  },

  "& .load-total strong": {
    display: "inline-block",
    width: "87px",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "36px",
    "@media (max-width:992px)": {
      width: "100%",
      fontSize: "14px",
    },
  },
  "& .load-total span": {
    display: "inline-block",
    color: "#707D88",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "36px",
    "@media (max-width:992px)": {
      fontSize: "14px",
    },
  },
  "& .load-table th": {
    textAlign: "left",
    color: "#707D88",
    fontSize: "17px",
    fontWeight: 400,
    padding: "8px 0px",
  },
  "& .load-table svg": {
    width: "28px",
    height: "28px",
    color: "#9FB1C1",
  },
  "& .load-table span": {
    fontSize: "15px",
    color: "#333333",
    fontWeight: 500,
  },
  "& .load-table td": {
    fontSize: "15px",
    fontWeight: 500,
    color: "#333333",
    padding: "8px 0px",
  },
  "& .load-table-view": {
    padding: "0px 10px 0px 18px",
  },
  "& .justify-content-start": {
    justifyContent: "start",
    paddingLeft: "0px",
  },
  "& .fullscreen-font": {
    padding: "0px",
  },
  "& .fullscreen-font strong": {
    fontSize: "14px",
  },
  "& .fullscreen-font span": {
    fontSize: "14px",
  },
  "& .fullscreen-table-view": {
    padding: "0px",
  },
}));

export const loaderStyles = {
  height: "100%",
  width: "100%",
  position: "fixed",
  zIndex: 99999,
  top: "0",
  left: "0",
  backgroundColor: "#ffffff20",
  overflowX: "hidden",
  transition: "0.5s",
  backdropFilter: "blur(5px)",
  placeItems: "center",
  alignContent: "center",
  gap: '1rem'
};
