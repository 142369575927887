import { createSlice } from "@reduxjs/toolkit";
import { measurementConversion, weightConversion } from "../utils/calculation";
import {
  defaultContainerData,
  defaultData,
  defaultTruckData,
} from "../utils/defaultValues";

const initialState = {
  cargoModal: false,
  CargoParkingModal: false,
  ContainerTypeData: "Container",
  ImportedExcelData: defaultData,
  updateCargoPacking: [],
  loadingType: [],
  selectedPalletType: [],
  containerSelectionType: defaultContainerData,
  truckSelectionType: defaultTruckData,
  updateContainerId: [],
  packingParameters: [],
  remainingContainerWeight: 0,
  remainingCargo: [],
  measurementUnit: "mm",
  calculate: true,
  packagingInputError: false,
  activeHeader: "home"
};

export const modalSlice = createSlice({
  name: "modalSlice",
  initialState,
  reducers: {
    setCargoModal: (state, action) => {
      state.cargoModal = action.payload;
    },

    setCargoParkingModal: (state, action) => {
      state.CargoParkingModal = action.payload;
    },

    setContainerTypeData: (state, action) => {
      state.ContainerTypeData = action.payload;
    },

    setContainerSelectionModal: (state, action) => {
      state.ContainerSelectionModal = action.payload;
    },
    setImportedExcelData: (state, action) => {
      state.ImportedExcelData = action.payload;
    },
    setUpdateCargoPacking: (state, action) => {
      state.updateCargoPacking = action.payload;
    },
    setLoadingType: (state, action) => {
      state.loadingType = action.payload;
    },
    setLoadingTypePalletValues: (state, action) => {
      state.loadingType = {
        loadingType: action.payload.loadingType,
        pallet_info: {
          ...state.loadingType.pallet_info,
          [action.payload.field]: action.payload.value,
        },
      };
    },
    setUpdateContainerId: (state, action) => {
      state.updateContainerId = action.payload;
    },

    setUpdateCargoPackingByField: (state, action) => {
      const { id, field, value } = action.payload;
      state.ImportedExcelData = state.ImportedExcelData.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            [field]: value,
          };
        }
        return data;
      });
    },
    setSelectedPalletType: (state, action) => {
      state.selectedPalletType = action.payload;
    },
    setContainerSelectionType: (state, action) => {
      state.containerSelectionType = action.payload;
    },
    setTruckSelectionType: (state, action) => {
      state.truckSelectionType = action.payload;
    },
    setContainerSelectionTypeNew: (state, action) => {
      state.containerSelectionType = {
        type: action.payload.type,
        container: [
          ...state.containerSelectionType.container,
          action.payload.container,
        ],
      };
    },
    setTruckSelectionTypeNew: (state, action) => {
      state.truckSelectionType = {
        type: action.payload.type,
        container: [
          ...state.truckSelectionType.container,
          action.payload.container,
        ],
      };
    },
    setUpdateContainerByField: (state, action) => {
      const { id, field, value } = action.payload;
      let stateToModify =
        state.ContainerTypeData === "Container"
          ? state.containerSelectionType
          : state.truckSelectionType;
      if (stateToModify.hasOwnProperty("container")) {
        stateToModify.container = stateToModify.container.map((data) => {
          if (data.id === id) {
            return {
              ...data,
              [field]: value,
            };
          }

          return data;
        });
      }
    },
    setPackingParameters: (state, action) => {
      state.packingParameters = action.payload;
    },
    setRemainingContainerWeight: (state, action) => {
      state.remainingContainerWeight = action.payload;
    },
    setRemainingCargo: (state, action) => {
      state.remainingCargo = action.payload;
    },
    changeAllUnits: (state, action) => {
      const { measurementUnit, weightUnit } = action.payload;

      if (state.measurementUnit !== measurementUnit) {
        state.ImportedExcelData = state.ImportedExcelData.map((data) => {
          return {
            ...data,
            length: measurementConversion(data.length, measurementUnit),
            width: measurementConversion(data.width, measurementUnit),
            height: measurementConversion(data.height, measurementUnit),
            weight: weightConversion(data.weight, weightUnit),
            measurementUnit: measurementUnit,
            weightUnit: weightUnit,
          };
        });

        if (state.loadingType.hasOwnProperty("pallet_info")) {
          let data = state.loadingType.pallet_info;
          state.loadingType = {
            ...state.loadingType,
            pallet_info: {
              ...data,
              length: measurementConversion(data.length, measurementUnit),
              width: measurementConversion(data.width, measurementUnit),
              height: measurementConversion(data.height, measurementUnit),
              payload: weightConversion(data.payload, weightUnit),
              max_loading_height_level: measurementConversion(
                data.max_loading_height_level,
                measurementUnit
              ),
              thickness_of_separating_plate: measurementConversion(
                data.thickness_of_separating_plate,
                measurementUnit
              ),
              measurementUnit: measurementUnit,
              weightUnit: weightUnit,
            },
          };
        }

        state.containerSelectionType = {
          ...state.containerSelectionType,
          container: state.containerSelectionType.container.map((data) => {
            return {
              ...data,
              length: measurementConversion(data.length, measurementUnit),
              width: measurementConversion(data.width, measurementUnit),
              height: measurementConversion(data.height, measurementUnit),
              weight: weightConversion(data.weight, weightUnit),
              volume: measurementConversion(
                data.volume,
                measurementUnit
              ),
              measurementUnit: measurementUnit,
              weightUnit: weightUnit,
            };
          }),
        };

        state.truckSelectionType = {
          ...state.truckSelectionType,
          container: state.truckSelectionType.container.map((data) => {
            return {
              ...data,
              length: measurementConversion(data.length, measurementUnit),
              width: measurementConversion(data.width, measurementUnit),
              height: measurementConversion(data.height, measurementUnit),
              weight: weightConversion(data.weight, weightUnit),
              volume: measurementConversion(
                data.volume ,
                measurementUnit
              ),
              measurementUnit: measurementUnit,
              weightUnit: weightUnit,
            };
          }),
        };

        state.measurementUnit = measurementUnit;
      }
    },
    setCalculate: (state, action) => {
      state.calculate = action.payload;
      // state.calculate = !state.calculate;
    },
    setPackagingInputError: (state, action) => {
      state.packagingInputError = action.payload;
    },
    setActiveHeader: (state, action) => {
      state.activeHeader = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setCargoModal,
  setCargoParkingModal,
  setContainerTypeData,
  setContainerSelectionModal,
  setImportedExcelData,
  setContainerSelectionTypeNew,
  setUpdateCargoPacking,
  setUpdateCargoPackingByField,
  setLoadingType,
  setLoadingTypePalletValues,
  setSelectedPalletType,
  setContainerSelectionType,
  setUpdateContainerId,
  setUpdateContainerByField,
  setPackingParameters,
  setRemainingContainerWeight,
  setRemainingCargo,
  setTruckSelectionType,
  setTruckSelectionTypeNew,
  changeAllUnits,
  setCalculate,
  setPackagingInputError,
  setActiveHeader,
} = modalSlice.actions;

export default modalSlice.reducer;
