import { ReactComponent as BackArrowIcon } from "./back-arrow.svg";
import { ReactComponent as ContainerIcon } from "./container-icon.svg";
import { ReactComponent as TrackIcon } from "./track.svg";
import { ReactComponent as UncheckedIcon } from "./unchecked.svg";
import { ReactComponent as CheckedIcon } from "./checked-icon.svg";
import { ReactComponent as DwonloadIcon } from "./download.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as UploadIcon } from "./upload.svg";
import { ReactComponent as BasketIcon } from "./basket.svg";
import { ReactComponent as DownArrowIcon } from "./downarrow.svg";
import { ReactComponent as SettingIcon } from "./setting.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as PlushIcon } from "./plush.svg";
import { ReactComponent as CubeIcon } from "./cube.svg";
import { ReactComponent as BarrelIcon } from "./barrels.svg";
import { ReactComponent as SacksIcon } from "./sacks.svg";
import { ReactComponent as BigBasketIcon } from "./bigbasket.svg";
import { ReactComponent as HeightIcon } from "./height.svg";
import { ReactComponent as LengthIcon } from "./length.svg";
import { ReactComponent as WidthIcon } from "./width.svg";
import { ReactComponent as FullScreenIcon } from "./fullscreen.svg";
import { ReactComponent as CylinderIcon } from "./cylinder.svg";
import { ReactComponent as SackHeightIcon } from "./sack-height.svg";
import { ReactComponent as SackLengthIcon } from "./sack-length.svg";
import { ReactComponent as SackWidthIcon } from "./sack-width.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as wallaby } from "./partnerOne.svg";
import { ReactComponent as Shopify } from "./partnerTwo.svg";
import { ReactComponent as colibiri } from "./partnerTh.svg";
import { ReactComponent as Medify } from "./partnerFour.svg";
import { ReactComponent as Anything } from "./partnerFive.svg";
import { ReactComponent as Amazon } from "./partnerS.svg";
import { ReactComponent as AmazonLogo } from "./amazonLogo.svg";
import { ReactComponent as ShopifyLogo } from "./shopifyLogo.svg";
import { ReactComponent as StoreLogo } from "./storeLogo.svg";
import { ReactComponent as facebookIcon } from "./facebookIcon.svg";
import { ReactComponent as twitterIcon } from "./twitterIcon.svg";
import { ReactComponent as linkedinIcon } from "./LinkedInIcon.svg";

import { ReactComponent as call } from "./call.svg";
import { ReactComponent as dollar } from "./dollar.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as timer } from "./timer.svg";

import { ReactComponent as purse } from "./purse.svg";

import { ReactComponent as experty } from "./experty.svg";
import { ReactComponent as Storage } from "./storage.svg";

import { ReactComponent as Service } from "./service.svg";

import { ReactComponent as Transport } from "./transport.svg";
import { ReactComponent as Logistic } from "./logistic.svg";
import { ReactComponent as Envelop } from "./envelop.svg";

export const SVG = {
  BackArrowIcon,
  ContainerIcon,
  TrackIcon,
  UncheckedIcon,
  CheckedIcon,
  DwonloadIcon,
  CloseIcon,
  UploadIcon,
  BasketIcon,
  DownArrowIcon,
  SettingIcon,
  DeleteIcon,
  PlushIcon,
  CubeIcon,
  BarrelIcon,
  SacksIcon,
  BigBasketIcon,
  HeightIcon,
  LengthIcon,
  WidthIcon,
  FullScreenIcon,
  CylinderIcon,
  SackHeightIcon,
  SackLengthIcon,
  SackWidthIcon,
  Logo,
  wallaby,
  Shopify,
  colibiri,
  Medify,
  Anything,
  Amazon,
  AmazonLogo,
  ShopifyLogo,
  StoreLogo,
  facebookIcon,
  twitterIcon,
  linkedinIcon,
  call,
  dollar,
  check,
  timer,
  purse,
  experty,
  Storage,
  Transport,
  Service,
  Logistic,
  Envelop,
};
