import { styled } from "@mui/system";

const HeaderStyle = styled("div")(({ theme }) => ({
  "& .MuiAppBar-root": {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 20px 3px rgba(0, 0, 0, 0.05)",
    padding: "14px 0",
  },
  "& .header-menu": {
    color: "#000024",
    fontSize: "15px",
    fontWeight: 600,
    textDecoration: "none",
    padding: "0px 5px",
    marginRight: "85px",
  },
  "& .active": {
    color: theme.palette.primary.main,
  },

  "& .MuiList-root": {
    padding: "0px 16px",
  },
}));

export default HeaderStyle;
