import "./App.css";
import "./fix.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./pages/home";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Box, ThemeProvider } from "@mui/material";
import defaultTheme from "./theme";

import AdminLayout from "./admin/component/layout/adminLayout";
import AdminLogin from "./admin/pages/login/AdminLogin";
import AdminForgotPassword from "./admin/pages/login/forgotPassword";
import ResetPassword from "./admin/pages/login/resetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./pages/landing-page";
import "../node_modules/aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";
import PrivacyPolicy from "./pages/privacyPolicy";
import ServiceAgreement from "./pages/serviceAgreement";
import LandingPageNew from "./pages/landing-page/landingPageNew";
import LandingPageFix from "./pages/landing-page/landingPageFix";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
        <Routes>
          <Route path="/landing-page-fix" element={<LandingPage />} />
          <Route path="/landing-page-new" element={<LandingPageNew />} />
          <Route path="/" element={<LandingPageFix />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/service-agreement" element={<ServiceAgreement />} />

          <Route path="/*" element={<Layout />} />
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route
            path="/admin/forgot-password"
            element={<AdminForgotPassword />}
          />
          <Route path="/admin/reset-password/:id" element={<ResetPassword />} />
        </Routes>
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
      />
    </div>
  );
}

export default App;
