import { styled } from "@mui/system";

const FooterStyle = styled("div")(({ theme }) => ({
  "& .MuiAppBar-root": {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 20px 3px rgba(0, 0, 0, 0.05)",
    padding: "14px 0",
  },
  "& .footer_list": {
    background:
      "linear-gradient(180deg, #F4F5FF -24.83%, rgba(255, 255, 255, 0) 321.93%);",
    padding: "50px 0 25px",
  },
  "& .footer_list h5": {
    fontSize: "16px",
    fontFamily: "'Inter', sans-serif",
    lineHeight: "19.36px",
    fontWeight: "700",
    color: "#151A1E",
  },
  "& .MuiList-root": {
    padding: "0",
  },
  "& .MuiList-root li": {
    paddingLeft: "0",
  },
  "& .MuiList-root li a": {
    color: "#151A1E",
    textDecoration: "none",
  },
  "& .social_login": { marginTop: "15px", backgroundColor: "transparent" },
  "& .social_login li": {
    backgroundColor: "#21368D",
    padding: "4px",
    width: "35px",
    height: "35px",
    borderRadius: "100%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  "& .bottom_footer": {
    marginTop: "30px",
    borderTop: "1px solid #E1E1E1",
    paddingTop: "25px",
    fontFamily: "'Inter', sans-serif",
  },
  "& .bottom_footer p": {
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
    lineHeight: "16.94px",
    color: "#151A1E",
  },
  "& .footer_link a": {
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
    lineHeight: "16.94px",
    color: "#151A1E",
  },
  "& .left_content_footer p": {
    fontSize: "13px",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#151A1E",
    fontFamily: "'Inter', sans-serif",
  },
}));

export default FooterStyle;
