import { getRandomColor } from "./calculation";
import { v4 as uuidv4 } from "uuid";
import * as THREE from "three";

export const SPECIAL_CHAR_REGEX = /^[0-9\b.]+$/;

export const defaultData = [
  {
    cargoType: "box",
    height: 500,
    length: 500,
    width: 500,
    name: "box",
    quantity: 100,
    weight: 50,
    id: uuidv4(),
    color: "#4D9AC7",
    measurementUnit: "mm",
    weightUnit: "kg",
  },
  // {
  //   cargoType: "barrel",
  //   height: 475,
  //   length: 735,
  //   width: 780,
  //   name: "barrel",
  //   quantity: 24,
  //   weight: 50,
  //   id: uuidv4(),
  //   color: getRandomColor(),
  //   measurementUnit: "mm",
  //   weightUnit: "kg",
  // },
  {
    cargoType: "sack",
    height: 500,
    length: 500,
    width: 500,
    name: "sack",
    quantity: 25,
    weight: 50,
    id: uuidv4(),
    color: "#2EA810",
    measurementUnit: "mm",
    weightUnit: "kg",
  },
  {
    cargoType: "big-bag",
    height: 500,
    length: 500,
    width: 500,
    name: "big-bag",
    quantity: 25,
    weight: 50,
    id: uuidv4(),
    color: "#9F085B",
    measurementUnit: "mm",
    weightUnit: "kg",
  },
];

export const defaultContainerDataAsUnit = (type, measurementUnit) => {
  const defaultValues = {
    Container: {
      mm: {
        type: "automatic",
        container: [
          {
            type: "1",
            id: uuidv4(),
            length: 5895,
            width: 2350,
            height: 2393,
            weight: 28200,
            qty: 1,
            volume: 5895 * 2350 * 2393,
            measurementUnit: "mm",
            weightUnit: "kg",
          },
          {
            type: "2",
            id: uuidv4(),
            length: 12032,
            width: 2350,
            height: 2393,
            weight: 28800,
            qty: 1,
            volume: 12032 * 2350 * 2393,
            measurementUnit: "mm",
            weightUnit: "kg",
          },
        ],
      },
      inch: {
        type: "automatic",
        container: [
          {
            type: "1",
            id: uuidv4(),
            length: 232,
            width: 92.5,
            height: 94.2,
            weight: 62170,
            qty: 1,
            volume: 232 * 92.5 * 94.2,
            measurementUnit: "inch",
            weightUnit: "lbs",
          },
          {
            type: "2",
            id: uuidv4(),
            length: 473.7,
            width: 92.5,
            height: 94.2,
            weight: 63493,
            qty: 1,
            volume: 473.7 * 92.5 * 94.2,
            measurementUnit: "inch",
            weightUnit: "lbs",
          },
        ],
      },
    },
    Truck: {
      mm: {
        type: "automatic",
        container: [
          {
            type: "Truck",
            id: uuidv4(),
            length: 5895,
            width: 2350,
            height: 2393,
            weight: 28200,
            qty: 1,
            volume: 5895 * 2350 * 2393,
            measurementUnit: "mm",
            weightUnit: "kg",
          },
          {
            type: "Truck",
            id: uuidv4(),
            length: 5895,
            width: 2350,
            height: 2393,
            weight: 28200,
            qty: 0,
            volume: 5895 * 2350 * 2393,
            measurementUnit: "mm",
            weightUnit: "kg",
          },
        ],
      },
      inch: {
        type: "automatic",
        container: [
          {
            type: "Truck",
            id: uuidv4(),
            length: 232,
            width: 92.5,
            height: 94.2,
            weight: 62170,
            qty: 1,
            volume: 232 * 92.5 * 94.2,
            measurementUnit: "inch",
            weightUnit: "lbs",
          },
          {
            type: "Truck",
            id: uuidv4(),
            length: 232,
            width: 92.5,
            height: 94.2,
            weight: 62170,
            qty: 0,
            volume: 232 * 92.5 * 94.2,
            measurementUnit: "inch",
            weightUnit: "lbs",
          },
        ],
      },
    },
  };

  return defaultValues[type][measurementUnit];
};

export const defaultContainerData = {
  type: "automatic",
  container: [
    {
      type: "1",
      id: uuidv4(),
      length: 5895,
      width: 2350,
      height: 2393,
      weight: 28200,
      qty: 1,
      volume: 5895 * 2350 * 2393,
      measurementUnit: "mm",
      weightUnit: "kg",
    },
    // {
    //   type: "2",
    //   id: uuidv4(),
    //   length: 12032,
    //   width: 2350,
    //   height: 2393,
    //   weight: 28800,
    //   qty: 1,
    //   volume: 12032 * 2350 * 2393,
    //   measurementUnit: "mm",
    //   weightUnit: "kg",
    // },
  ],
};

export const defaultTruckData = {
  type: "automatic",
  container: [
    {
      type: "Truck",
      id: uuidv4(),
      length: 5895,
      width: 2350,
      height: 2393,
      weight: 28200,
      qty: 1,
      volume: 5895 * 2350 * 2393,
      measurementUnit: "mm",
      weightUnit: "kg",
    },
    // {
    //   type: "Truck",
    //   id: uuidv4(),
    //   length: 5895,
    //   width: 2350,
    //   height: 2393,
    //   weight: 28200,
    //   qty: 0,
    //   volume: 5895 * 2350 * 2393,
    //   measurementUnit: "mm",
    //   weightUnit: "kg",
    // },
  ],
};

export const pallet_info = (palletType, units) => {
  const { measurementUnit } = units;

  const defaultValues = {
    1: {
      mm: {
        length: 1200,
        width: 800,
        height: 100,
        payload: 10,
        max_loading_height_level: 2000,
        thickness_of_separating_plate: 50,
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 47,
        width: 31,
        height: 4,
        payload: 22,
        max_loading_height_level: 79,
        thickness_of_separating_plate: 2,
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
    2: {
      mm: {
        length: 600,
        width: 800,
        height: 100,
        payload: 10,
        max_loading_height_level: 2000,
        thickness_of_separating_plate: 50,
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 23,
        width: 31,
        height: 4,
        payload: 22,
        max_loading_height_level: 79,
        thickness_of_separating_plate: 2,
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
    3: {
      mm: {
        length: 1200,
        width: 800,
        height: 100,
        payload: 10,
        max_loading_height_level: 2000,
        thickness_of_separating_plate: 50,
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 47,
        width: 31,
        height: 4,
        payload: 22,
        max_loading_height_level: 79,
        thickness_of_separating_plate: 2,
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
    4: {
      mm: {
        length: 1200,
        width: 1000,
        height: 100,
        payload: 10,
        max_loading_height_level: 2000,
        thickness_of_separating_plate: 50,
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 47,
        width: 39,
        height: 4,
        payload: 22,
        max_loading_height_level: 79,
        thickness_of_separating_plate: 2,
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
    5: {
      mm: {
        length: 13,
        width: 25,
        height: 25,
        payload: 10,
        max_loading_height_level: 254,
        thickness_of_separating_plate: 51,
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 0.5,
        width: 1,
        height: 1,
        payload: 22,
        max_loading_height_level: 10,
        thickness_of_separating_plate: 2,
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
  };
  return defaultValues[palletType][measurementUnit];
};

export const getDefaultProperties = (value, unit) => {
  const defaultValues = {
    box: {
      mm: {
        height: 500,
        length: 500,
        width: 500,
        weight: 50,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 20,
        width: 20,
        height: 20,
        weight: 110,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
    barrel: {
      mm: {
        height: 500,
        length: 500,
        width: 500,
        weight: 50,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 20,
        width: 20,
        height: 20,
        weight: 110,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
    sack: {
      mm: {
        height: 500,
        length: 500,
        width: 500,
        weight: 50,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 20,
        width: 20,
        height: 20,
        weight: 110,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
    "big-bag": {
      mm: {
        height: 500,
        length: 500,
        width: 500,
        weight: 50,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "mm",
        weightUnit: "kg",
      },
      inch: {
        length: 20,
        width: 20,
        height: 20,
        weight: 110,
        quantity: 5,
        name: value,
        color: getRandomColor(),
        measurementUnit: "inch",
        weightUnit: "lbs",
      },
    },
  };

  return defaultValues[value][unit];
};

export const getBoxMaterial = (color) => {
  return new THREE.MeshStandardMaterial({
    color: color,
    wireframe: false,
  });
};

export const getBoxGeo = (length, width, height) => {
  return new THREE.BoxGeometry(length, width, height);
};
