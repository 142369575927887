import { styled } from "@mui/system";

const TableStyled = styled("div")(({ theme }) => ({
  "& .MuiTableRow-head .MuiTableCell-head": {
    color: "#9FB1C1",
    fontSize: "12px",
    fontWeight: 500,
    border: "0px",
    padding: "10px",
    whiteSpace: "nowrap",
  },
  "& .MuiTableBody-root .MuiTableCell-root": {
    color: "#9FB1C1",
    fontSize: "12px",
    fontWeight: 500,
    border: "0px",
    padding: "7px",
  },
  "& .form-control": {
    borderRadius: "15px",
    border: "1px solid #DAE3EB",
    background: "#fff",
    outline: "none",
    padding: "6px 12px",
    height: "30px",
    fontSize: "12px",
    fontWeight: "500",
    boxSizing: "border-box",
    width: "100%",
  },
  "& .MuiPaper-root": {
    border: "1px solid #DAE3EB",
    borderRadius: "15px",
    boxShadow: "none",
    padding: "0px",
    width: "auto",
  },
  input: {
    "&::placeholder": {
      color: "#666666",
      opacity: 1,
    },
  },
  "& fieldset": {
    display: "none",
  },
  "& .MuiSelect-select": {
    padding: "0px",
    color: "#8FADC8",
    fontSize: "10px",
    fontWeight: 400,
  },
  "& .MuiSvgIcon-root": {
    color: "#8FADC8",
    fontSize: "18px",
  },
  "& .MuiInputBase-input": {
    color: "#666666",
    fontSize: "12px",
  },
  "& .colortype": {
    outline: "none",
    padding: "0px",
    background: "transparent",
    width: "26px",
    height: "26px",
    borderRadius: "5px",
    appearance: "none",
    border: "0px",
    cursor: "pointer",
  },
  "& ::-webkit-color-swatch-wrapper": {
    padding: "0px",
    border: "0px",
  },
  "& ::-webkit-color-swatch": {
    padding: "0px",
    borderRadius: "5px",
    border: "0px",
  },
  "& ::-moz-color-swatch": {
    padding: "0px",
    borderRadius: "5px",
    border: "0px",
  },
  "& ::-moz-focus-inner": {
    padding: "0px",
    border: "0px",
    borderRadius: "5px",
  },
  "& .mt-6": { marginTop: "-6px" },
  "& .setting-button": {
    background: theme.palette.others.blue,
    color: "#fff",
    minWidth: "auto",
    height: "26px",
    borderRadius: "5px",
    width: "26px",
    padding: "0px",
  },
  "& .delete-button": {
    background: theme.palette.others.red,
    color: "#fff",
    minWidth: "auto",
    height: "26px",
    borderRadius: "5px",
    width: "26px",
    padding: "0px",
  },
  "& .MuiTable-root caption": { padding: "24px 0px 0px" },
}));
export default TableStyled;
