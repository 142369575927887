import { Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
export const OutLineStyled = styled("span")(({ theme }) => ({
  "& .MuiButton-root": {
    boxShadow: "none",
    color: "#6D5BDE",
    fontWeight: 500,
    borderRadius: "10px",
    padding: "10.76px 28px",
    // borderColor: "#6D5BDE",
    // border: "2px solid #6D5BDE",
    textTransform: "inherit",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
  },
  "& .MuiButton-root:hover": {
    background: "#fff",
    // border: "2px solid #6D5BDE",
  },
  "& .MuiButton-root svg": { color: "#009EE3", marginRight: "7px" },
  "& .topspace": { position: "relative", top: "2px" },
}));
const OutLinePurpleButton = ({ text, icon, ...rest }) => {
  return (
    <OutLineStyled>
      <Button {...rest} variant="outlined">
        {icon} <span className="topspace">{text}</span>
      </Button>
    </OutLineStyled>
  );
};
export default OutLinePurpleButton;
