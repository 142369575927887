import { Button, Stack } from "@mui/material";
import { SVG } from "../../assert/svg";

import InputTextField from "../textfield";
import { QuantityPicker } from "react-qty-picker";
import {
  setCalculate,
  setContainerSelectionModal,
  setContainerSelectionType,
  setTruckSelectionType,
  setUpdateContainerByField,
  setUpdateContainerId,
} from "../../reducers/modalSlice";
import { v4 as uuidv4 } from "uuid";

import { useDispatch } from "react-redux";
import { defaultContainerDataAsUnit } from "../../utils/defaultValues";
export const containerType = (value, index) => {
  switch (value) {
    case "1":
      return "20 dv";
    case "2":
      return "40 dv";
    case "3":
      return "40 hq";
    case "4":
      return "45 hq";
    case "Truck":
      return `Truck ${index}`;
    default:
      break;
  }
};

const InputType = ({ value, index }) => {
  return (
    <input
      type="text"
      value={containerType(value, index) || "1"}
      className="form-control"
      placeholder="20 dv"
    />
  );
};

export const CONTAINER_SELECTION_COLUMN_DATA = (measurementUnit) => {
  return [
    {
      id: 1,
      name: "Container type",
      key: "containertype",
    },
    {
      id: 2,
      name: "Qty",
      key: "Qty",
    },
    {
      id: 3,
      name: `Length (${measurementUnit})`,
      key: "length",
    },
    {
      id: 4,
      name: `Width / Diameter (${measurementUnit})`,
      key: "diameter",
    },
    {
      id: 5,
      name: `Height (${measurementUnit})`,
      key: "height",
    },
    {
      id: 6,
      name: `Tonnage (${measurementUnit === "mm" ? "kg" : "lbs"})`,
      key: "tonnage",
    },
    {
      id: 7,
      name: "Action",
      key: "action",
      cclass: "text-right",
    },
  ];
};

export const CONTAINER_SELECTION_ROW_DATA = (containers, isTruck) => {
  const dispatch = useDispatch();

  // console.log("containers", containers);

  const handleDeleteContainer = (id, type) => {
    if (type === "Truck") {
      if (id !== "" && containers.length > 1) {
        let data = containers;

        let filterData = data.filter((i) => i.id !== id);

        dispatch(
          setTruckSelectionType({
            type: "manual",
            container: filterData,
          })
        );
      }
    } else {
      if (id !== "" && containers.length > 1) {
        let data = containers;

        let filterData = data.filter((i) => i.id !== id);

        dispatch(
          setContainerSelectionType({
            type: "manual",
            container: filterData,
          })
        );
      }
    }
    dispatch(setCalculate(true));
  };

  const handleQuantityChange = (item, value) => {
    let filterData = [...containers].filter((i) => i.id !== item.id);

    console.log("filterData", containers);

    if (item.type === "Truck") {
      if (value === "minus") {
        dispatch(
          setTruckSelectionType({
            type: "manual",
            container: filterData,
          })
        );
      } else {
        dispatch(
          setTruckSelectionType({
            type: "manual",
            container: [
              ...containers,
              {
                type: item.type,
                id: uuidv4(),
                ...defaultContainerDataAsUnit("Truck", item.measurementUnit)
                  .container[0],
              },
            ],
          })
        );
      }
    } else {
      if (value === "minus") {
        dispatch(
          setContainerSelectionType({
            type: "manual",
            container: filterData,
          })
        );
      } else {
        dispatch(
          setContainerSelectionType({
            type: "manual",
            container: [
              ...containers,
              {
                type: item.type,
                id: uuidv4(),
                ...defaultContainerDataAsUnit("Container", item.measurementUnit)
                  .container[item.type - 1],
              },
            ],
          })
        );
      }
    }
    dispatch(setCalculate(true));
  };

  const updateContainer = (id) => {
    dispatch(setUpdateContainerId(id));
  };

  let accumulatedContainers = (containers || []).reduce((acc, cargo) => {
    const existingCargo = acc.find((c) => c.type === cargo.type);
    if (existingCargo) {
      acc[acc.indexOf(existingCargo)] = {
        ...existingCargo,
        qty: existingCargo.qty + 1,
      };
    } else {
      acc.push({ ...cargo, qty: 1 });
    }
    return acc;
  }, []);

  return (accumulatedContainers || []).map((item, index) => {
    return {
      containertype: <InputType value={item.type} index={index + 1} />,
      Qty: (
        <>
          <span className="quantity-picker">
            <button
              className="quantity-modifier modifier-left"
              onClick={(e) => {
                e.preventDefault();
                if (item.qty > 1) {
                  handleQuantityChange(item, "minus");
                }
              }}
            >
              –
            </button>
            <input
              className="quantity-display"
              type="number"
              style={{ width: "100%" }}
              value={item.qty}
              onChange={(e) => {
                e.preventDefault();
                // if (item.quantity > 0) {
                //   dispatch(
                //     setUpdateContainerByField({
                //       id: item.id,
                //       field: "qty",
                //       value: e.target.value,
                //     })
                //   );
                // }
              }}
            />
            <button
              className="quantity-modifier modifier-right"
              onClick={(e) => {
                e.preventDefault();
                console.log("item.quantity", item);
                if (item.qty < 10) {
                  handleQuantityChange(item, "plus");
                }
              }}
            >
              ＋
            </button>
          </span>
        </>
        // <QuantityPicker
        //   value={item.qty}
        //   // max={10}
        //   min={1}
        //   field="qty"
        //   id={item.id}
        //   onChange={(value) => {
        //     // if (value <= 10 && value >= 1) {
        //     dispatch(
        //       setUpdateContainerByField({
        //         id: item.id,
        //         field: "qty",
        //         value: value,
        //       })
        //     );
        //     // }
        //   }}
        // />
      ),
      length: (
        <InputTextField
          value={item.length}
          kg={item.measurementType}
          height="30px"
          inputType="number"
          field="length"
          id={item.id}
        />
      ),
      diameter: (
        <InputTextField
          inputType="number"
          value={item.width}
          kg={item.measurementType}
          height="30px"
          field="width"
          id={item.id}
        />
      ),
      height: (
        <InputTextField
          inputType="number"
          value={item.height}
          kg={item.measurementType}
          height="30px"
          field="height"
          id={item.id}
        />
      ),
      tonnage: (
        <InputTextField
          inputType="number"
          value={parseInt(item.weight)}
          kg={item.weightType}
          height="30px"
          field="weight"
          id={item.id}
        />
      ),
      action: (
        <Stack
          direction="row"
          spacing={1.75}
          className="mt-6"
          justifyContent="end"
        >
          {!isTruck && (
            <Button
              className="setting-button"
              onClick={() => {
                dispatch(setContainerSelectionModal(true));
                updateContainer(item.id);
              }}
            >
              <SVG.SettingIcon />
            </Button>
          )}
          <Button
            className="delete-button"
            onClick={() => {
              handleDeleteContainer(item.id, item.type);
            }}
          >
            <SVG.DeleteIcon />
          </Button>
        </Stack>
      ),
    };
  });
};
