import { Button } from "@mui/material";
import React from "react";

import { styled } from "@mui/system";
export const ButtonStyled = styled("span")(({ theme }) => ({
  "& .MuiButton-root": {
    boxShadow: "none",
    color: "#fff",
    fontWeight: 600,
    borderRadius: "73px",
    padding: "10.76px 54px",
    "@media (max-width:992px)": {
      width: "100%",
    },
  },
  "& .MuiButton-root:hover": { backgroundColor: theme.palette.primary.main },
}));

const BlueButtonComponent = ({ text, ...props }) => {
  return (
    <ButtonStyled>
      <Button sx={{ backgroundColor: "#2B3A90" }} {...props}>
        {text}
      </Button>
    </ButtonStyled>
  );
};
export default BlueButtonComponent;
