import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styles from "./login.module.css";
import IMAGES from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
const { whiteLogo } = IMAGES;
export default function AdminLogin() {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.main_bg}>
        <div className={styles.login_form}>
          <Form>
            <div className={styles.logo_div}>
              <img src={whiteLogo} />
            </div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                type="email"
                placeholder="Enter email"
                className={styles.form_input}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              {/* <Form.Label>Password</Form.Label> */}
              <Form.Control
                type="password"
                placeholder="Password"
                className={styles.form_input}
              />
            </Form.Group>
            <div className="text-center mt-5">
              <Button
                variant="primary"
                type="submit"
                className={styles.main_button}
                onClick={() => navigate("/admin/usage-stats")}
              >
                Login
              </Button>
              <br></br>
              <Link to="/admin/forgot-password" className={styles.forgot_btn}>
                Forgot Password ?
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
