import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SVG } from "../../assert/svg";
import TableStyled from "../cargo-packaging/styles";
import CardStyled from "../container-type/style";
import ModalView from "../modal";
import { v4 as uuidv4 } from "uuid";

import SelectBox from "../select/SelectBox";
import {
  CONTAINER_SELECTION_COLUMN_DATA,
  CONTAINER_SELECTION_ROW_DATA,
} from "./data";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AddContainerModalContent from "./AddContainerModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setCalculate,
  setContainerSelectionModal,
  setContainerSelectionType,
} from "../../reducers/modalSlice";
import { toast } from "react-toastify";
import { defaultContainerDataAsUnit } from "../../utils/defaultValues";

export const getContainerProperties = (value) => {
  switch (value) {
    case "1":
      return {
        length: 5895,
        width: 2350,
        height: 2393,
        weight: 28200,
        qty: 1,
        volume: 5895 * 2350 * 2393,
        measurementUnit: "mm",
        weightUnit: "kg",
      };
    case "2":
      return {
        length: 12032,
        width: 2350,
        height: 2393,
        weight: 28800,
        qty: 1,
        volume: 12032 * 2350 * 2393,
        measurementUnit: "mm",
        weightUnit: "kg",
      };
    case "3":
      return {
        length: 12032,
        width: 2350,
        height: 2697,
        weight: 28620,
        qty: 1,
        volume: 12032 * 2350 * 2697,
        measurementUnit: "mm",
        weightUnit: "kg",
      };
    case "4":
      return {
        length: 13556,
        width: 2353,
        height: 2697,
        weight: 27600,
        qty: 1,
        volume: 13556 * 2353 * 2697,
        measurementUnit: "mm",
        weightUnit: "kg",
      };
    default:
      return {
        length: 5895,
        width: 2350,
        height: 2393,
        weight: 28200,
        qty: 1,
        volume: 5895 * 2350 * 2393,
        measurementUnit: "mm",
        weightUnit: "kg",
      };
  }
};
const ContainerSelection = ({ handleSelectedContainer, selectedContainer }) => {
  const dispatch = useDispatch();
  const ContainerSelectionModal =
    useSelector((state) => state.modalHandler.ContainerSelectionModal) || false;
  const [selectedValue, setSelectedValue] = React.useState("");
  const [newContainerType, setNewContainerType] = useState("1");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const ImportedExcelData = useSelector(
    (state) => state.modalHandler.ImportedExcelData
  );

  useEffect(() => {
    if (ImportedExcelData.length > 0 && selectedValue === "")
      setSelectedValue("automatic");
  }, [ImportedExcelData, selectedValue]);

  const handleClose = () => {
    dispatch(setContainerSelectionModal(false));
  };

  const containerSelectionType = useSelector(
    (state) => state.modalHandler.containerSelectionType
  );
  const truckSelectionType = useSelector(
    (state) => state.modalHandler.truckSelectionType
  );

  const measurementUnit = useSelector(
    (state) => state.modalHandler.measurementUnit
  );

  // const isContainerAlreadySelected =
  //   Object.keys(containerSelectionType).length > 0;

  const containerType = useSelector(
    (state) => state.modalHandler.ContainerTypeData
  );

  const handleChange = (event) => {
    setNewContainerType(event.target.value);
  };

  const handleCreateNew = () => {
    const newData = {
      type: newContainerType,
      id: uuidv4(),
      ...defaultContainerDataAsUnit("Container", measurementUnit).container[
        newContainerType - 1
      ],
    };
    let newcontainer = containerSelectionType.container;
    dispatch(
      setContainerSelectionType({
        type: "manual",
        container: [...newcontainer, newData],
      })
    );
    dispatch(setCalculate(true));
  };

  const content = [
    {
      value: "1",
      label: "20 dv",
    },
    {
      value: "2",
      label: "40 dv",
    },
    // {
    //   value: "3",
    //   label: "40 hq",
    // },
    // {
    //   value: "4",
    //   label: "45 hq",
    // },
  ];
  return (
    <CardStyled>
      <Card
        className={
          selectedContainer === "automaticcontainer" ? "selectedBox" : ""
        }
      >
        <CardContent>
          <Stack
            spacing={1.875}
            direction="row"
            alignItems="center"
            sx={{ mb: 3.75 }}
          >
            <IconButton disableRipple>
              <SVG.BackArrowIcon />
            </IconButton>

            <Typography variant="h1">{`Step 4: ${containerType} Selection`}</Typography>
          </Stack>
          {containerType === "Truck" ? null : (
            <Grid container spacing={1}>
              <Grid item lg={6} xs={12}>
                <Card
                  className={`containerBox ${
                    selectedValue === "automatic" ? "selectedcontainer" : ""
                  }`}
                >
                  <CardContent>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                      component="label"
                      className="container-color"
                    >
                      <span>Automatic Container Selection</span>
                      <Radio
                        disableRipple={true}
                        checked={selectedValue === "automatic"}
                        onClick={() => {
                          if (ImportedExcelData.length > 0) {
                            handleSelectedContainer("automaticcontainer");
                            setSelectedValue("automatic");
                            dispatch(setCalculate(true));
                            dispatch(
                              setContainerSelectionType({
                                type: "automatic",
                                container: containerSelectionType.container,
                              })
                            );
                          } else {
                            toast.warn("Please Add Cargo!");
                          }
                        }}
                        checkedIcon={<SVG.CheckedIcon />}
                        icon={<SVG.UncheckedIcon />}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item lg={6} xs={12}>
                <Card
                  className={`containerBox ${
                    selectedValue === "manual" ? "selectedcontainer" : ""
                  }`}
                >
                  <CardContent>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                      component="label"
                      className="container-color"
                    >
                      <span>Manual Container Selection</span>
                      <Radio
                        checked={selectedValue === "manual"}
                        onClick={() => {
                          if (ImportedExcelData.length > 0) {
                            handleSelectedContainer("automaticcontainer");
                            setSelectedValue("manual");
                            // dispatch(setCalculate(true));
                            dispatch(
                              setContainerSelectionType({
                                type: "manual",
                                container: containerSelectionType.container,
                              })
                            );
                          } else {
                            toast.warn("Please Add Cargo!");
                          }
                        }}
                        disableRipple={true}
                        checkedIcon={<SVG.CheckedIcon />}
                        icon={<SVG.UncheckedIcon />}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          {selectedValue === "manual" || containerType === "Truck" ? (
            <Box sx={{ mt: 2.5 }}>
              <Typography
                variant="h2"
                sx={{ fontWeight: "400 !important", mb: 1.75 }}
              >
                {`${containerType} Details`}
              </Typography>
              {containerType === "Truck" ? null : (
                <Grid container spacing={3}>
                  <Grid item lg={9} xs={12}>
                    <SelectBox
                      selectItems={content}
                      selectedItem={newContainerType}
                      bgColor="#F7F9FA"
                      height="48px"
                      borderColor="transparent !important"
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12}>
                    <Button
                      fullWidth
                      className="add-container-btn"
                      onClick={handleCreateNew}
                    >
                      Add Container
                    </Button>
                  </Grid>
                </Grid>
              )}

              <TableStyled>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        {(
                          CONTAINER_SELECTION_COLUMN_DATA(measurementUnit) || []
                        ).map((data) => (
                          <TableCell className={data.cclass} key={data.id}>
                            {data.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(
                        CONTAINER_SELECTION_ROW_DATA(
                          containerType === "Truck"
                            ? truckSelectionType?.container
                            : containerSelectionType?.container,
                          containerType === "Truck"
                        ) || []
                      ).map((row, i) => (
                        <TableRow key={i}>
                          {Object.keys(row).map((key, i) => {
                            return <TableCell key={i}>{row[key]}</TableCell>;
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableStyled>
            </Box>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
      <ModalView
        open={ContainerSelectionModal}
        fullScreen={fullScreen}
        handleClose={handleClose}
        maxWidth="lg"
        content={<AddContainerModalContent handleClose={handleClose} />}
      />
    </CardStyled>
  );
};
export default ContainerSelection;
