import React from "react";
import styles from "../../admin.module.css";
import Sidebar from "../sidebar";
import Header from "../header";
import adminRoutes from "../../utils/adminRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../admin.css";
import { Routes, Route, Link, useLocation } from "react-router-dom";
export default function AdminLayout() {
  return (
    <>
      <div className={styles.main_admin_body}>
        <div className={styles.sidebar_admin}>
          <Sidebar />
        </div>
        <div className={styles.admin_rightside_section}>
          <div className="admin_header_section">
            <Header />
          </div>
          <div className={styles.admin_content_section}>
            <Routes>
              {adminRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={index}
                  />
                );
              })}

              {/* <Route path="*" element={<Error />} /> */}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
