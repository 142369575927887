import { Grid, IconButton, Radio, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SVG } from "../../assert/svg";
import { BlueButtons } from "../button";
import ExportStyled from "../container-type/export-content/style";
import img1 from "../../assert/images/container40hq.png";
import img2 from "../../assert/images/container20st.png";
import { useDispatch, useSelector } from "react-redux";
import { setContainerSelectionType } from "../../reducers/modalSlice";
import { defaultContainerDataAsUnit } from "../../utils/defaultValues";

const AddContainerModalContent = ({ handleClose }) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const [newContainerType, setNewContainerType] = React.useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const dispatch = useDispatch();
  const containerSelectionType = useSelector(
    (state) => state.modalHandler.containerSelectionType
  );
  const updateContainerId = useSelector(
    (state) => state.modalHandler.updateContainerId
  );
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const checkedList = [
    {
      item: "1",
      icon: img1,
      title: "20 dv",
    },
    {
      item: "2",
      icon: img2,
      title: "40 dv",
    },
    // {
    //   item: "3",
    //   icon: img1,
    //   title: "40 hq",
    // },
    // {
    //   item: "4",
    //   icon: img1,
    //   title: "45 hq",
    // },
  ];
  const selectedContainer = (containerType) => {
    // console.log("containerType",containerType);
    setNewContainerType(containerType);
  };
  const updateContainer = () => {
    
    const containerType = newContainerType;
    if (containerType !== "") {
      let filterData = containerSelectionType.container.map((data) => {
        if (data.id === updateContainerId) {
          return {
            ...data,
            type: containerType,
            ...defaultContainerDataAsUnit("Container", data.measurementUnit)
              .container[containerType - 1],
          };
        }
        return data;
      });

      dispatch(
        setContainerSelectionType({
          type: "manual",
          container: filterData,
        })
      );

    }
  };

  return (
    <ExportStyled>
      <div className="export-content">
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          className="border-bottom"
        >
          <h2>Add Container</h2>
          <IconButton disableRipple={true} onClick={handleClose}>
            <SVG.CloseIcon />
          </IconButton>
        </Stack>

        <Box sx={{ py: 5, px: 5.375 }}>
          <Grid container spacing={4.5}>
            {checkedList.map((items, index) => (
              <Grid item lg={3} xs={12} key={index}>
                <Box
                  component="label"
                  className={`card ${
                    selectedValue !== items.item ? "" : "selected"
                  }`}
                >
                  <div className="checkbox">
                    <Radio
                      {...controlProps(items.item)}
                      checkedIcon={<SVG.CheckedIcon />}
                      icon={<SVG.UncheckedIcon />}
                      disableRipple={true}
                      onClick={(event) => selectedContainer(event.target.value)}
                    />
                  </div>
                  <img alt="" src={items.icon} />
                  <h6>{items.title}</h6>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2.5 }}
            sx={{ mt: 5 }}
            justifyContent="end"
          >
            <BlueButtons
              text="Change"
              onClick={() => {
                handleClose();
                updateContainer();
              }}
            />
          </Stack>
        </Box>
      </div>
    </ExportStyled>
  );
};
export default AddContainerModalContent;
